import jwt_decode from "jwt-decode";

export const getUser = () => {
  const userJWT = localStorage.getItem("JWT");
  let user;
  if (userJWT) {
    try {
      user = jwt_decode(userJWT);
    } catch (err) {
      console.log("oh no", err);
      // localStorage.removeItem("JWT");
      // window.location.href = "/";
    }
  }
  if (user) {
    let userData = user.user;
    // userData.jwt = userJWT;
    return userData;
  }
  return null;
};

export const logOut = () => {
  localStorage.removeItem("JWT");
  window.location.href = "/";
  return null;
};

export const logIn = (token) => {
  localStorage.setItem("JWT", token);
  window.location.href = "/";
  return null;
};
