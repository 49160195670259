import React, { useState } from "react";
import { FormInput, InputLabel } from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { validateInformation } from "App/Admin/Wizard/_helpers/validateWizard";
import { sendCustomerEmail } from "App/Admin/Wizard/_helpers/form";
import { getErrors } from "_helpers/api";
import { Switch } from "antd";

function SendEmail(props) {
  const [email, setEmail] = useState(props?.form?.email);
  const [saving, setSaving] = useState(false);

  const validation = validateInformation({ email });

  const submitEmail = async () => {
    try {
      setSaving(true);
      await sendCustomerEmail(
        props.client,
        props?.form,
        email,
        props.newAccount,
      );
      message.success("Completion email sent to: " + email);
      setSaving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error sending email, try again");
      setSaving(false);
    }
  };

  return (
    <div className="wizard-send">
      <InputLabel htmlFor="email" {...validation.email}>
        Confirm email address
      </InputLabel>
      <FormInput
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        size="medium"
        {...validation.email}
      />
      <div className="ws-actions">
        <div className="ws-toggle">
          <Switch
            size="small"
            checked={props.manual}
            onChange={(checked) => props.submitManual(checked)}
          />
          Allow account & routing entry
        </div>
        <Button
          className="green-btn"
          type="primary"
          size="small"
          loading={saving}
          disabled={!validation?.email?.valid}
          onClick={submitEmail}
        >
          Send email
        </Button>
      </div>
    </div>
  );
}

export default SendEmail;
