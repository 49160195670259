import React, { useState, useEffect } from "react";
import WorkflowHeader from "./_components/WorkflowHeader/WorkflowHeader";
import AddTask from "./_components/AddTask/AddTask";
import TaskCard from "./_components/TaskCard/TaskCard";
import ManageTemplate from "../ManageTemplate/ManageTemplate";
import TaskTemplates from "../TaskTemplates/TaskTemplates";
import { IconPlus } from "@tabler/icons-react";
import { Button, message } from "@centrate-io/barn";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { getWorkflow } from "App/Admin/_graphql/queries/workflow";
import { getTaskTemplates } from "App/Admin/_graphql/queries/task";
import { saveWorkflow } from "App/Admin/_graphql/mutations/workflow";
import { myUsers } from "App/Admin/_graphql/queries/user";
import { getTeams } from "App/Admin/_graphql/queries/team";
import { getErrors } from "_helpers/api";
import { Loading } from "_styleguide";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CreateWorkflow from "../../_modals/CreateWorkflow/CreateWorkflow";
import "./EditWorkflow.scss";

function EditWorkflow(props) {
  const client = useApolloClient();
  const params = useParams();
  const [createWorkflowModal, setCreateWorkflowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [workflow, setWorkflow] = useState([]);
  const [savedWorkflow, setSavedWorkflow] = useState([]);
  const [templates, setTemplates] = useState();
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [reset, setReset] = useState(true);
  const [resetT, setResetT] = useState(true);

  // Load Payments
  const fetchWorkflow = async () => {
    const queryData = await client.query({
      variables: {
        workflow_id: params?.id,
      },
      query: getWorkflow,
    });
    const workflowData = queryData?.data?.getWorkflow || null;
    if (workflowData) {
      setLoading(false);
      setWorkflow(workflowData);
      setSavedWorkflow(JSON.stringify(workflowData));
    } else {
      message.error("Workflow not found");
    }
  };
  const fetchUsers = async () => {
    const queryData = await client.query({
      query: myUsers,
    });
    setUsers(queryData?.data?.myUsers || []);
  };
  const fetchTeams = async () => {
    const queryData = await client.query({
      query: getTeams,
    });
    setTeams(queryData?.data?.getTeams || []);
  };
  const fetchTemplates = async () => {
    const queryData = await client.query({
      query: getTaskTemplates,
    });
    setTemplates(queryData?.data?.getTaskTemplates || []);
  };

  // Submit question form
  const cleanTasks = (w) => {
    if (w.total_tasks || w.total_tasks === 0) {
      delete w.total_tasks;
    }
    w.tasks = w.tasks.map((t, i) => {
      const newT = { ...t };
      if (newT.hasOwnProperty("template")) delete newT.template;
      if (newT.hasOwnProperty("assigned_user")) delete newT.assigned_user;
      if (newT.hasOwnProperty("assigned_team")) delete newT.assigned_team;
      newT.order = i + 1;
      return newT;
    });
    return w;
  };
  const updateWorkflow = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          workflow: cleanTasks({ ...workflow }),
        },
        mutation: saveWorkflow,
      });
      message.success(`Workflow successfully saved`);
      fetchWorkflow();
    } catch (err) {
      message.error(getErrors(err) || "Error saving workflow, try again");
      setSaving(false);
    }
    setSaving(false);
  };

  const addTaskToWorkflow = (task, isEditing) => {
    if (isEditing) {
      let oldTasks = [...workflow.tasks];
      oldTasks = oldTasks.map((t) => {
        if (t.order === task.order) {
          return { ...t, ...task };
        } else {
          return t;
        }
      });
      setWorkflow({
        ...workflow,
        tasks: oldTasks,
      });
    } else {
      setWorkflow({
        ...workflow,
        tasks: [...workflow.tasks, task],
      });
    }
  };

  const changeName = (name) => {
    setWorkflow({
      ...workflow,
      name: name,
    });
  };

  const removeTask = (task) => {
    const taskIndex = workflow?.tasks?.findIndex((t) => t.order === task.order);
    const oldTasks = [...workflow.tasks];
    setWorkflow({
      ...workflow,
      tasks: oldTasks.filter((t, i) => i !== taskIndex),
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    let items = Array.from(workflow.tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items = items.map((t, i) => {
      const newT = { ...t };
      newT.order = i + 1;
      return newT;
    });
    setWorkflow({
      ...workflow,
      tasks: items,
    });
  };

  useEffect(() => {
    fetchWorkflow();
    fetchTemplates();
    fetchUsers();
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tasks = workflow?.tasks || [];
  const isEdited = JSON.stringify(workflow) !== savedWorkflow;

  const manageTask = (template) => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setOpen(template);
    }, 1);
  };

  const showNewTask = (template) => {
    setResetT(true);
    setTimeout(() => {
      setResetT(false);
      setOpenTemplate(template ? template : true);
    }, 1);
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="edit-workflow">
      {!reset ? (
        <AddTask
          onClose={() => setOpen(false)}
          open={open}
          task={open}
          templates={templates}
          users={users}
          teams={teams}
          reload={fetchWorkflow}
          addTaskToWorkflow={addTaskToWorkflow}
        />
      ) : null}
      {!resetT ? (
        <ManageTemplate
          {...props}
          onClose={() => setOpenTemplate(false)}
          open={openTemplate}
          template={openTemplate}
          reload={fetchTemplates}
        />
      ) : null}
      {createWorkflowModal ? (
        <CreateWorkflow
          editing={true}
          name={workflow.name}
          changeName={changeName}
          removeModal={() => setCreateWorkflowModal(false)}
        />
      ) : null}
      <TaskTemplates showNewTask={showNewTask} tasks={templates || []} />
      <WorkflowHeader
        workflow={workflow}
        isEdited={isEdited}
        updateWorkflow={updateWorkflow}
        editName={() => setCreateWorkflowModal(true)}
        saving={saving}
      />
      {tasks?.length <= 0 ? (
        <div className="workflow-body">
          <div className="no-tasks">
            <h3>Start by adding a task</h3>
            <p>
              Tasks are reusable instructions to direct your team on customer
              outreach.
            </p>
            <Button
              type="secondary"
              className="boxed"
              onClick={() => manageTask(true)}
            >
              <IconPlus />
              Add Task
            </Button>
          </div>
        </div>
      ) : (
        <div className="workflow-body">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="all-tasks"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tasks.map((t, i) => (
                    <Draggable
                      key={t.id || `new-id-${i}`}
                      draggableId={t.id || `new-id-${i}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskCard
                            task={t}
                            isDragging={snapshot.isDragging}
                            manageTask={manageTask}
                            removeTask={removeTask}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Button
            type="secondary"
            className="boxed tsk-btn"
            onClick={() => manageTask(true)}
          >
            <IconPlus />
            Add Task
          </Button>
        </div>
      )}
    </div>
  );
}

export default EditWorkflow;
