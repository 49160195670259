import React, { Component } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import "./DatePicker.scss";

class DatePicker extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-datepicker " + (props.className || "");
    props.popupClassName = "pp-datepicker-popup";

    if (props.started && props.valid !== 1) {
      props.className += " pp-invalid";
    }
    if (props.size === "large") {
      props.className += " pp-large";
    }
    if (props.size === "medium") {
      props.className += " pp-medium";
    }

    return <AntdDatePicker {...props} />;
  }
}

export default DatePicker;
