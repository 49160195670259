import React, { useRef, useEffect, useState } from "react";
import { Drawer } from "antd";
import Menu from "./_components/Menu/Menu";
import Header from "./_components/Header/Header";
import Personal from "./_components/Personal/Personal";
import Score from "./_components/Score/Score";
import Mortgages from "./_components/Mortgages/Mortgages";
import Installments from "./_components/Installments/Installments";
import Revolving from "./_components/Revolving/Revolving";
import Inquiries from "./_components/Inquiries/Inquiries";
import { mortgageList } from "_helpers/experian";
import "./CreditReport.scss";

function CreditReport(props) {
  const [scrollTops, setScrollTops] = useState(undefined);
  const [selected, setSelected] = useState("personal");
  const scrollRef = useRef(null);
  const { report } = props;
  const mortgages = report?.trades?.filter((t) => {
    return mortgageList.includes(t?.accountTypeCode?.code);
  });

  const installment = report?.trades?.filter((t) => {
    return (
      !mortgageList.includes(t?.accountTypeCode?.code) &&
      (!t.portfolioTypeCode?.code || t.portfolioTypeCode?.code !== "R")
    );
  });
  const revolving = report?.trades?.filter((t) => {
    return (
      !mortgageList.includes(t?.accountTypeCode?.code) &&
      t.portfolioTypeCode?.code === "R"
    );
  });

  const scrollToChild = (childId) => {
    const childOffsetTop = document.querySelector(
      `#trade-${childId}`,
    )?.offsetTop;
    if (!childOffsetTop) return;
    scrollRef?.current?.scrollTo({
      top: childOffsetTop - 20,
      behavior: "smooth",
    });
  };

  const FORGIVENESS = 400;
  const handleScroll = (tops) => {
    if (scrollRef.current) {
      const curr = scrollRef.current.scrollTop;
      Object.keys(tops || []).forEach((key) => {
        const el = tops[key];
        if (
          curr > el.top - FORGIVENESS &&
          curr < el.top + el.height - FORGIVENESS
        ) {
          setSelected(key);
        }
      });
    }
  };

  useEffect(() => {
    if (
      scrollRef.current &&
      scrollRef.current.getAttribute("listener") !== "true" &&
      !scrollTops
    ) {
      const childElements = document.querySelectorAll("[id^='trade-']");
      const tops = {};
      childElements.forEach((el) => {
        const id = el.id.replace("trade-", "");
        tops[id] = {
          top: el?.offsetTop,
          height: el?.offsetHeight,
        };
      });
      setScrollTops(tops);
      scrollRef.current.addEventListener("scroll", () => handleScroll(tops));
    }
    // eslint-disable-next-line
  }, [props.open]);

  const reportProps = {
    report,
    mortgages,
    installment,
    revolving,
    scrollToChild,
    selected,
    inquiries: report?.inquiries || [],
  };

  return (
    <Drawer
      width={800}
      onClose={props.onClose}
      open={props.open}
      className="exp-drawer"
    >
      <Header {...props} report={report} close={props.onClose} />
      <Menu {...props} {...reportProps} />
      <div className="report" ref={scrollRef}>
        <Personal id="trade-personal" {...reportProps} />
        <Score id="trade-score" {...reportProps} />
        <div id="trade-mortgages">
          <Mortgages {...reportProps} />
        </div>
        <div id="trade-installment">
          <Installments {...reportProps} />
        </div>
        <div id="trade-revolving">
          <Revolving {...reportProps} />
        </div>
        <Inquiries id="trade-inquiries" {...reportProps} />
      </div>
    </Drawer>
  );
}

export default CreditReport;
