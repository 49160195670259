import React, { Component } from "react";
import { Dropdown } from "antd";
import { IconDots } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import "./TableActions.scss";

class TableActions extends Component {
  render() {
    return (
      <Dropdown
        className="pp-table-actions"
        menu={{
          items: this.props.items,
        }}
        trigger={["click"]}
        overlayClassName="pp-table-actions-drop"
        placement="bottomRight"
        disabled={this.props.disabled}
      >
        <Button
          disabled={this.props.disabled}
          className="pp-table-button"
          type="blanco"
          size="small"
        >
          <IconDots />
        </Button>
      </Dropdown>
    );
  }
}

export default TableActions;
