import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { unpauseSubscription } from "_graphql/mutations/application";
import { getErrors } from "_helpers/api";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import { fetchActionsData } from "../_helpers/plan";
import { Loading } from "_styleguide";
import "./UnpausePlan.scss";

function UnpausePlan(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionsData, setActionsData] = useState();

  // Pause Plan
  const runPausePlan = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          application_id: props.app?.id,
        },
        mutation: unpauseSubscription,
      });
      message.success(`Plan is now back to Active`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error un-pausing plan, try again");
      setSaving(false);
    }
  };

  // Load Information
  const gather = async () => {
    const notesData = await fetchActionsData(client, props.app.id);
    setActionsData(notesData);
    setLoading(false);
  };

  useEffect(() => {
    gather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPayments = actionsData?.payments?.filter((p) => {
    return ["paused"].includes(p.status);
  });

  return (
    <Modal
      wrapClassName="actions-unpause-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div className="pp-mh-icon warning">
              <IconAlertTriangleFilled />
            </div>
            {`Un-pause ${props.app?.first_name}'s plan?`}
          </div>

          <Modal.Body>
            <div className="ba-content">
              <p>You are attempting to un-pause a customer's plan:</p>

              <div className="ba-desc">
                <div>
                  <b> Un-pausing this plan will:</b>
                </div>
                <div>
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;Move all paused payments back to
                  "Active" status ({filteredPayments?.length || 0} payments)
                </div>
                <div>
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;Change the status of this plan
                  from "Paused" to "Active"
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Actions>
            <Button type="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              className="green-btn"
              type="primary"
              onClick={runPausePlan}
              loading={saving}
            >
              Un-pause Plan
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default UnpausePlan;
