import React, { useState, useEffect } from "react";
import { Table, Search, Empty } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getTeams } from "App/Admin/_graphql/queries/team";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import CreateTeam from "./_modals/CreateTeam/CreateTeam";
import ManageTeam from "./_modals/ManageTeam/ManageTeam";
import { IconPlus } from "@tabler/icons-react";
import "./Teams.scss";

function Teams(props) {
  const client = useApolloClient();
  const [createModal, setCreateModal] = useState(false);
  const [manageModal, setManageModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [search, setSearch] = useState();

  // Load Payments
  const fetchTeams = async () => {
    const queryData = await client.query({
      query: getTeams,
    });
    setState({
      loading: false,
      data: queryData?.data?.getTeams || [],
    });
  };

  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (state?.data || []).filter((u) => {
    if ((search?.length || 0) <= 0) return true;
    const nameSearch = u.name?.toLowerCase();
    const idSearch = u.short_id?.toLowerCase();
    return nameSearch.includes(search) || idSearch.includes(search);
  });
  const columns = getColumns(setManageModal);

  const isEmpty = (state?.data || [])?.length <= 0;

  return (
    <>
      {createModal ? (
        <CreateTeam
          reload={fetchTeams}
          removeModal={() => setCreateModal(false)}
        />
      ) : null}
      {manageModal ? (
        <ManageTeam
          teamId={manageModal}
          reload={fetchTeams}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <Empty
          title="Create Your First Team"
          description="Teams allows for cross departmental assigning & communication of tasks, messages etc..."
          action="Create Team"
          actionIcon={<IconPlus />}
          onClick={() => setCreateModal(true)}
        />
      ) : (
        <div className="settings-teams">
          <div className="settings-search">
            <div className="ms-filters">
              <Search
                placeholder={`Search ${state.data?.length || ""} teams...`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: 304 }}
              />
            </div>{" "}
            <div className="ms-actions">
              <Button
                type="primary"
                size="mini"
                onClick={() => setCreateModal(true)}
              >
                <IconPlus />
                Create Team
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            loading={state.loading}
            pagination={state.pagination}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            scroll={{
              y: "calc(80vh - 231px)",
              x: "max-content",
            }}
            locale={{
              emptyText: state.loading ? "Loading teams..." : "No teams found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default Teams;
