import React, { Component } from "react";
import "./Bank.scss";

export default class Bank extends Component {
  render() {
    const { institution, bankdata, metadata } = this.props;

    return bankdata ? (
      <div className="plaid-bank">
        {bankdata?.logo ? (
          <img
            src={`data:image/png;base64, ${bankdata?.logo}`}
            alt={bankdata?.name}
          />
        ) : null}
        <div className="bank-info">****{bankdata.last_four}</div>
      </div>
    ) : (
      <div className="plaid-bank">
        {institution?.logo ? (
          <img
            src={`data:image/png;base64, ${institution?.logo}`}
            alt={institution?.name}
          />
        ) : null}
        <div className="bank-info">****{metadata?.account?.mask}</div>
      </div>
    );
  }
}
