import React, { useState, useEffect } from "react";
import { Search, Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import {
  adminAllPayments,
  adminPaymentsStats,
} from "App/Admin/_graphql/queries/payment";
import { getColumns } from "./_data/Columns";
import { getCrumbs } from "../_helpers/crumbs";
import { TimeRange, Tag } from "_styleguide";
import dayjs from "dayjs";
import StatBar from "./_components/StatBar/StatBar";
import { Select } from "antd";
import PaymentHistory from "./_modals/PaymentHistory/PaymentHistory";
import { Button } from "@centrate-io/barn";
import { timeout } from "_assets/js/helpers";
import { IconTableExport } from "@tabler/icons-react";
import { downloadPayments } from "_helpers/exports";
import "./Payments.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "first_attempt",
  sortOrder: "ascend",
};

function Payments(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [loadingStats, setLoadingStats] = useState(false);
  const [stats, setStats] = useState({
    open: 0,
    paid: 0,
    processing: 0,
    paused: 0,
    failed: 0,
    cancelled: 0,
  });
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("monthly");
  const [date, setDate] = useState(dayjs().format("YYYYMMDD"));
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState();
  const [exporting, setExporting] = useState(false);

  const columns = getColumns(setModal);

  // Load Payments
  const fetchPayments = async (params = defaultParams) => {
    const queryData = await client.query({
      variables: {
        id: props?.applicationId,
        ...params,
        date: date,
        type: type,
        search: search,
        status: status,
      },
      query: adminAllPayments,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.adminPayments?.pageTotal;
    pagination.totalAll = queryData?.data?.adminPayments?.total;
    setPage(params?.page || 1);
    props?.setCurrentCount(pagination.totalAll);
    setState({
      loading: false,
      data: queryData?.data?.adminPayments?.data || [],
      pagination: pagination,
    });
    fetchStats();
  };

  const exportTable = async () => {
    setExporting(true);
    await timeout(500);
    await downloadPayments(client, {
      date,
      type,
      search,
      status,
    });
    setExporting(false);
  };

  // Load stats
  const fetchStats = async () => {
    setLoadingStats(true);
    const queryData = await client.query({
      variables: {
        date: date,
        type: type,
        search: search,
        status: status,
      },
      query: adminPaymentsStats,
    });
    const statData = queryData?.data?.adminPaymentsStats;
    setStats(statData);
    setLoadingStats(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchPayments({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
      date: date,
      type: type,
      status: status,
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("payments"));
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, type, status]);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  const timeProps = { type, setType, date, setDate };

  const isSuper = props?.user?.role === "super" ? true : false;

  return (
    <div className="admin-application-payments">
      {modal ? (
        <PaymentHistory paymentId={modal} removeModal={() => setModal(false)} />
      ) : null}
      <div className="admin-search admin-search-row">
        {isSuper ? (
          <Button
            loading={exporting}
            onClick={exportTable}
            type="secondary"
            className="boxed"
            size="small"
          >
            <IconTableExport />
            Export
          </Button>
        ) : null}
        <Select
          value={status}
          onChange={(newType) => setStatus(newType)}
          className="filter-select"
          popupClassName="pp-popup"
          placeholder="All"
        >
          <Select.Option value="">All</Select.Option>
          <Select.Option value="open">
            <Tag>Open</Tag>
          </Select.Option>
          <Select.Option value="paid">
            <Tag type="success">Paid</Tag>
          </Select.Option>
          <Select.Option value="processing">
            <Tag type="neutral">Processing</Tag>
          </Select.Option>
          <Select.Option value="paused">
            <Tag type="warning">Paused</Tag>
          </Select.Option>
          <Select.Option value="failed">
            <Tag type="danger">Failed</Tag>
          </Select.Option>
          <Select.Option value="cancelled">
            <Tag type="primary">Cancelled</Tag>
          </Select.Option>
        </Select>
        <div className="admin-search-sep" />
        <TimeRange {...timeProps} />
        <div className="admin-search-sep" />
        <Search
          placeholder="Search payments..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchPayments({ ...defaultParams, search });
          }}
          style={{ width: 220 }}
        />
      </div>
      <div className="admin-table application-table">
        <StatBar stats={stats} loadingStats={loadingStats} />
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 317px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading payments..."
              : "No payments found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default Payments;
