import React from "react";
import { IconNote } from "@tabler/icons-react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export default function Note(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;

  const goToNotes = () => {
    navigate(`/customer/${params.id}/notes`);
    props.setTab("notes");
  };

  return (
    <div className={"app-activity " + activity.type}>
      <div className="act-icon note">
        <IconNote />
      </div>
      <div className="descript">
        <b>{user?.name}</b> posted a note
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToNotes}>
          See Note
        </div>
      </div>
    </div>
  );
}
