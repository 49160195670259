import React from "react";
import { InfoVert } from "../";
import { scoreFactors } from "_helpers/experian";
import "./Score.scss";

function Score(props) {
  const { report } = props;
  const score = report?.models?.[0];
  return (
    <div id={props.id} className="wzrd-box score">
      <div className="wzrd-header">
        <h3>Score Summary</h3>
      </div>
      <div className="wzrd-body">
        <div className="columns" style={{ marginBottom: "24px" }}>
          <InfoVert title="Risk Model">Vantage Score 4.0</InfoVert>
          {score?.score && (
            <InfoVert title="Score">{parseInt(score?.score)}</InfoVert>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Score Factor Description</th>
            </tr>
          </thead>
          <tbody>
            {score?.reasons?.map((s, i) => (
              <tr key={`sf-${i}`}>
                <td>{parseInt(s.code)}</td>
                <td>{scoreFactors[parseInt(s.code)]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Score;
