import React from "react";
import {
  FormInput,
  InputLabel,
  Autocomplete,
  Select,
  Checkbox,
} from "_styleguide";
import { validateInformation } from "App/Admin/Wizard/_helpers/validateWizard";
import { STATES } from "_helpers/location";
import "./Information.scss";

function Information(props) {
  const { form, u, ua } = props;

  const validation = validateInformation(form);

  const filterOption = (input, option) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div className="admin-wizard-information">
      <div className="wzrd-box">
        <div className="wzrd-header">
          <h3>User Information</h3>
        </div>
        <div className="wzrd-body">
          <div className="form-row">
            <div>
              <InputLabel htmlFor="first_name" {...validation.first_name}>
                First name *
              </InputLabel>
              <FormInput
                id="first_name"
                value={form.first_name}
                size="medium"
                onChange={(e) => u("first_name", e.target.value)}
                placeholder="e.g. James..."
                {...validation.first_name}
              />
            </div>
            <div>
              <InputLabel htmlFor="last_name" {...validation.last_name}>
                Last name *
              </InputLabel>
              <FormInput
                id="last_name"
                value={form.last_name}
                size="medium"
                onChange={(e) => u("last_name", e.target.value)}
                placeholder="e.g. Ford..."
                {...validation.last_name}
              />
            </div>
          </div>
          <div className="form-item">
            <InputLabel htmlFor="email" {...validation.email}>
              Email address *
            </InputLabel>
            <FormInput
              id="email"
              value={form.email}
              onChange={(e) => u("email", e.target.value?.toLowerCase())}
              size="medium"
              placeholder="e.g. jford@gmail.com..."
              {...validation.email}
            />
          </div>
          <div className="form-item">
            <InputLabel htmlFor="phone" {...validation.phone}>
              Primary phone number *
            </InputLabel>
            <FormInput
              id="phone"
              value={form.phone}
              onChange={(e) => u("phone", e.target.value)}
              size="medium"
              placeholder="e.g. 631-294-2293..."
              {...validation.phone}
            />
          </div>
          <div className="form-item">
            <Checkbox
              checked={form?.has_co_applicant}
              onChange={(e) => u("has_co_applicant", e.target.checked)}
            >
              Has co-applicant?
            </Checkbox>
          </div>
          {form?.has_co_applicant === true ? (
            <div className="form-row">
              <div>
                <InputLabel
                  htmlFor="co_first_name"
                  {...validation.co_first_name}
                >
                  Co-applicant First name *
                </InputLabel>
                <FormInput
                  id="co_first_name"
                  value={form.co_first_name}
                  size="medium"
                  onChange={(e) => u("co_first_name", e.target.value)}
                  placeholder="e.g. James..."
                  {...validation.co_first_name}
                />
              </div>
              <div>
                <InputLabel htmlFor="co_last_name" {...validation.co_last_name}>
                  Co-applicant Last name *
                </InputLabel>
                <FormInput
                  id="co_last_name"
                  value={form.co_last_name}
                  size="medium"
                  onChange={(e) => u("co_last_name", e.target.value)}
                  placeholder="e.g. Ford..."
                  {...validation.co_last_name}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="wzrd-box">
        <div className="wzrd-header">
          <h3>Home Address</h3>
        </div>
        <div className="wzrd-body">
          <div className="form-item">
            <InputLabel {...validation.address}>Address *</InputLabel>
            <Autocomplete
              value={form.address}
              onChange={(v) => u("address", v)}
              form={form}
              updateAddress={ua}
              size="medium"
              {...validation.address}
            />
            <FormInput
              id="address_line_two"
              value={form.address_line_two}
              onChange={(e) => u("address_line_two", e.target.value)}
              placeholder="Apartment, suite, unit (optional)"
              size="medium"
              style={{ marginTop: "16px" }}
            />
          </div>
          <div className="form-item">
            <InputLabel htmlFor="city" {...validation.city}>
              City *
            </InputLabel>
            <FormInput
              id="city"
              value={form.city}
              onChange={(e) => u("city", e.target.value)}
              size="medium"
              {...validation.city}
            />
          </div>
          <div className="form-row">
            <div>
              <InputLabel htmlFor="state" {...validation.state}>
                State *
              </InputLabel>
              <Select
                showSearch
                value={form.state}
                onChange={(val) => u("state", val)}
                size="extra-medium"
                popupClassName="pp-select-dropdown"
                filterOption={filterOption}
                optionFilterProp="children"
                {...validation.state}
              >
                {STATES.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ maxWidth: "160px" }}>
              <InputLabel htmlFor="zip" {...validation.zip}>
                Zip *
              </InputLabel>
              <FormInput
                id="zip"
                value={form.zip}
                onChange={(e) => u("zip", e.target.value)}
                size="medium"
                maxLength="5"
                {...validation.zip}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
