import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { createTradeline } from "_graphql/mutations/tradeline";
import { getErrors } from "_helpers/api";
import { IconPlus } from "@tabler/icons-react";
import { FormInput, InputLabel, InputNumber, Flex, Select } from "_styleguide";
import { validateCustomCredit } from "App/Admin/_helpers/validateForm";
import { accountTypeMap } from "_helpers/experian";
import "./CustomCredit.scss";

function CustomCredit(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    account_number: undefined,
    creditor: undefined,
    account_type: undefined,
    balance: undefined,
  });

  // Submit question form
  const saveCreditLine = async () => {
    setSaving(true);
    try {
      const saveResponse = await client.mutate({
        variables: { application_id: props.applicationId, ...form },
        mutation: createTradeline,
      });
      message.success(`Credit line successfully added`);
      if (props.reload) props.reload();
      const newId = saveResponse?.data?.createTradeline;
      if (props.addNewTradeline && newId) {
        props.addNewTradeline(newId);
      }
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error adding credit line, try again");
      setSaving(false);
    }
    setSaving(false);
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const validation = validateCustomCredit(form);

  return (
    <Modal
      wrapClassName="custom-credit-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconPlus />
        </div>
        <h3>Add Credit Line</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="account_number" {...validation.account_number}>
            Account Number *
          </InputLabel>
          <FormInput
            id="account_number"
            size="medium"
            value={form.account_number}
            onChange={(e) => updateForm("account_number", e.target.value)}
            placeholder="e.g. 0493857163..."
            {...validation.account_number}
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="creditor" {...validation.creditor}>
            Creditor Name *
          </InputLabel>
          <FormInput
            id="creditor"
            size="medium"
            value={form.creditor}
            onChange={(e) => updateForm("creditor", e.target.value)}
            placeholder="e.g. Bank of America..."
            {...validation.creditor}
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="account_type" {...validation.account_type}>
            Account Type *
          </InputLabel>
          <Select
            value={form.account_type}
            onChange={(val) => updateForm("account_type", val)}
            size="extra-medium"
            popupClassName="pp-select-dropdown"
            placeholder="Select account type..."
            showSearch
            filterOption={(input, option) => {
              return (option?.children?.[0] ?? "")
                .toLowerCase()
                .includes(input?.toLowerCase());
            }}
            {...validation.account_type}
          >
            {Object.keys(accountTypeMap)
              .sort((a, b) => (parseInt(a) > parseInt(b) ? 1 : -1))
              .map((key) => {
                const desc = accountTypeMap[key];
                return (
                  <Select.Option key={`custom-credit-${key}`} value={key}>
                    {desc} ({key})
                  </Select.Option>
                );
              })}
          </Select>
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="balance" {...validation.balance}>
            Total Balance *
          </InputLabel>
          <InputNumber
            controls={false}
            value={form.balance}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => updateForm("balance", value)}
          />
        </Flex>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={!validation.allValid}
          onClick={saveCreditLine}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CustomCredit;
