import React from "react";
import { InputLabel, AlertBox, Select } from "_styleguide";
import {
  IconStarFilled,
  IconChevronRight,
  IconChevronDown,
  IconCircleCheck,
} from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import { validatePlan } from "App/Admin/Wizard/_helpers/validateWizard";
import "./CustomPlan.scss";

const COSTS = {
  service_percent: 40,
  monthly_fees: 0,
  months: 24,
};

const THRESHOLDS = {
  service_percent: {
    min: 15,
    max: 50,
  },
  months: {
    min: 12,
    max: 48,
  },
  monthly_fees: {
    min: 0,
    max: 120,
  },
};

function CustomPlan(props) {
  const { selected, defaultCustom, form } = props;
  const plan = form?.plan;
  const validation = validatePlan(plan);

  const u = (f, v) => {
    const oldPlan = { ...plan };
    oldPlan[f] = v;
    const totalDebt = oldPlan?.debt_amount || 0;
    const customCost = parseFloat(
      (totalDebt * (oldPlan.service_percent / 100) +
        oldPlan.monthly_fees * oldPlan.months) /
        oldPlan.months,
    );
    oldPlan.type = "custom";
    oldPlan.amount = customCost;
    props.ua(form, {
      plan: oldPlan,
    });
  };

  const totalCost = (overrideMonths) => {
    const totalDebt = plan?.debt_amount || 0;
    const months = overrideMonths || plan?.months || 24;

    return parseFloat(
      (totalDebt * ((plan?.service_percent || COSTS.service_percent) / 100) +
        (plan?.monthly_fees || COSTS.monthly_fees) * months) /
        months,
    );
  };

  const monthlyFeesList = [
    ...Array(THRESHOLDS.monthly_fees.max - THRESHOLDS.monthly_fees.min + 1),
  ];
  const servicePercentList = [
    ...Array(
      THRESHOLDS.service_percent.max - THRESHOLDS.service_percent.min + 1,
    ),
  ];

  const isDisabled = plan?.type !== "custom";

  const monthsArray = [
    ...new Set([plan?.months, 12, 18, 24, 36, 48, 60]),
  ].sort();

  return (
    <React.Fragment>
      <div
        className={`wzrd-box admin-custom-plan ${isDisabled ? " collapsed" : ""}`}
      >
        <div
          className="wzrd-header custom"
          onClick={() => (selected !== "custom" ? defaultCustom() : null)}
        >
          <div
            className={`plan-radio ${selected === "custom" ? "selected" : ""}`}
          />
          <h3>
            Create Custom Offer<span>(Optional)</span>
          </h3>
          {isDisabled ? <IconChevronRight /> : <IconChevronDown />}
        </div>
        <div className="wzrd-body">
          <AlertBox type="warning" className="plan-custom">
            <h5>Using Custom Offers</h5>
            <p>
              Only use this section if the above program offers don’t work for
              the client. Please use the offers above as a base to expand upon.
            </p>
          </AlertBox>
          <div className="form-row">
            <div>
              <InputLabel
                htmlFor="service_percent"
                {...validation.service_percent}
              >
                Service Fee
              </InputLabel>
              <Select
                value={isDisabled ? null : plan.service_percent}
                onChange={(val) => u("service_percent", parseInt(val))}
                size="extra-medium"
                popupClassName="pp-select-dropdown"
                placeholder="Select fee..."
                disabled={isDisabled}
                {...validation.service_percent}
              >
                {servicePercentList.map((s, i) => {
                  const fee = THRESHOLDS.service_percent.min + i;
                  return (
                    <Select.Option key={`ss-loop-` + fee} value={fee}>
                      {fee}%
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div>
              <InputLabel htmlFor="monthly_fees" {...validation.monthly_fees}>
                Maintenance Fee
              </InputLabel>
              <Select
                value={isDisabled ? null : plan.monthly_fees}
                onChange={(val) => u("monthly_fees", parseInt(val))}
                size="extra-medium"
                popupClassName="pp-select-dropdown"
                placeholder="Select fee..."
                disabled={isDisabled}
                {...validation.monthly_fees}
              >
                {monthlyFeesList.map((s, i) => {
                  const fee = THRESHOLDS.monthly_fees.min + i;
                  return (
                    <Select.Option key={`sm-loop-` + fee} value={fee}>
                      ${fee}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="form-item">
            <InputLabel htmlFor="months" {...validation.months}>
              Program Length
            </InputLabel>
            <Select
              value={isDisabled ? null : plan.months}
              onChange={(val) => u("months", val)}
              size="extra-medium"
              popupClassName="pp-select-dropdown"
              placeholder="Select months..."
              disabled={isDisabled}
              {...validation.months}
            >
              {[...Array(49)].map((s, i) => (
                <Select.Option key={`s-loop-` + i + 12} value={i + 12}>
                  {i + 12} months
                </Select.Option>
              ))}
            </Select>
          </div>
          <AlertBox>
            <div className="plan-preview">
              <IconStarFilled />
              <h5>Monthly Plan Rate</h5>
              <IconStarFilled />
            </div>
            {monthsArray.map((mo) => (
              <div
                key={`mpr-${mo}`}
                className={`plan-rate${mo !== plan?.months ? " no-svg" : ""}`}
              >
                <IconCircleCheck />
                <b>{mo} months:</b>&nbsp;&nbsp;Est.{" "}
                <b>{formatMoney(totalCost(mo) || 0)}</b> / per month
              </div>
            ))}
          </AlertBox>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CustomPlan;
