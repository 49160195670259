import { gql } from "@apollo/client";

export const cancelPayment = gql`
  mutation cancelPayment($payment_id: String!) {
    cancelPayment(payment_id: $payment_id)
  }
`;

export const createPayment = gql`
  mutation createPayment(
    $application_id: String!
    $next_attempt: String!
    $amount: Float!
  ) {
    createPayment(
      application_id: $application_id
      next_attempt: $next_attempt
      amount: $amount
    )
  }
`;

export const updatePayment = gql`
  mutation updatePayment($payment_id: String!, $next_attempt: String!) {
    updatePayment(payment_id: $payment_id, next_attempt: $next_attempt)
  }
`;
