import {
  validateEmail,
  validateName,
  validateZipCode,
  validateState,
  validateAddress,
  validatePhoneNumber,
  validateLastFour,
  validateSocial,
} from "_assets/js/helpers";

const hasStarted = (field) => {
  if (field !== undefined && field !== null) {
    return 1;
  }
  return 0;
};

export const validateStart = (start) => {
  const isValid = {
    job: {
      started: hasStarted(start.job),
      valid: hasStarted(start.job),
    },
    debt: {
      started: hasStarted(start.debt),
      valid: hasStarted(start.debt),
    },
    bank: {
      started: hasStarted(start.bank),
      valid: hasStarted(start.bank),
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateAll = (form) => {
  const validContact = validateContact(form);
  const validLocation = validateLocation(form);
  const validPhone = validatePhone(form);

  const validAll = {
    ...validContact,
    ...validLocation,
    ...validPhone,
  };
  delete validAll.allValid;

  let allValid = true;
  Object.keys(validAll).forEach((key) => {
    if (!validAll[key].valid) allValid = false;
  });
  validAll.allValid = allValid;

  return validAll;
};

export const validateContact = (form) => {
  const isValid = {
    first_name: {
      started: hasStarted(form?.first_name),
      valid: validateName(form?.first_name) ? 1 : 0,
    },
    last_name: {
      started: hasStarted(form?.last_name),
      valid: validateName(form?.last_name) ? 1 : 0,
    },
    email: {
      started: hasStarted(form?.email),
      valid: validateEmail(form?.email || "") ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateLocation = (form) => {
  const isValid = {
    address: {
      started: hasStarted(form?.address),
      valid: validateAddress(form?.address) ? 1 : 0,
    },
    city: {
      started: hasStarted(form?.city),
      valid: validateName(form?.city) ? 1 : 0,
    },
    state: {
      started: hasStarted(form?.state),
      valid: validateState(form?.state) ? 1 : 0,
    },
    zip: {
      started: hasStarted(form?.zip),
      valid: validateZipCode(form?.zip) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validatePhone = (form) => {
  const isValid = {
    phone: {
      started: hasStarted(form?.phone),
      valid: validatePhoneNumber(form?.phone) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateSSN = (form) => {
  const isValid = {
    ssn_last_4: {
      started: hasStarted(form?.ssn_last_4),
      valid: validateLastFour(form?.ssn_last_4) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateFullSSN = (form) => {
  const isValid = {
    ssn: {
      started: hasStarted(form?.ssn),
      valid: validateSocial(form?.ssn) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateDOB = (form) => {
  const isValid = {
    dob: {
      started: hasStarted(form?.dob),
      valid: form?.dob && form?.dob?.isValid() ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};
