import React, { useState } from "react";
import { Input, Button, message } from "@centrate-io/barn";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { getErrors } from "_helpers/api";
import PayPathLogo from "_assets/logos/paypath.svg";
import { changePassword } from "_graphql/mutations/user";
import "./Reset.scss";

function Reset(props) {
  const params = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = props;

  // Submit login and receive token
  const submitChange = async () => {
    const error = shouldDisable();
    if (error) return message.error(error);
    setLoading(true);
    try {
      const tokenData = await client.mutate({
        variables: { password: newPassword, token: params?.id },
        mutation: changePassword,
      });
      message.success(`Password successfully changed`);
      const token = tokenData?.data?.changePassword;
      if (token) login(token);
      navigate("/");
    } catch (err) {
      message.error(getErrors(err) || "Error resetting password, try again");
      setLoading(false);
    }
  };

  const shouldDisable = () => {
    let error = null;
    if (newPassword?.length < 6 || confirmPassword?.length < 6)
      error = "Passwords must be longer than 6 characters";
    if (newPassword !== confirmPassword) error = "Passwords do not match";
    return error;
  };

  return (
    <React.Fragment>
      <div className="auth-reset">
        <div className="auth-info">
          <img className="login-logo" src={PayPathLogo} alt="PayPathAI" />
        </div>
        <div className="login-form">
          <div className="line">
            <Input.Label>New password *</Input.Label>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              size="large"
            />
          </div>
          <div className="line">
            <Input.Label>Confirm password *</Input.Label>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              size="large"
            />
          </div>
          <Button
            loading={loading}
            type="secondary"
            onClick={submitChange}
            size="large"
            block
          >
            Change Password
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Reset;
