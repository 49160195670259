import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import {
  IconSignature,
  IconReport,
  IconCreditCard,
  IconCalendar,
  IconCheckupList,
  IconScoreboard,
} from "@tabler/icons-react";
import moment from "moment";

export const getColumns = (goToTradelines) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Name
        </div>
      ),
      dataIndex: "slug",
      key: "slug",
      sorter: true,
      render: (slug, data) => (
        <div>
          <Text className="doc-name">
            <IconReport />
            <b>{data?.slug || "Unknown"}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScoreboard />
          Score
        </div>
      ),
      dataIndex: "credit_lines",
      key: "credit_lines",
      render: (report, data) => {
        const score = data?.reportData?.riskModel?.[0]?.score ? (
          parseInt(data?.reportData?.riskModel?.[0]?.score)
        ) : (
          <i>n/a</i>
        );
        return (
          <Link to={`/tradelines`} className="cl-link">
            {score}
          </Link>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconCheckupList />
          Issuer
        </div>
      ),
      dataIndex: "issuer",
      key: "issuer",
      sorter: true,
      render: (issuer) => <Text>{issuer}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCreditCard />
          Credit Lines
        </div>
      ),
      dataIndex: "credit_lines",
      key: "credit_lines",
      render: (report, data) => {
        return (
          <Text onClick={goToTradelines} className="cl-link">
            {data?.reportData?.tradeline?.length || 0} credit lines
          </Text>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Date Pulled
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (createdAt) => (
        <Text>{moment(parseInt(createdAt)).format("M/D/YYYY")}</Text>
      ),
    },
  ];
};
