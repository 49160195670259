import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminUsers } from "App/Admin/_graphql/queries/user";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import { getCrumbs } from "../_helpers/crumbs";
import InviteUser from "./_modals/InviteUser/InviteUser";
import "./Users.scss";

function Users(props) {
  const client = useApolloClient();
  const [inviteModal, setInviteModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });

  const columns = getColumns(props?.user?.id);

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      query: adminUsers,
    });
    setState({
      loading: false,
      data: queryData?.data?.adminUsers || [],
    });
    props?.setCurrentCount(queryData?.data?.adminUsers?.length || 0);
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("users"));
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-users">
      <div className="admin-search admin-button">
        <Button
          type="primary"
          size="small"
          onClick={() => setInviteModal(true)}
        >
          Invite User
        </Button>
        {inviteModal ? (
          <InviteUser
            reload={fetchDocuments}
            removeModal={() => setInviteModal(false)}
            user={props.user}
          />
        ) : null}
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 230px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading ? "Loading users..." : "No users found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          {/*Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}*/}
        </div>
      )}
    </div>
  );
}

export default Users;
