import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Header, Modal } from "@centrate-io/barn";
import { inviteUser } from "App/Admin/_graphql/mutations/user";
import { getErrors } from "_helpers/api";
import { AlertBox, FormInput, InputLabel, Flex, Select } from "_styleguide";
import { validateInviteUser } from "App/Admin/_helpers/validateForm";
import "./InviteUser.scss";

function InviteUser(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    email: undefined,
    role: undefined,
  });

  // Submit question form
  const createInvitation = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { ...form },
        mutation: inviteUser,
      });
      message.success(`Invite sent to ${form.email}`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error inviting user, try again");
      setSaving(false);
    }
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const validation = validateInviteUser(form);

  return (
    <Modal
      wrapClassName="invite-user-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <Header size="5" type="display">
        Invite to {props?.user?.company?.name}
      </Header>

      <Modal.Body>
        <div className="invite-form">
          <Flex vertical={true}>
            <InputLabel htmlFor="email" {...validation.email}>
              Email address *
            </InputLabel>
            <FormInput
              id="email"
              value={form.email}
              onChange={(e) =>
                updateForm("email", e.target.value?.toLowerCase())
              }
              placeholder="e.g. jford@gmail.com..."
              {...validation.email}
            />
          </Flex>
          <Flex vertical={true}>
            <InputLabel htmlFor="role" {...validation.role}>
              Role *
            </InputLabel>
            <Select
              value={form.role}
              onChange={(val) => updateForm("role", val)}
              size="medium"
              popupClassName="pp-select-dropdown"
              placeholder="Select role..."
              {...validation.role}
            >
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="manager">Manager</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
          </Flex>
        </div>
        <AlertBox type="info">
          <b>Admin: </b> Access to all customers, payments, companies, and users
          under your umbrella.
          <br />
          <b>Manager: </b> Access to all customers, their payments, and users
          under your umbrella.
          <br />
          <b>User: </b> Access to only their specific customers and ability to
          manage them.
        </AlertBox>
      </Modal.Body>

      <Modal.Actions>
        <Button type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={!validation.allValid}
          onClick={createInvitation}
          loading={saving}
        >
          Send Invitation
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default InviteUser;
