import React, { Component } from "react";
import { Checkbox as AntdCheckbox } from "antd";
import "./Checkbox.scss";

class Checkbox extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-checkbox " + (props.className || "");

    if (props.size === "large") {
      props.className += "pp-checkbox-large";
    }

    return <AntdCheckbox {...props} />;
  }
}

Checkbox.Group = AntdCheckbox.Group;
export default Checkbox;
