import React, { useState } from "react";
import { Avatar, Title } from "_styleguide";
import { Button } from "@centrate-io/barn";
import { IconBuildingBank, IconLockOpen } from "@tabler/icons-react";
import Plaid from "App/Application/Checkout/_components/Plaid/Plaid";
import { Trust, Creating } from "App/Application/Checkout/_components";
import { Actions } from "_styleguide";
import { sendMessage } from "_graphql/queries/socket";
import AccountRouting from "../_modals/AccountRouting/AccountRouting";
import Illustration from "_assets/images/check-illustration.png";

import "./AddBank.scss";

function AddBank(props) {
  const { form } = props;
  // eslint-disable-next-line
  const [saving, setSaving] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [bankAdded, setBankAdded] = useState(false);

  const sendSocketMessage = async (status, data) => {
    try {
      await props.client.query({
        query: sendMessage,
        variables: {
          application_id: form?.id,
          status: status,
          data: data || null,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const openManual = async () => {
    await sendSocketMessage("connecting-bank");
    setShowManual(true);
  };
  const closeManual = () => setShowManual(false);

  return saving ? (
    <Creating />
  ) : (
    <React.Fragment>
      {showManual ? (
        <AccountRouting
          form={form}
          closeWindow={closeManual}
          updateBank={props.updateBank}
          sendSocketMessage={sendSocketMessage}
          setBankAdded={setBankAdded}
        />
      ) : null}

      {bankAdded ? (
        <div className="ba-wrapper">
          <div className="bank-added">
            <div className="gradient" />
            <img src={Illustration} alt="PayPath" />
            <h2>Payment method updated!</h2>
            <p>
              Thank you for taking this step towards financial freedom. We
              appreciate your commitment to your debt relief journey.
            </p>
          </div>
        </div>
      ) : (
        <div className="step-start step-bank">
          <Avatar icon={<IconBuildingBank />} />
          <Title>Let's setup your payment method!</Title>
          <p className="final-info">
            We just need some basic bank account details to get started. We’re
            only saving your information for future payouts. Below is an
            overview of your current plan:
          </p>
          <div className="app-form">
            <Actions
              {...props.actionsProps}
              noPrevious
              noPowered
              form={props.form}
              customNext={
                <div className="final-actions">
                  {!props.manual ? (
                    <Plaid
                      {...props}
                      hasStartDate={true}
                      toggle={false}
                      fromRegistration={true}
                      setBankAdded={setBankAdded}
                    />
                  ) : (
                    <Button
                      type="primary"
                      size="large"
                      block
                      className="plaid-connect green-btn"
                      onClick={openManual}
                    >
                      <IconLockOpen /> Connect Bank Account
                    </Button>
                  )}
                  <Trust />
                </div>
              }
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default AddBank;
