import { gql } from "@apollo/client";

export const adminUsers = gql`
  query {
    adminUsers {
      id
      short_id
      name
      email
      phone
      role
      application_count
      subscription_count
      invited_date
      joined_date
      createdAt

      company {
        id
        short_id
        name
        role
      }
    }
  }
`;

export const getUsers = gql`
  query {
    getUsers {
      id
      name
      email
    }
  }
`;

export const myUsers = gql`
  query {
    myUsers {
      id
      name
      email
    }
  }
`;
