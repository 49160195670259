import React, { useState } from "react";
import { IconChevronLeft } from "@tabler/icons-react";
import { Tooltip, Button } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import EquifaxSmall from "_assets/logos/equifax_small.svg";
import CreditReport from "./_components/CreditReport/CreditReport";
import { Tag } from "_styleguide";
import "./Header.scss";

function Header(props) {
  const [open, setOpen] = useState(false);
  const { form, report } = props;
  const isNew = props.applicationId === "new";
  const hasName = form?.first_name || form?.last_name;

  let status = "draft";
  if (!isNew) status = "saved";
  if (props.isEdited) status = "edited";

  const showReport = () => {
    setOpen(true);
  };
  const closeReport = () => {
    setOpen(false);
  };

  return (
    <div className="admin-wizard-header">
      {props.isEdited ? (
        <Tooltip title="Save before leaving">
          <div className="awh-back disabled">
            <IconChevronLeft />
          </div>
        </Tooltip>
      ) : (
        <Link to="/">
          <div className="awh-back">
            <IconChevronLeft />
          </div>
        </Link>
      )}

      <div className="awh-title">
        <h4>
          <span>Application&nbsp;&nbsp;/&nbsp;&nbsp;</span>
          <b>
            {hasName
              ? `${form?.first_name || ""} ${form?.last_name || ""}`
              : "Untitled"}
          </b>
          {props.wasCancelled ? (
            <Tag size="small" type="neutral">
              MODIFYING
            </Tag>
          ) : null}
        </h4>
        <div className={`awh-status-${status}`}>
          <span className="awh-circle" />
          <h5>{status.toUpperCase()}</h5>
        </div>
      </div>
      <div className="awh-actions">
        {report ? (
          <Button type="secondary" onClick={showReport} className="cr-button">
            <img src={EquifaxSmall} alt="Equifax" />
            See Credit Report
          </Button>
        ) : null}
      </div>
      <CreditReport {...props} onClose={closeReport} open={open} />
    </div>
  );
}

export default Header;
