import React from "react";
import { IconSubtask } from "@tabler/icons-react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";

export default function Task(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const taskData = activity.task;
  let icon = <IconSubtask />;

  const goToTasks = () => {
    navigate(`/customer/${params.id}/tasks`);
    props.setTab("tasks");
  };

  return (
    <div className={"app-activity status-task"}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {taskData.status === "applied" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> applied the workflow{" "}
            <b>{taskData.name}</b> which added <b>{taskData.amount} tasks</b>
          </React.Fragment>
        )}
        {taskData.status === "created" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> created the task{" "}
            <b>{taskData.name}</b>
          </React.Fragment>
        )}
        {taskData.status === "completed" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> completed the task{" "}
            <b>{taskData.name}</b>
          </React.Fragment>
        )}
        {taskData.status === "skipped" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> skipped the task{" "}
            <b>{taskData.name}</b>
          </React.Fragment>
        )}
        {taskData.status === "removed" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> removed the task{" "}
            <b>{taskData.name}</b>
          </React.Fragment>
        )}
        {taskData.status === "updated" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> updated the task{" "}
            <b>{taskData.name}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToTasks}>
          See Tasks
        </div>
      </div>
    </div>
  );
}
