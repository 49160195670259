import React from "react";
import Illustration from "_assets/images/no_workflows.png";
import "./NoTasks.scss";

function NoTasks(props) {
  return (
    <div className="no-tasks-pls">
      <img src={Illustration} alt="PayPath" />
      <h2>No Tasks Due</h2>
      <p>You are up to date on all of your due tasks.</p>
    </div>
  );
}
export default NoTasks;
