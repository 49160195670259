import React from "react";
import { formatMoney } from "_assets/js/helpers";
import "./StatBar.scss";

function StatBar(props) {
  const { stats } = props;

  return (
    <div className="stat-bar">
      {Object.keys(stats || {}).map((key) => (
        <div key={`aps-${key}`} className="stat-card">
          <label>{key}</label>
          <h3>{formatMoney(stats[key] || 0)}</h3>
        </div>
      ))}
    </div>
  );
}

export default StatBar;
