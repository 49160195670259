import React from "react";
import { IconX } from "@tabler/icons-react";
import EquifaxSmall from "_assets/logos/equifax_small.svg";
import "./Header.scss";

function Header(props) {
  const { report } = props;
  const name = report?.subjectName;
  const pdfUrl = report?.pdfUrl;
  return (
    <div className="header">
      <img src={EquifaxSmall} alt="Equifax" />
      <div className="report-details">
        <h2>Credit Profile Report</h2>
        <p>
          {name?.lastName}, {name?.firstName} {name?.middleName}
          {pdfUrl ? (
            <span>
              {" "}
              -{" "}
              <a
                className="doc-download"
                href={pdfUrl}
                target="_blank"
                rel="noreferrer"
              >
                Download Report
              </a>
            </span>
          ) : (
            ""
          )}
        </p>
      </div>
      <div className="close-drawer" onClick={props.close}>
        <IconX />
      </div>
    </div>
  );
}

export default Header;
