import React from "react";
import { IconFileTypePdf } from "@tabler/icons-react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export default function Status(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const status = activity.document;
  let icon = <IconFileTypePdf />;

  const goToDocuments = () => {
    navigate(`/customer/${params.id}/documents`);
    props.setTab("documents");
  };

  return (
    <div className={"app-activity status-" + status.type}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        <React.Fragment>
          <b>{user?.name || "Customer"}</b> added a document{" "}
          <a className="pp-link" href={status.url}>
            <b>{status.name || "document.pdf"}</b>
          </a>
        </React.Fragment>
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToDocuments}>
          See All Documents
        </div>
      </div>
    </div>
  );
}
