import React, { useState, useEffect } from "react";
import { Input, Button, message } from "@centrate-io/barn";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { getErrors } from "_helpers/api";
import queryString from "query-string";
import PayPathLogo from "_assets/logos/paypath.svg";
import "./Login.scss";

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const { login } = props;

  /* Google Login */
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params?.googleLogin) {
      login(params?.googleLogin);
      navigate("/");
    } else if (params?.error === "missing") {
      message.error("Account not found, try again");
      navigate("/");
    }
  }, [navigate, login]);

  // Submit login and receive token
  const submitLogin = async () => {
    setLoading(true);
    const data = { email, password };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/login`, data)
      .then((response) => {
        const token = response?.data?.token;
        if (token) login(token);
        navigate("/");
      })
      .catch((err) => {
        message.error(
          getErrors(err) || "Please re-check your login credentials",
        );
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="auth-login">
        <div className="auth-info">
          <img className="login-logo" src={PayPathLogo} alt="PayPathAI" />
        </div>
        <div className="login-form">
          <div className="line">
            <Input.Label>Email</Input.Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
            />
          </div>

          <div className="line">
            <Input.Label>Password</Input.Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="large"
            />
          </div>

          <div className="forgot">
            <Link className="pp-link" to="/forgot">
              Forgot password?
            </Link>
          </div>

          <Button
            loading={loading}
            type="secondary"
            onClick={submitLogin}
            size="large"
            block
          >
            Login
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
