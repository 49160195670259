import {
  saveApplication,
  exchangeToken as exchangePlaidToken,
} from "_graphql/mutations/application";
import { saveTradelines as mutateTradelines } from "_graphql/mutations/tradeline";

export const saveForm = async (client, form, stage) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: {
        application: { ...form, stage },
      },
      mutation: saveApplication,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult ? true : false;
};

export const exchangeToken = async (client, form, plaidData) => {
  let exchangeResult = null;
  try {
    exchangeResult = await client.mutate({
      variables: {
        application_id: form.id,
        public_token: plaidData?.public_token,
        account_id: plaidData?.metadata?.account_id,
      },
      mutation: exchangePlaidToken,
    });
  } catch (err) {
    exchangeResult = null;
    console.log(err);
  }
  return exchangeResult?.data?.exchangeToken;
};

export const saveTradelines = async (client, tradelines, application_id) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: { tradelines, application_id },
      mutation: mutateTradelines,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult ? true : false;
};
