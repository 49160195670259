import { useState, useEffect } from "react";
import { getAppWorkflows } from "App/Admin/_graphql/queries/workflow";
import { applyWorkflow } from "App/Admin/_graphql/mutations/workflow";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconSubtask } from "@tabler/icons-react";
import { Select, Flex, InputLabel } from "_styleguide";
import "./ApplyWorkflow.scss";

function ApplyWorkflow(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [selected, setSelected] = useState();

  // Load Payments
  const fetchWorkflows = async () => {
    const queryData = await client.query({
      variables: {
        application_id: props.applicationId,
      },
      query: getAppWorkflows,
    });
    setWorkflows(queryData?.data?.getAppWorkflows?.workflows || []);
  };

  // Submit question form
  const addTasks = async (val) => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          workflow_id: selected,
          application_id: props.applicationId,
        },
        mutation: applyWorkflow,
      });
      message.success(`Selected workflow has been applied`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      setSaving(false);
      message.error(getErrors(err) || "Error applying workflow, try again");
    }
  };

  useEffect(() => {
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      wrapClassName="apply-workflow-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div className="pp-mh-icon">
          <IconSubtask />
        </div>
        Apply Workflow to Customer
      </div>

      <Modal.Body>
        <div className="aw-form">
          <Flex vertical={true}>
            <InputLabel htmlFor="notify">Select a Workflow</InputLabel>
            <Select
              disabled={saving}
              value={selected}
              onChange={(val) => setSelected(val)}
              size="extra-medium"
              popupClassName="pp-select-dropdown ppsd-task"
              placeholder="---"
            >
              {workflows?.map((u) => (
                <Select.Option key={u.id} value={u.id}>
                  {u.name} ({u.total_tasks} tasks)
                </Select.Option>
              ))}
            </Select>
          </Flex>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <div className="right-part">
          <Button
            className="green-btn"
            type="primary"
            disabled={!selected}
            onClick={() => addTasks(false)}
            loading={saving}
          >
            Apply Workflow
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default ApplyWorkflow;
