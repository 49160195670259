import React, { Component } from "react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import "./TimeRange.scss";

const { MonthPicker, WeekPicker } = DatePicker;

class TimeRange extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-time-range " + (props.className || "");

    const { type, setType, date, setDate } = this.props;
    const year = dayjs(date, "YYYYMMDD").format("YYYY");

    const onChange = (currentDate) => {
      let newDate = currentDate;
      if (type === "weekly") {
        newDate = currentDate.startOf("week");
      } else if (type === "monthly") {
        newDate = currentDate.startOf("month");
      }
      setDate(newDate.format("YYYYMMDD"));
    };

    const onYearChange = (year) => {
      const currentDate = dayjs().day(1).year(year).week("24");
      setDate(currentDate.format("YYYYMMDD"));
    };

    return (
      <div className={props.className}>
        <Select
          value={type}
          onChange={(newType) => setType(newType)}
          popupClassName="pp-popup"
        >
          <Select.Option value="daily">Daily</Select.Option>
          <Select.Option value="weekly">Weekly</Select.Option>
          <Select.Option value="monthly">Monthly</Select.Option>
          <Select.Option value="yearly">Yearly</Select.Option>
          <Select.Option value="all">All</Select.Option>
        </Select>
        <span>for</span>
        {type === "daily" && (
          <DatePicker
            onChange={onChange}
            defaultValue={dayjs()}
            format="M/D/YY"
            popupClassName="pp-datepicker-popup"
            allowClear={false}
          />
        )}
        {type === "weekly" && (
          <WeekPicker
            onChange={onChange}
            defaultValue={dayjs().startOf("week")}
            format="M/D/YY"
            popupClassName="pp-datepicker-popup"
            allowClear={false}
          />
        )}
        {type === "monthly" && (
          <MonthPicker
            onChange={onChange}
            defaultValue={dayjs()}
            format="MMM YYYY"
            popupClassName="pp-datepicker-popup"
            allowClear={false}
          />
        )}
        {type === "yearly" && (
          <Select
            value={year}
            onChange={onYearChange}
            popupClassName="pp-popup"
          >
            <Select.Option value="2024">2024</Select.Option>
            <Select.Option value="2025">2025</Select.Option>
            <Select.Option value="2026">2026</Select.Option>
            <Select.Option value="2027">2027</Select.Option>
            <Select.Option value="2028">2028</Select.Option>
            <Select.Option value="2029">2029</Select.Option>
            <Select.Option value="2030">2030</Select.Option>
            <Select.Option value="2031">2031</Select.Option>
            <Select.Option value="2032">2032</Select.Option>
            <Select.Option value="2033">2033</Select.Option>
            <Select.Option value="2034">2034</Select.Option>
          </Select>
        )}
      </div>
    );
  }
}

export default TimeRange;
