import { gql } from "@apollo/client";

export const getBankAccounts = gql`
  query getBankAccounts($application_id: ID) {
    getBankAccounts(application_id: $application_id) {
      id
      name
      logo
      url
      account_type
      last_four
      is_active
    }
  }
`;
