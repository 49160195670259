import React from "react";
import { IconCoin } from "@tabler/icons-react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";

export default function Status(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const paymentData = activity.payment;
  let icon = <IconCoin />;

  const goToPayments = () => {
    navigate(`/customer/${params.id}/payments`);
    props.setTab("payments");
  };

  return (
    <div className={"app-activity status-payment"}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {paymentData.status === "updated" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> updated one of the payments to a
            new date ({paymentData.information})
          </React.Fragment>
        )}
        {paymentData.status === "cancelled" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> cancelled a payment that was
            scheduled on &nbsp;
            {paymentData.information}
          </React.Fragment>
        )}
        {paymentData.status === "created" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> added a new payment &nbsp;
            {paymentData.information}
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToPayments}>
          See Payments
        </div>
      </div>
    </div>
  );
}
