import React, { useState } from "react";
import { Avatar, Title } from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { IconLock, IconLockOpen } from "@tabler/icons-react";
import Plaid from "App/Application/Checkout/_components/Plaid/Plaid";
import Cart from "App/Application/Checkout/_components/Cart/Cart";
import ConfirmPopup from "App/Application/Checkout/_components/ConfirmPopup/ConfirmPopup";
import {
  Trust,
  DueToday,
  Creating,
} from "App/Application/Checkout/_components";
import { Actions } from "_styleguide";
import { timeout } from "_assets/js/helpers";
import { createSubscription } from "_graphql/mutations/application";
import { sendMessage } from "_graphql/queries/socket";
import { useNavigate } from "react-router-dom";
import { getErrors } from "_helpers/api";
import AccountRouting from "../_modals/AccountRouting/AccountRouting";
import "./CompleteRegistration.scss";

function CompleteRegistration(props) {
  const navigate = useNavigate();
  const { form } = props;
  const [saving, setSaving] = useState(false);
  const [showWindow, setShowWindow] = useState(false);
  const [showManual, setShowManual] = useState(false);

  const showCart =
    (form?.plaid || props.bank) && form?.plan?.start_date ? true : false;
  const saveSubscription = async (form) => {
    let formResult = null;
    try {
      formResult = await props.client.mutate({
        variables: { application_id: form.id },
        mutation: createSubscription,
      });
      await sendSocketMessage("subscribed");
    } catch (err) {
      formResult = null;
      message.error(getErrors(err) || "Error subscribing, try again");
    }
    return formResult ? true : false;
  };
  const startSubscription = async () => {
    setSaving(true);
    await timeout(1000);
    const savedSubscription = await saveSubscription(form);
    if (props.setDone) props.setDone(true);
    if (savedSubscription) {
      navigate(`/s/${props?.shortId}`);
    } else {
      setSaving(false);
    }
  };
  const sendSocketMessage = async (status, data) => {
    try {
      await props.client.query({
        query: sendMessage,
        variables: {
          application_id: form?.id,
          status: status,
          data: data || null,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const openWindow = async () => {
    await sendSocketMessage("agreement");
    setShowWindow(true);
  };
  const closeWindow = () => setShowWindow(false);

  const openManual = async () => {
    await sendSocketMessage("connecting-bank");
    setShowManual(true);
  };
  const closeManual = () => setShowManual(false);

  return saving ? (
    <Creating />
  ) : (
    <React.Fragment>
      {showWindow ? (
        <ConfirmPopup
          form={form}
          startSubscription={startSubscription}
          closeWindow={closeWindow}
        />
      ) : null}

      {showManual ? (
        <AccountRouting
          form={form}
          closeWindow={closeManual}
          updateBank={props.updateBank}
          sendSocketMessage={sendSocketMessage}
        />
      ) : null}

      <div className="step-start step-register">
        <Avatar icon={<IconLock />} />
        <Title>Last Step to Free up Your Credit!</Title>
        <p className="final-info">
          We just need some basic bank account details to get started. Don't
          worry, <b>nothing will be charged yet.</b> We’re only saving your
          information for future payouts.
        </p>
        <div className="app-form">
          <Cart {...props} />
          <Actions
            {...props.actionsProps}
            noPrevious
            noPowered
            form={props.form}
            customNext={
              <div className="final-actions">
                <DueToday />
                {showCart ? (
                  <Button
                    disabled={saving}
                    type="primary"
                    size="large"
                    block
                    className="plaid-connect green-btn"
                    onClick={openWindow}
                  >
                    Confirm Your Plan
                  </Button>
                ) : null}
                {!showCart && !props.manual ? (
                  <Plaid
                    {...props}
                    hasStartDate={true}
                    toggle={false}
                    fromRegistration={true}
                  />
                ) : null}
                {!showCart && props.manual === true ? (
                  <Button
                    type="primary"
                    size="large"
                    block
                    className="plaid-connect green-btn"
                    onClick={openManual}
                  >
                    <IconLockOpen /> Connect Bank Account
                  </Button>
                ) : null}
                <Trust />
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompleteRegistration;
