import React, { useState, useEffect } from "react";
import { getAppWorkflows } from "App/Admin/_graphql/queries/workflow";
import { saveWorkflowSelection } from "App/Admin/_graphql/mutations/application";
import { useApolloClient } from "@apollo/client";
import { Flex, Select } from "_styleguide";
import { getErrors } from "_helpers/api";
import { message } from "@centrate-io/barn";
import "./WorkflowSelection.scss";

function WorkflowSelection(props) {
  const client = useApolloClient();
  const { form } = props;
  const [workflows, setWorkflows] = useState([]);
  const [selected, setSelected] = useState();

  // Load Payments
  const fetchWorkflows = async () => {
    const queryData = await client.query({
      variables: {
        application_id: form.id,
      },
      query: getAppWorkflows,
    });
    setWorkflows(queryData?.data?.getAppWorkflows?.workflows || []);
    setSelected(queryData?.data?.getAppWorkflows?.selected || undefined);
  };

  // Submit question form
  const saveSelected = async (val) => {
    try {
      await client.mutate({
        variables: { application_id: form.id, workflow_id: val },
        mutation: saveWorkflowSelection,
      });
      setSelected(val);
      message.success(`Selected workflow has been updated`);
      fetchWorkflows();
    } catch (err) {
      message.error(getErrors(err) || "Error saving selection, try again");
    }
  };

  useEffect(() => {
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wzrd-box bank-box">
      <div className="wzrd-header">
        <h3>Workflow Selection</h3>
      </div>
      <div className="wzrd-body">
        <p className="bank-info">
          Select which workflow you would like to apply to this lead once they
          become a customer.
        </p>
        <Flex vertical={true} style={{ marginTop: "16px" }}>
          <Select
            value={selected}
            onChange={saveSelected}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
          >
            <Select.Option value={undefined}>---</Select.Option>
            {workflows?.map((u) => (
              <Select.Option key={u.id} value={u.id}>
                {u.name} ({u.total_tasks} tasks)
              </Select.Option>
            ))}
          </Select>
          {selected ? (
            <p className="pp-form-info">
              This workflow will be applied once the customer completes
              registration. If you don't want to apply a workflow, please select
              "---".
            </p>
          ) : null}
        </Flex>
      </div>
    </div>
  );
}

export default WorkflowSelection;
