import { useState } from "react";
import { Modal } from "@centrate-io/barn";
import { IconSettings } from "@tabler/icons-react";
import SettingsMenu from "./_components/SettingsMenu/SettingsMenu";
import Users from "./Users/Users";
import Companies from "./Companies/Companies";
import Teams from "./Teams/Teams";
import "./Settings.scss";

function Settings(props) {
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState("users");
  return (
    <Modal
      wrapClassName="settings-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={1280}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <div className="settings-title">
        <div className="st-icon">
          <IconSettings />
        </div>
        <h4>Settings</h4>
      </div>

      <Modal.Body>
        <SettingsMenu selected={selected} setSelected={setSelected} />
        <div className="sm-page">
          {selected === "users" && (
            <Users {...props} closeModal={() => setVisible(false)} />
          )}
          {selected === "companies" && <Companies {...props} />}
          {selected === "teams" && <Teams {...props} />}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Settings;
