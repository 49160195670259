import React from "react";
import moment from "moment";
import "./Menu.scss";

const MenuItem = (props) => {
  const isSelected = props.selected;
  let className = "cr-item";
  if (isSelected) className += " selected";
  return (
    <div className={className} onClick={props.onClick}>
      <h5>{props.title}</h5>
      {props.count && <span>{props.count}</span>}
    </div>
  );
};

function Menu(props) {
  const { mortgages, installment, revolving, inquiries, report } = props;
  const scroll = props.scrollToChild;
  return (
    <div className="menu">
      <h4 className="applicant">Applicant Info</h4>
      <MenuItem
        title="Personal Information"
        selected={props.selected === "personal"}
        onClick={() => scroll("personal")}
      />
      {/*      <MenuItem
        title="Profile Summary"
        selected={props.selected === "summary"}
        onClick={() => scroll("summary")}
      />
      <MenuItem
        title="Delinquency Counter"
        selected={props.selected === "delinquency"}
        onClick={() => scroll("delinquency")}
      />*/}
      <MenuItem
        title="Score Summary"
        selected={props.selected === "score"}
        onClick={() => scroll("score")}
      />
      <div className="sep" />
      <h4 className="creditors">Creditors</h4>
      <MenuItem
        title="Mortgage Accounts"
        count={mortgages?.length || 0}
        selected={props.selected === "mortgages"}
        onClick={() => scroll("mortgages")}
      />
      <MenuItem
        title="Installment Accounts"
        count={installment?.length || 0}
        selected={props.selected === "installment"}
        onClick={() => scroll("installment")}
      />
      <MenuItem
        title="Revolving Accounts"
        count={revolving?.length || 0}
        selected={props.selected === "revolving"}
        onClick={() => scroll("revolving")}
      />
      <MenuItem
        title="Inquiries"
        count={inquiries?.length || 0}
        selected={props.selected === "inquiries"}
        onClick={() => scroll("inquiries")}
      />
      <div className="sep" />
      <p>
        {moment(parseInt(report.createdAt)).format("MM/DD/YYYY - h:mm:ss A Z")}
      </p>
    </div>
  );
}

export default Menu;
