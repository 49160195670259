import React from "react";
import Illustration from "_assets/images/no_workflows.png";
import { IconPlus } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import "./NoWorkflows.scss";

function NoWorkflows(props) {
  return (
    <div className="no-workflows">
      <img src={Illustration} alt="PayPath" />
      <h2>No Workflows Yet</h2>
      <p>
        Create a workflow to guide tasks and engage customers effectively. Start
        by adding your first workflow now.
      </p>
      <Button type="primary" onClick={props.showModal}>
        <IconPlus /> Create Workflow
      </Button>
    </div>
  );
}

export default NoWorkflows;
