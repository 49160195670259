import { gql } from "@apollo/client";

export const getCompanyInvite = gql`
  query getCompanyInvite($company_id: ID!) {
    getCompanyInvite(company_id: $company_id) {
      name
      company
      email
    }
  }
`;
