import {
  validateEmail,
  validateString,
  validateName,
  validatePhoneNumber,
} from "_assets/js/helpers";

const hasStarted = (field) => {
  if (field !== undefined && field !== null) {
    return 1;
  }
  return 0;
};

export const validateInviteCompany = (form) => {
  const isValid = {
    company: {
      started: hasStarted(form.company),
      valid: validateString(form.company) ? 1 : 0,
    },
    name: {
      started: hasStarted(form.name),
      valid: validateName(form.name) ? 1 : 0,
    },
    email: {
      started: hasStarted(form.email),
      valid: validateEmail(form.email || "") ? 1 : 0,
    },
    phone: {
      started: hasStarted(form.phone),
      valid: validatePhoneNumber(form.phone || "") ? 1 : 0,
    },
    password: {
      started: hasStarted(form.password),
      valid: form?.password?.length > 5 ? 1 : 0,
    },
    rePassword: {
      started: hasStarted(form.rePassword),
      valid: form?.password === form?.rePassword ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateInviteUser = (form) => {
  const isValid = {
    name: {
      started: hasStarted(form.name),
      valid: validateName(form.name) ? 1 : 0,
    },
    email: {
      started: hasStarted(form.email),
      valid: validateEmail(form.email || "") ? 1 : 0,
    },
    phone: {
      started: hasStarted(form.phone),
      valid: validatePhoneNumber(form.phone || "") ? 1 : 0,
    },
    password: {
      started: hasStarted(form.password),
      valid: form?.password?.length > 5 ? 1 : 0,
    },
    rePassword: {
      started: hasStarted(form.rePassword),
      valid: form?.password === form?.rePassword ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};
