export const Steps = {
  information: {
    next: "credit",
    previous: null,
  },
  credit: {
    next: "plan",
    previous: "information",
  },
  plan: {
    next: "payment",
    previous: "credit",
  },
  payment: {
    next: "customer",
    previous: "plan",
  },
  customer: {
    next: null,
    previous: "plan",
  },
};
