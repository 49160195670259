import React, { Component } from "react";
import { IconCheck } from "@tabler/icons-react";

import "./YesNo.scss";

class YesNo extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-yesno " + (props.className || "");

    const checked = (
      <div className="pp-checked">
        <IconCheck />
      </div>
    );

    const isYes = props.value === true;
    const isNo = props.value === false;

    const onClick = (val) => {
      this.props.onChange(val);
    };

    return (
      <div className={props.className}>
        <div
          className={`pp-no ${isNo ? "pp-selected" : ""}`}
          onClick={() => onClick(false)}
        >
          {isNo && checked}No
        </div>
        <div
          className={`pp-yes ${isYes ? "pp-selected" : ""}`}
          onClick={() => onClick(true)}
        >
          {isYes && checked}Yes
        </div>
      </div>
    );
  }
}

export default YesNo;
