import React, { useState, useEffect } from "react";
import { Button, message } from "@centrate-io/barn";
import { useParams, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { getErrors } from "_helpers/api";
import PayPathLogo from "_assets/logos/paypath.svg";
import { Loading } from "_styleguide";
import { getCompanyInvite } from "_graphql/queries/company";
import { signupCompany } from "_graphql/mutations/company";
import { AlertBox, FormInput, InputLabel, Flex } from "_styleguide";
import { validateInviteCompany } from "App/Auth/_helpers/validateForm";
import { FormatPhone } from "_assets/js/helpers";
import "./Company.scss";

function Company(props) {
  const navigate = useNavigate();
  const params = useParams();
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { login } = props;
  const [form, setForm] = useState({
    name: undefined,
    company: undefined,
    email: undefined,
    phone: undefined,
    password: undefined,
    rePassword: undefined,
  });

  // Load Entries
  const fetchInvite = async () => {
    const inviteData = await client.query({
      variables: { company_id: params?.id },
      query: getCompanyInvite,
    });
    const formData = inviteData?.data?.getCompanyInvite;
    setForm(formData ? { ...form, ...formData, name: undefined } : null);
    setLoading(false);
  };

  // Submit question form
  const signUp = async () => {
    setSaving(true);
    try {
      const tokenData = await client.mutate({
        variables: { company_id: params?.id, ...form },
        mutation: signupCompany,
      });
      message.success(`Account created, welcome to PayPath!`);
      const token = tokenData?.data?.signupCompany;
      if (token) login(token);
      navigate("/");
      // setSaving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error signing up company, try again");
      setSaving(false);
    }
  };

  useEffect(() => {
    fetchInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const validation = validateInviteCompany(form || {});

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <img className="company-invite-logo" src={PayPathLogo} alt="PayPathAI" />

      <div className="auth-company">
        <h2>Company Registration</h2>
        {!form ? (
          <AlertBox type="danger">
            We were unable to find your invitation. Please reach out to
            your&nbsp;
            <b>company administrator</b> to check the status of your invitation.
          </AlertBox>
        ) : (
          <React.Fragment>
            <p>Register your company with PayPath</p>
            <div className="invite-form">
              <Flex vertical={true}>
                <InputLabel htmlFor="name" {...validation.company}>
                  Company Name *
                </InputLabel>
                <FormInput
                  id="company"
                  value={form.company}
                  onChange={(e) => updateForm("company", e.target.value)}
                  placeholder="e.g. Google"
                  {...validation.company}
                />
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="name" {...validation.name}>
                  Full Name *
                </InputLabel>
                <FormInput
                  id="name"
                  value={form.name}
                  onChange={(e) => updateForm("name", e.target.value)}
                  placeholder="e.g. James Ford"
                  {...validation.name}
                />
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="email" {...validation.email}>
                  Email address *
                </InputLabel>
                <FormInput
                  id="email"
                  value={form.email}
                  onChange={(e) =>
                    updateForm("email", e.target.value?.toLowerCase())
                  }
                  placeholder="e.g. jford@gmail.com..."
                  {...validation.email}
                />
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="phone" {...validation.phone}>
                  Phone Number *
                </InputLabel>
                <FormInput
                  id="phone"
                  value={form.phone}
                  onChange={(e) =>
                    updateForm("phone", FormatPhone(e.target.value))
                  }
                  placeholder="e.g. 555-555-5555"
                  {...validation.phone}
                />
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="password" {...validation.password}>
                  Password *
                </InputLabel>
                <FormInput
                  id="password"
                  value={form.password}
                  type="password"
                  onChange={(e) => updateForm("password", e.target.value)}
                  {...validation.password}
                />
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="rePassword" {...validation.rePassword}>
                  Re-enter Password *
                </InputLabel>
                <FormInput
                  id="rePassword"
                  value={form.rePassword}
                  type="password"
                  onChange={(e) => updateForm("rePassword", e.target.value)}
                  {...validation.rePassword}
                />
              </Flex>
            </div>
            <Button
              disabled={!validation.allValid}
              onClick={signUp}
              loading={saving}
              type="primary"
              size="large"
              block
            >
              Sign up
            </Button>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default Company;
