import { gql } from "@apollo/client";

export const adminApplicationActivity = gql`
  query adminApplicationActivity($application_id: ID!) {
    adminApplicationActivity(application_id: $application_id) {
      id
      type
      status {
        type
        automated
        from_admin
        information
      }
      payment {
        status
        information
        success
        attempt
        payment_id
      }
      task {
        status
        name
        amount
        workflow_id
        task_id
      }
      update {
        fields {
          field_name
          old_value
          new_value
        }
      }
      note {
        note_id
      }
      document {
        document_id
        name
        url
      }
      credit_report {
        credit_report_id
      }
      tradelines {
        type
        debt_amount
        amount
      }
      outreach {
        type
        contact
        url
      }
      createdAt
      user {
        id
        name
        email
      }
      company {
        id
        name
      }
    }
  }
`;
