export const InfoLine = (props) => {
  return (
    <div className={`info-line ${props.last ? "last" : ""}`}>
      <h5>{props.title}</h5>
      <div className="info-details">
        <div>{props.info}</div>
        {props.details && <p>{props.details}</p>}
      </div>
    </div>
  );
};

export const InfoVert = (props) => {
  return (
    <div className={`info-vert ${props.last ? "last" : ""}`}>
      <h5>{props.title}</h5>
      <div className="info-details">{props.children}</div>
    </div>
  );
};
