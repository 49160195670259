import React from "react";
import { Flex } from "_styleguide";
import { IconCheck } from "@tabler/icons-react";
import "./BankInfo.scss";

function BankInfo(props) {
  const { bankData } = props;
  const { institution, metadata } = bankData;

  const isManual = !institution;

  return isManual ? (
    <div className="wzrd-box bank-info-box">
      <div className="wzrd-header">
        <h3>Bank Confirmation</h3>
      </div>
      <div className="wzrd-body">
        <div className="bib-details">
          <Flex gap="16px">
            <div className="bib-bank">
              {bankData?.logo ? (
                <img
                  src={`data:image/png;base64, ${bankData?.logo}`}
                  alt={bankData?.name}
                />
              ) : null}
            </div>
            <Flex className="bib-info" vertical={true}>
              <h5>{bankData?.name}</h5>
              <p>xxxxxxxx{bankData?.last_four}</p>
            </Flex>
          </Flex>
          <div className="bib-confirmed">
            <IconCheck />
            Bank Connected
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="wzrd-box bank-info-box">
      <div className="wzrd-header">
        <h3>Bank Confirmation</h3>
      </div>
      <div className="wzrd-body">
        <div className="bib-details">
          <Flex gap="16px">
            <div className="bib-bank">
              <img
                src={`data:image/png;base64, ${institution?.logo}`}
                alt={institution?.name}
              />
            </div>
            <Flex className="bib-info" vertical={true}>
              <h5>{institution?.name}</h5>
              <p>xxxxxxxx{metadata?.account?.mask}</p>
            </Flex>
          </Flex>
          <div className="bib-confirmed">
            <IconCheck />
            Bank Connected
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankInfo;
