import React from "react";
import "./SettingsMenu.scss";

const MenuItem = (props) => {
  const isSelected = props.selected;
  let className = "cr-item";
  if (isSelected) className += " selected";
  return (
    <div className={className} onClick={props.onClick}>
      <h5>{props.title}</h5>
      {props.count && <span>{props.count}</span>}
    </div>
  );
};

function SettingsMenu(props) {
  return (
    <div className="settings-menu">
      <MenuItem
        title="User Management"
        selected={props.selected === "users"}
        onClick={() => props.setSelected("users")}
      />
      <MenuItem
        title="Company Management"
        selected={props.selected === "companies"}
        onClick={() => props.setSelected("companies")}
      />
      <MenuItem
        title="Teams"
        selected={props.selected === "teams"}
        onClick={() => props.setSelected("teams")}
      />
    </div>
  );
}

export default SettingsMenu;
