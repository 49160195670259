import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { pauseSubscription } from "_graphql/mutations/application";
import { getErrors } from "_helpers/api";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import { fetchActionsData } from "../_helpers/plan";
import { Loading, TextArea } from "_styleguide";
import "./PausePlan.scss";

function PausePlan(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionsData, setActionsData] = useState();
  const [reason, setReason] = useState();

  // Pause Plan
  const runPausePlan = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          application_id: props.app?.id,
          reason: reason || null,
        },
        mutation: pauseSubscription,
      });
      message.success(`Plan is now set as Paused`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error pausing plan, try again");
      setSaving(false);
    }
  };

  // Load Information
  const gather = async () => {
    const notesData = await fetchActionsData(client, props.app.id);
    setActionsData(notesData);
    setLoading(false);
  };

  useEffect(() => {
    gather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPayments = actionsData?.payments?.filter((p) => {
    return ["open", "failed"].includes(p.status);
  });

  return (
    <Modal
      wrapClassName="actions-pause-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div className="pp-mh-icon warning">
              <IconAlertTriangleFilled />
            </div>
            {`Pause ${props.app?.first_name}'s plan?`}
          </div>

          <Modal.Body>
            <div className="ba-content">
              <p>You are attempting to pause a customer's plan:</p>

              <div className="ba-desc">
                <div>
                  <b> Pausing this plan will:</b>
                </div>
                <div>
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;Pause all incomplete and future
                  payments ({filteredPayments?.length || 0} payments)
                </div>
                <div>
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;Change the status of this plan
                  from "Active" to "Paused"
                </div>
              </div>
              <div className="ba-desc">
                <b>Leave a reason for pausing (required):</b>
              </div>
              <TextArea
                disabled={saving}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                size="large"
                autoSize={true}
                placeholder="Leave your reason here..."
              />
            </div>
          </Modal.Body>

          <Modal.Actions>
            <Button type="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              disabled={!reason}
              className="green-btn"
              type="primary"
              onClick={runPausePlan}
              loading={saving}
            >
              Pause Plan
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default PausePlan;
