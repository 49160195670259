import React from "react";
import { InfoLine, InfoVert } from "../";
import { FormatSSN, FormatDate } from "_assets/js/helpers";
import "./Personal.scss";

const AddressList = (props) => {
  const { address } = props;

  return (
    <div className="item-list">
      <div>
        {address?.houseNumber} {address?.streetName} {address?.streetType}
        <br />
        {address?.cityName}, {address?.stateAbbreviation}{" "}
        {address?.zipCode?.substring(0, 5)}
      </div>
      {address.rentOwnBuy && (
        <p>{address.rentOwnBuy === "O" ? "Owned" : "Rental"}</p>
      )}
      {address.firstReportedDate && address.lastUpdatedDate && (
        <p>
          Reported {FormatDate(address?.dateFirstReported)} to&nbsp;
          {FormatDate(address?.dateLastReported)}
        </p>
      )}
    </div>
  );
};

function Personal(props) {
  const { report } = props;
  const name = report?.subjectName;
  const otherNames = report?.formerNames
    ?.map((n, i) => {
      if (i === 0) return null;
      return `${n?.firstName} ${n?.middleInitial || ""} ${n?.lastName}`;
    })
    .filter((n) => n !== null);
  const ssn = report?.subjectSocialNum;
  const address = report?.addresses?.[0];
  const otherAddresses = report?.addresses
    ?.map((a, i) => {
      if (i === 0) return null;
      return a;
    })
    .filter((a) => a !== null);
  const dob = report?.birthDate;
  const employment = report?.employments?.find(
    (e) => e.identifier === "current",
  );
  const otherEmployment = report?.employments?.find(
    (e) => e.identifier === "former",
  );

  return (
    <div id={props.id} className="wzrd-box personal">
      <div className="wzrd-header">
        <h3>Personal Information</h3>
      </div>
      <div className="wzrd-body">
        <InfoLine
          title="Best Name"
          info={`${name?.firstName} ${name?.middleName || ""} ${name?.lastName}`}
        />
        {otherNames ? (
          <InfoLine title="Other Name(s)" info={otherNames.join("; ")} />
        ) : null}
        {ssn && (
          <InfoLine title="Social Security Number" info={FormatSSN(ssn)} />
        )}
        {dob && <InfoLine title="Date of Birth" info={`${FormatDate(dob)}`} />}
        {employment && (
          <InfoLine title="Best Employer" info={employment?.employer} />
        )}
        {otherEmployment && (
          <InfoLine title="Other Employer" info={otherEmployment?.employer} />
        )}
        {address && (
          <InfoVert title="Best Address">
            <AddressList address={address} />
          </InfoVert>
        )}
        {otherAddresses && (
          <InfoVert title="Other Address(es)" last>
            <div className="columns">
              {otherAddresses?.map((a, i) => (
                <AddressList key={`al-${i}`} address={a} />
              ))}
            </div>
          </InfoVert>
        )}
      </div>
    </div>
  );
}

export default Personal;
