export const getSelectedKeys = (path, tasksOpen) => {
  if (tasksOpen) return ["tasks"];
  let currentPage = ["leads"];
  if (path === "/") currentPage = ["leads"];
  if (path.includes("/customer")) currentPage = ["customers"];
  if (path === "/payments") currentPage = ["payments"];
  if (path.includes("/analytics")) currentPage = ["analytics"];
  if (path === "/users") currentPage = ["users"];
  if (path === "/companies") currentPage = ["companies"];
  if (path.includes("/wizard")) currentPage = [""];
  if (path.includes("/workflows")) currentPage = ["workflows"];
  return currentPage;
};
