import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  IconInfoOctagon,
  IconOctagon,
  IconCalendarMonth,
  IconCoin,
  IconRepeat,
  IconClock,
  IconBuildingBank,
} from "@tabler/icons-react";
import { Bank } from "_styleguide";
import { formatMoney } from "_assets/js/helpers";
import { useApolloClient } from "@apollo/client";
import { getDebtAmount } from "_graphql/queries/application";
import "./Cart.scss";

dayjs.extend(advancedFormat);

function Cart(props) {
  const { form } = props;
  const client = useApolloClient();
  const [debtAmount, setDebtAmount] = useState(0);

  const plaidData = form?.plaid ? JSON.parse(form?.plaid) : null;
  let dateValue = undefined;
  const plan = form?.plan;
  if (plan?.start_date) {
    if (plan?.start_date?.length <= 13) {
      dateValue = dayjs(parseInt(plan?.start_date));
    } else {
      dateValue = dayjs(plan?.start_date);
    }
  }

  const durationMap = {
    relaxed: "Steady Course",
    moderate: "Accelerated",
    aggressive: "Rapid Relief",
    custom: "Custom Plan",
  };

  const getAmount = async () => {
    const stateResponse = await client.query({
      query: getDebtAmount,
      variables: {
        application_id: form?.id,
      },
    });
    setDebtAmount(stateResponse?.data?.getDebtAmount);
  };

  useEffect(() => {
    getAmount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const bank = props.bank || null;

  const monthlyCost = () => {
    const totalDebt = plan?.debt_amount || 0;
    const planCost = parseFloat(
      totalDebt * (plan?.service_percent / 100) +
        plan?.monthly_fees * plan?.months,
    );
    return parseFloat(planCost / plan?.months).toFixed(2);
  };

  return (
    <div className={`checkout-cart${props.unpaid ? " unpaid" : ""}`}>
      <div className="cart-body">
        <div className="cb-item">
          <IconOctagon />
          <div>Relief Plan</div>
          <div>
            <span className={`plan-circle duration-${plan.type}`} />
            <b>{durationMap[plan.type]}</b>
          </div>
        </div>
        <div className="cb-item">
          <IconCalendarMonth />
          <div>Payment start date</div>
          <div>{dateValue.format("ddd, MMMM Do, YYYY")}</div>
        </div>
        <div className="cb-item">
          <IconCoin />
          <div>Payment</div>
          <div>{formatMoney(monthlyCost() || 0)}</div>
        </div>
        <div className="cb-item">
          <IconRepeat />
          <div>Frequency</div>
          <div>Monthly</div>
        </div>
        <div className="cb-item">
          <IconClock />
          <div>Debt Free Time Frame</div>
          <div>{plan.months} months</div>
        </div>
        {plaidData && !bank ? (
          <div className="cb-item">
            <IconBuildingBank />
            <div>Bank Account</div>
            <div>
              <Bank {...plaidData} />
            </div>
          </div>
        ) : null}
        {bank ? (
          <div className="cb-item">
            <IconBuildingBank />
            <div>Bank Account</div>
            <div>
              <Bank bankdata={bank} />
            </div>
          </div>
        ) : null}
      </div>
      {!props.unpaid ? (
        <div className="cart-info">
          <IconInfoOctagon />
          <p>
            {formatMoney(plan?.debt_amount || debtAmount)} in debt to be
            relieved!
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Cart;
