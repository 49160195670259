import React from "react";
import { IconReport } from "@tabler/icons-react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export default function Note(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;

  const goToCreditReports = () => {
    navigate(`/customer/${params.id}/credit-reports`);
    props.setTab("credit-reports");
  };

  return (
    <div className={"app-activity " + activity.type}>
      <div className="act-icon note">
        <IconReport />
      </div>
      <div className="descript">
        <b>{user?.name || "Customer"}</b> generated a credit report from{" "}
        <b>Equifax</b>
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToCreditReports}>
          See Credit Report
        </div>
      </div>
    </div>
  );
}
