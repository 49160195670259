import React, { useState, useEffect } from "react";
import { AlertBox, Tabs } from "_styleguide";
import { IconAt, IconDeviceMobile } from "@tabler/icons-react";
import CanvasConfetti from "canvas-confetti";
import SendEmail from "./_components/SendEmail/SendEmail";
import SendText from "./_components/SendText/SendText";
import WorkflowSelection from "./_components/WorkflowSelection/WorkflowSelection";
import BankInfo from "./_components/BankInfo/BankInfo";
import CustomerStatus from "./_components/CustomerStatus/CustomerStatus";
import Review from "../Review/Review";
import { socket } from "_helpers/socket";
import { updateManual } from "App/Admin/Wizard/_helpers/form";
import "./BankAgreement.scss";

function BankAgreement(props) {
  const { form, bank } = props;
  const [customerStatus, setCustomerStatus] = useState(
    socket.connected ? "pending" : "connecting",
  );
  const [plaidData, setPlaidData] = useState(
    bank ? bank : form?.plaid ? JSON.parse(form?.plaid) : null,
  );
  const [manual, setManual] = useState(props.manual);

  const confetti = () => {
    CanvasConfetti({
      particleCount: 200,
      spread: 500,
      origin: { y: 0.3, x: 0.58 },
      zIndex: 2147483647,
      startVelocity: 30,
    });
  };

  useEffect(() => {
    function onConnect() {
      setCustomerStatus("pending");
    }

    function onDisconnect() {
      setCustomerStatus("failed");
    }

    function onNewEvent(message) {
      setCustomerStatus(message?.customerStatus || "pending");
      if (
        message?.customerStatus === "bank" &&
        (message?.data?.metadata || message?.data?.last_four)
      ) {
        setPlaidData(message?.data);
      }
      if (message?.customerStatus === "subscribed") {
        confetti();
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on(`admin-registration-${form.id}`, onNewEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off(`admin-registration-${form.id}`, onNewEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitManual = async (value) => {
    try {
      setManual(value);
      const manualData = await updateManual(props.client, props?.form, value);
      setManual(manualData);
    } catch (err) {
      console.log(err);
    }
  };

  const sendProps = {
    submitManual,
    manual,
  };

  return (
    <div className="wizard-bank-setup">
      <CustomerStatus customerStatus={customerStatus} />
      <WorkflowSelection form={form} />
      {plaidData ? <BankInfo bankData={plaidData} /> : null}
      {!plaidData || props.wasCancelled === true ? (
        <div className="wzrd-box bank-box">
          <div className="wzrd-header">
            <h3>Customer Bank & Subscription</h3>
          </div>
          <div className="wzrd-body">
            <p className="bank-info">
              The last step is to send the customer a <b>url</b> to add their
              bank account and confirm the agreement. Send the url via{" "}
              <b>email</b> or <b>text</b> below:
            </p>
            <AlertBox className="bb-contact">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: (
                      <div className="tab-name">
                        <IconAt />
                        Send email
                      </div>
                    ),
                    children: <SendEmail {...props} {...sendProps} />,
                  },
                  {
                    key: "2",
                    label: (
                      <div className="tab-name">
                        <IconDeviceMobile />
                        Send text message
                      </div>
                    ),
                    children: <SendText {...props} {...sendProps} />,
                  },
                ]}
              />
            </AlertBox>
          </div>
        </div>
      ) : null}
      <Review {...props} />
    </div>
  );
}

export default BankAgreement;
