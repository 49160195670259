import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { getMyTasks } from "App/Admin/_graphql/queries/task";
import { Drawer } from "antd";
import { IconCircleCheckFilled, IconX } from "@tabler/icons-react";
import CompleteTask from "App/Admin/Application/_components/Tasks/_components/CompleteTask/CompleteTask";
import TaskCard from "./_components/TaskCard/TaskCard";
import NoTasks from "./_components/NoTasks/NoTasks";
import { Loading } from "_styleguide";
import "./MyTasks.scss";

function MyTasks(props) {
  const client = useApolloClient();
  const [tab, setTab] = useState("todo");
  const [todo, setTodo] = useState([]);
  const [done, setDone] = useState([]);
  const [loading, setLoading] = useState(true);
  const [completeModal, setCompleteModal] = useState(false);
  const [expanded, setExpanded] = useState([]);

  const fetchTasks = async () => {
    const queryData = await client.query({
      query: getMyTasks,
    });
    let todoTasks = queryData?.data?.getMyTasks?.todo || [];
    todoTasks = todoTasks.filter((t) => {
      return t.application?.status === "subscription";
    });
    if (props.setTaskCount) props.setTaskCount(todoTasks?.length || 0);
    setTodo(todoTasks);
    setDone(queryData?.data?.getMyTasks?.done || []);
    setLoading(false);
  };
  const toggle = (tab) => setTab(tab);

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const todoCount = todo?.length || 0;
  const doneCount = done?.length || 0;

  return (
    <Drawer
      width={456}
      onClose={props.onClose}
      open={props.open}
      maskClosable={true}
      mask={true}
      placement="left"
      className="my-tasks-drawer"
      rootClassName={`my-tasks-parent${props.collapsed ? " collapsed" : ""}`}
      destroyOnClose={true}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {todo?.length <= 0 && done?.length <= 0 ? (
            <>
              <div className="new-task-title">
                My Tasks
                <div className="tc-close" onClick={props.onClose}>
                  <IconX />
                </div>
              </div>
              <div className="my-tasks-body">
                <NoTasks />
              </div>
            </>
          ) : (
            <>
              {completeModal ? (
                <CompleteTask
                  taskId={completeModal}
                  reload={fetchTasks}
                  removeModal={() => setCompleteModal(false)}
                />
              ) : null}
              <div className="new-task-title">
                My Tasks
                <div className="tc-close" onClick={props.onClose}>
                  <IconX />
                </div>
              </div>
              <div className="at-tabs">
                <div
                  className={tab === "todo" ? "at-active" : ""}
                  onClick={() => toggle("todo")}
                >
                  To do <span className="tc-count">{todoCount}</span>
                </div>
                <div
                  className={tab === "done" ? "at-active" : ""}
                  onClick={() => toggle("done")}
                >
                  <IconCircleCheckFilled />
                  Done <span className="tc-count">{doneCount}</span>
                </div>
              </div>
              <div className="my-tasks-body">
                {tab === "todo" ? (
                  <div className="at-list">
                    {todo.map((t) => (
                      <TaskCard
                        key={t.id}
                        task={t}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        setCompleteModal={setCompleteModal}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="at-list">
                    {done.map((t) => (
                      <TaskCard
                        key={t.id}
                        task={t}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        setCompleteModal={setCompleteModal}
                      />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Drawer>
  );
}

export default MyTasks;
