import React, { useState, useEffect } from "react";
import { IconTrendingUp, IconTrendingDown } from "@tabler/icons-react";
import { AutoComplete, Input } from "antd";
import {
  budgetOptions,
  incomeCategories,
  determineType,
} from "_helpers/budget";
import { InputNumber, Loading } from "_styleguide";
import Overview from "./_components/Overview/Overview";
import { getBudget } from "App/Admin/_graphql/queries/budget";
import { useApolloClient } from "@apollo/client";
import "./Budget.scss";

const BudgetItem = (props) => (
  <div className={`budget-item`}>
    <h5>{props.name}</h5>
    <InputNumber
      controls={false}
      value={props.value}
      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      onChange={(value) => props.updateItemValue(props.name, value)}
    />
  </div>
);

function Budget(props) {
  const client = useApolloClient();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const { form, budget } = props;
  const { setBudgetItems, setSavedBudgetItems } = budget;

  const renderTitle = (title, type, actual) => (
    <span className="budget-pop-title">
      {type === "income" ? <IconTrendingUp /> : <IconTrendingDown />}
      {title}&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{actual}
    </span>
  );

  const options = [];
  Object.keys(budgetOptions).forEach((key) => {
    const isIncome = incomeCategories.includes(key);
    const optionsList = (budgetOptions[key] || []).filter((ol) => {
      return !budget.items.find((b) => b.name === ol);
    });
    if (optionsList.length > 0) {
      optionsList.forEach((n) => {
        options.push({
          label: renderTitle(key, isIncome ? "income" : "expense", n),
          value: n,
        });
      });
    }
  });

  const updateItemValue = (name, newValue) => {
    const updatedItems = budget.items.map((item) => {
      if (item.name === name) {
        return { ...item, value: newValue };
      }
      return item;
    });
    setBudgetItems(updatedItems);
  };

  const selectItem = (value) => {
    const exists = budget.items.find((b) => b.name === value);
    if (exists) return;
    const valueType = determineType(value);
    setBudgetItems([...budget.items, valueType]);
    setTimeout(() => setSearch(undefined), 0);
  };

  const loadBudget = async () => {
    const stateResponse = await client.query({
      query: getBudget,
      variables: {
        application_id: form?.id,
      },
    });
    const newState = stateResponse?.data?.getBudget;
    const initial = newState?.items || [];
    setBudgetItems(initial);
    setSavedBudgetItems(JSON.stringify(initial.sort()));
    setLoading(false);
  };

  useEffect(() => {
    loadBudget();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <Loading />
  ) : (
    <div className="admin-wizard-budget">
      <Overview budget={budget?.items || []} />
      <div className="wzrd-box">
        <div className="wzrd-header">
          <h3>Calculator</h3>
        </div>
        <div className="wzrd-body">
          <AutoComplete
            popupClassName="budget-pop"
            popupMatchSelectWidth="100%"
            style={{ width: "100%" }}
            options={options}
            onSelect={selectItem}
            onChange={(value) => setSearch(value)}
            value={search}
            filterOption={(inputValue, option) => {
              return (option?.value || "")
                .toLowerCase()
                .includes(inputValue?.toLowerCase());
            }}
          >
            <Input.Search
              size="large"
              placeholder="Search income & expenses..."
            />
          </AutoComplete>
          <div className="budget-list">
            <div className="budget-income">
              <div className="bl-title">
                <IconTrendingUp />
                Income
              </div>
              <div className="bl-items">
                {budget.items
                  .filter((b) => b.type === "income")
                  .map((b, i) => (
                    <BudgetItem
                      key={`bl-income-${i}`}
                      {...b}
                      updateItemValue={updateItemValue}
                    />
                  ))}
              </div>
            </div>
            <div className="budget-sep" />
            <div className="budget-expenses">
              <div className="bl-title">
                <IconTrendingDown />
                Expenses
              </div>
              <div className="bl-items">
                {budget.items
                  .filter((b) => b.type === "expense")
                  .map((b, i) => (
                    <BudgetItem
                      key={`bl-expense-${i}`}
                      {...b}
                      updateItemValue={updateItemValue}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Budget;
