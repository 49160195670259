import React, { useState, useEffect } from "react";
import { Tabs } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getCustomerCounts } from "../_graphql/queries/application";
import { IconReport } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveCustomers from "./_components/ActiveCustomers/ActiveCustomers";
import "./Subscriptions.scss";

function Subscriptions(props) {
  const navigate = useNavigate();
  const params = useParams();
  const client = useApolloClient();
  const [tab, setTab] = useState(params?.tab || "active");
  const [counts, setCounts] = useState();

  const abstractProps = {
    ...props,
  };

  let items = [
    {
      key: "active",
      label: (
        <div className="tab-name success">
          <IconReport />
          Active {counts ? `(${counts?.active})` : null}
        </div>
      ),
      children: <ActiveCustomers category="active" {...abstractProps} />,
    },
    {
      key: "paused",
      label: (
        <div className="tab-name warning">
          <IconReport />
          Paused {counts ? `(${counts?.paused})` : null}
        </div>
      ),
      children: <ActiveCustomers category="paused" {...abstractProps} />,
    },
    {
      key: "cancelled",
      label: (
        <div className="tab-name danger">
          <IconReport />
          Cancelled {counts ? `(${counts?.cancelled})` : null}
        </div>
      ),
      children: <ActiveCustomers category="cancelled" {...abstractProps} />,
    },
    {
      key: "modifying",
      label: (
        <div className="tab-name">
          <IconReport />
          Modifying {counts ? `(${counts?.modifying})` : null}
        </div>
      ),
      children: <ActiveCustomers category="modifying" {...abstractProps} />,
    },
  ];

  const fetchCounts = async () => {
    const applicationData = await client.query({
      query: getCustomerCounts,
    });
    setCounts(applicationData?.data?.getCustomerCounts);
  };

  useEffect(() => {
    fetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-tabs customer-tabs">
      <Tabs
        onChange={(key) => {
          navigate(`/customers/${key}`);
          setTab(key);
        }}
        activeKey={tab}
        items={items}
        className={tab}
        destroyInactiveTabPane={true}
      />
    </div>
  );
}

export default Subscriptions;
