import React, { useState } from "react";
import { FormInput, InputLabel } from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { validateInformation } from "App/Admin/Wizard/_helpers/validateWizard";
import { FormatPhone } from "_assets/js/helpers";
import { sendCustomerText } from "App/Admin/Wizard/_helpers/form";
import { getErrors } from "_helpers/api";
import { Switch } from "antd";

function SendText(props) {
  const [phone, setPhone] = useState(props?.form?.phone);
  const [saving, setSaving] = useState(false);

  const validation = validateInformation({ phone });

  const submitText = async () => {
    try {
      setSaving(true);
      await sendCustomerText(
        props.client,
        props?.form,
        phone,
        props.newAccount,
      );
      message.success("Completion text message sent to: " + phone);
      setSaving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error sending text message, try again");
      setSaving(false);
    }
  };
  return (
    <div className="wizard-send">
      <InputLabel htmlFor="phone" {...validation.phone}>
        Confirm cell phone number
      </InputLabel>
      <FormInput
        id="phone"
        value={phone}
        onChange={(e) => setPhone(FormatPhone(e.target.value))}
        size="medium"
        {...validation.phone}
      />
      <div className="ws-actions">
        <div className="ws-toggle">
          <Switch
            size="small"
            checked={props.manual}
            onChange={(checked) => props.submitManual(checked)}
          />
          Allow account & routing entry
        </div>
        <Button
          className="green-btn"
          type="primary"
          size="small"
          loading={saving}
          disabled={!validation?.phone?.valid}
          onClick={submitText}
        >
          Send text message
        </Button>
      </div>
    </div>
  );
}

export default SendText;
