import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getTeam } from "App/Admin/_graphql/queries/team";
import { updateTeam, removeFromTeam } from "App/Admin/_graphql/mutations/team";
import { getUsers } from "App/Admin/_graphql/queries/user";
import { getErrors } from "_helpers/api";
import { IconX, IconPlus } from "@tabler/icons-react";
import { Select, InputLabel, Flex, Loading } from "_styleguide";
import "./ManageTeam.scss";

function ManageTeam(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [team, setTeam] = useState();
  const [selected, setSelected] = useState([]);
  const [users, setUsers] = useState([]);

  // Load Payments
  const addTeam = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          team_id: props?.teamId,
          user_ids: selected,
        },
        mutation: updateTeam,
      });
      message.success(`User(s) successfully added`);
      setSelected();
      if (props.reload) props.reload();
      fetchTeam();
      setSaving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error adding to team, try again");
      setSaving(false);
    }
  };
  const removeTeam = async (user_id) => {
    setRemoving(user_id);
    try {
      await client.mutate({
        variables: {
          team_id: props?.teamId,
          user_id: user_id,
        },
        mutation: removeFromTeam,
      });
      message.success(`User successfully removed`);
      if (props.reload) props.reload();
      fetchTeam();
      setRemoving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error removing from team, try again");
      setRemoving(false);
    }
  };
  const fetchTeam = async () => {
    const queryData = await client.query({
      variables: { team_id: props?.teamId },
      query: getTeam,
    });
    setLoading(false);
    setTeam(queryData?.data?.getTeam);
  };
  const fetchUsers = async () => {
    const usersData = await client.query({
      query: getUsers,
    });
    setUsers(usersData?.data?.getUsers || []);
  };

  useEffect(() => {
    fetchTeam();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actualUsers = users.filter((u) => {
    return !team?.users?.find((j) => j.id === u.id);
  });

  return (
    <Modal
      wrapClassName="manage-team-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={loading ? true : false}
      width={456}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <Button
            className="boxed mt-close"
            type="blanco"
            size="small"
            onClick={() => setVisible(false)}
          >
            <IconX />
          </Button>

          <Modal.Body>
            <div className="mt-team">
              <div className="team-avatar">
                <span>{team?.name[0].toUpperCase()}</span>
              </div>
              <h4>{team.name}</h4>
              <p>{team.users?.length || 0} Members</p>
            </div>
            <div className="mt-form">
              <Flex vertical={true}>
                <InputLabel htmlFor="notify">Add to {team.name}</InputLabel>
                <Select
                  disabled={saving}
                  mode="multiple"
                  allowClear
                  value={selected}
                  onChange={(val) => setSelected(val)}
                  size="extra-medium"
                  popupClassName="pp-select-dropdown ppsd-task"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {actualUsers?.map((u) => (
                    <Select.Option key={u.id} value={u.id}>
                      {u.name}
                    </Select.Option>
                  ))}
                </Select>
                {selected?.length > 0 ? (
                  <Button
                    className="boxed mt-add"
                    block
                    type="primary"
                    loading={saving}
                    onClick={addTeam}
                  >
                    <IconPlus />
                    Add {selected?.length} member(s) to team
                  </Button>
                ) : null}
              </Flex>
            </div>
            <div className="mt-users">
              {(team?.users || []).map((u) => (
                <div key={"mt-" + u.id}>
                  <div className="mtu-info">
                    <h4>{u.name}</h4>
                    <p>{u.email}</p>
                  </div>
                  <Button
                    loading={removing === u.id}
                    className="boxed"
                    type="nope"
                    size="small"
                    onClick={() => removeTeam(u.id)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default ManageTeam;
