export const budgetOptions = {
  "Monthly Income": [
    "Alimony",
    "Child Support",
    "Rent / Other Income",
    "Salary / Wages",
    "Self-Employment Income",
    "Social Security",
    "Retirement Income",
  ],
  "Liquid Assets": ["Checking Account", "Savings", "Account", "Cash"],
  "Other Assets": ["Property", "Stocks, Bonds, 401k", "Vehicles", "Others"],
  "Living Essentials": [
    "Food",
    "Cable TV",
    "Clothing",
    "Household Items",
    "Mortgage / Rent",
    "Telephone",
    "Utilities",
  ],
  Education: ["Education", "Student Loans"],
  "Debt Obligations": ["2nd Mortgage / Rent", "Child Support / Alimony"],
  Insurance: ["Home Insurance", "Life Insurance", "Auto Insurance"],
  "Personal Expenses": [
    "Auto Loans",
    "Auto Other",
    "Charity / Donations",
    "Entertainment",
    "Gym / Health",
    "Medical Bills",
    "Medical Care",
    "Misc / Other",
    "Personal Care",
    "Tickets / Fines",
  ],
};

export const incomeCategories = [
  "Monthly Income",
  "Liquid Assets",
  "Other Assets",
];

export const expenseCategories = [
  "Living Essentials",
  "Education",
  "Debt Obligations",
  "Insurance",
  "Personal Expenses",
];

export const cashCategories = ["Liquid Assets"];
export const assetCategories = ["Liquid Assets", "Other Assets"];

export const determineType = (item) => {
  let category = null;
  Object.keys(budgetOptions).forEach((key) => {
    budgetOptions[key].forEach((n) => {
      if (n === item) {
        category = key;
      }
    });
  });
  const isIncome = incomeCategories.includes(category);
  return {
    name: item,
    category: category,
    type: isIncome ? "income" : "expense",
    value: 0,
  };
};
