import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { activateBankAccount } from "App/Admin/_graphql/mutations/bank_account";
import { getErrors } from "_helpers/api";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import "./ActiveAccount.scss";

function ActiveAccount(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);

  // Activat Account
  const activateBank = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          bank_account_id: props.account?.id,
        },
        mutation: activateBankAccount,
      });
      message.success(`Bank account set as active`);
      if (props.reload) props.reload();
      if (props.reloadApplication) props.reloadApplication();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) || "Error setting bank account as active, try again",
      );
      setSaving(false);
    }
  };

  return (
    <Modal
      wrapClassName="active-account-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div className="pp-mh-icon warning">
          <IconAlertTriangleFilled />
        </div>
        {`Set "${props.account?.name}" as Active?`}
      </div>

      <Modal.Body>
        <div className="ba-content">
          <p>
            You are attempting to activate an inactive bank account and make it
            the primary active account for payments.
          </p>

          <div className="ba-desc">
            <div>
              <b> Activating this inactive account will:</b>
            </div>
            <div>
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;Deactivate the currently active bank
              account (if any).
            </div>
            <div>
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;Associate all future transactions
              with the newly activated account
            </div>
          </div>
          <div className="ba-desc">
            <b>
              Confirm you want to proceed with activating this inactive bank
              account.
            </b>
          </div>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          className="green-btn"
          type="primary"
          onClick={activateBank}
          loading={saving}
        >
          Set Account Active
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ActiveAccount;
