import React from "react";
import { Input as AntInput } from "_styleguide";
import { usePlacesWidget } from "react-google-autocomplete";
import { parseAddress } from "_assets/js/helpers";
import "./Autocomplete.scss";

function Autocomplete(props) {
  const { ref: antRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      const actual = parseAddress(place?.address_components);
      const newData = {
        address:
          (actual?.street_number?.long || "") +
          (actual?.route?.long ? " " + actual?.route?.long : ""),
        city:
          actual?.locality?.long ||
          actual?.administrative_area_level_3?.long ||
          actual?.sublocality_level_1?.long ||
          actual?.political?.long ||
          "",
        state: actual?.administrative_area_level_1?.short || "",
        zip: actual?.postal_code?.long || "",
      };
      const attributeVal = antRef?.current?.getAttribute("form");
      if (props.form && attributeVal) {
        const oldForm = JSON.parse(attributeVal);
        props.updateAddress(oldForm, newData);
      } else {
        props.updateAddress(newData);
      }
    },
  });

  let className = "barn-autocomplete";
  if (props.started && !props.valid) {
    className += " pp-invalid";
  }
  if (props.size === "large") {
    className += " pp-large";
  }
  if (props.size === "medium") {
    className += " pp-medium";
  }
  return (
    <AntInput
      placeholder="123 Downing Dr"
      value={props.value}
      form={JSON.stringify(props.form)}
      onChange={(e) => props.onChange(e.target.value)}
      ref={(c) => {
        if (c) antRef.current = c.input;
      }}
      size="large"
      className={className}
      type="text"
    />
  );
}

export default Autocomplete;
