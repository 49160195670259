import moment from "moment";

export const generate = (plan) => {
  if (plan?.payment_schedule === "monthly" && plan?.start_date) {
    return generateMonthly(plan);
  } else if (
    plan?.payment_schedule === "semi-monthly" &&
    plan?.start_date &&
    plan?.next_date
  ) {
    return generateSemiMonthly(plan);
  } else if (plan?.payment_schedule === "bi-weekly" && plan?.start_date) {
    return generateBiWeekly(plan);
  }
  return [];
};

const generateMonthly = (plan) => {
  const planCost = totalCost(plan);
  const payments = [];
  const paymentAmount = parseFloat(planCost / plan?.months);
  for (let i = 0; i < plan?.months; i++) {
    const initialDate = moment(formatDate(plan?.start_date))
      .add(i, "months")
      .startOf("day");
    const correctDate = noWeekends(initialDate, plan);
    payments.push({
      draft_date: correctDate?.toDate(),
      amount: paymentAmount,
    });
  }
  return payments;
};

const generateSemiMonthly = (plan) => {
  let planCost = totalCost(plan);
  const totalPayments = parseInt(plan?.months * 2);
  const paymentAmount = parseFloat(planCost / totalPayments);
  const payments = [];
  for (let i = 0; i < plan?.months; i++) {
    const initialStartDate = moment(formatDate(plan?.start_date))
      .add(i, "months")
      .startOf("day");
    const correctStartDate = noWeekends(initialStartDate, plan);
    payments.push({
      draft_date: correctStartDate?.toDate(),
      amount: paymentAmount,
    });
    const initialNextDate = moment(formatDate(plan?.next_date))
      .add(i, "months")
      .startOf("day");
    const correctNextDate = noWeekends(initialNextDate, plan);
    payments.push({
      draft_date: correctNextDate?.toDate(),
      amount: paymentAmount,
    });
  }
  return payments;
};

const generateBiWeekly = (plan) => {
  let planCost = totalCost(plan);
  const startDate = moment(formatDate(plan?.start_date));
  const endDate = moment(formatDate(plan?.start_date)).add(
    plan?.months,
    "months",
  );
  const totalDays = endDate.diff(startDate, "days");
  const totalPayments = parseInt(Math.floor(totalDays / 14));
  const paymentAmount = parseFloat(planCost / totalPayments);
  const payments = [];
  for (let i = 0; i < totalPayments; i++) {
    const initialDate = moment(formatDate(plan?.start_date))
      .add(i * 2, "weeks")
      .startOf("day");
    payments.push({
      draft_date: initialDate?.toDate(),
      amount: paymentAmount,
    });
  }
  return payments;
};

/* HELPERS BELOW - sunday 0 - saturday 6 - monday 1 */
const noWeekends = (date, plan) => {
  const isWeekend = [0, 6].includes(date.day()) ? true : false;
  if (isWeekend && plan?.weekend_payments === "monday") {
    if (date.day() === 0) date.add(1, "days");
    if (date.day() === 6) date.add(2, "days");
    return date;
  } else if (isWeekend) {
    if (date.day() === 0) date.subtract(2, "days");
    if (date.day() === 6) date.subtract(1, "days");
    return date;
  } else {
    return date;
  }
};

const totalCost = (plan) => {
  const totalDebt = plan?.debt_amount || 0;
  return parseFloat(
    totalDebt * (plan?.service_percent / 100) +
      plan?.monthly_fees * plan?.months,
  );
};

export const formatDate = (value) => {
  if (typeof value === "string" || value instanceof String) {
    return parseInt(value);
  } else {
    return value;
  }
};
