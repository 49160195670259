import React, { useState, useEffect } from "react";
import { Button, Text, Modal } from "@centrate-io/barn";
import { Checkbox, Tag } from "_styleguide";
import { accountTypeCredit, accountTypeLoan } from "_helpers/experian";
import { flatMoney } from "_assets/js/helpers";
import {
  IconChevronDown,
  IconChevronUp,
  IconCheck,
  IconX,
  IconAlertTriangle,
  IconPlus,
} from "@tabler/icons-react";
import CustomCredit from "../../_modals/CustomCredit/CustomCredit";
import "./CreditSelect.scss";

const AccountBox = (props) => {
  const { data } = props;
  return (
    <div className="tradeline">
      <Checkbox
        checked={props?.selected}
        onChange={props.toggleTradeline}
        size="large"
      />
      <div className="account-box">
        <div className="top-line">
          <div>
            {data?.customerName}
            {props?.isCustom ? (
              <Tag type="success" size="small">
                Custom
              </Tag>
            ) : null}
          </div>
          <div>{flatMoney(parseInt(data?.balanceAmount || 0))}</div>
        </div>
        <div className="bottom-line">
          <div>
            {data?.accountNumber} - {data?.accountTypeText}
          </div>
          <div>Balance Owed</div>
        </div>
      </div>
    </div>
  );
};

function CreditSelect(props) {
  const { tradelines, setTradelines, setSavedTradelines } = props.credit;
  const [showDeclined, setShowDeclined] = useState(false);
  const [customModal, setCustomModal] = useState(false);

  let totalApproved = 0;
  const approved = [],
    declined = [];

  (props?.tradeline || []).forEach((t) => {
    const isApproved =
      accountTypeLoan.includes(t.tradeline.accountType) ||
      accountTypeCredit.includes(t.tradeline.accountType);
    const isCustom = t.is_custom === true;
    const balanceAmount = parseInt(t.tradeline?.balanceAmount || 0);
    const isOpen = t.tradeline.openOrClosed === "C" ? false : true;
    if (isCustom || (isApproved && balanceAmount > 0 && isOpen)) {
      totalApproved += balanceAmount;
      approved.push(t);
    } else {
      declined.push(t);
    }
  });

  useEffect(() => {
    const initial = [];
    approved.forEach((a) => {
      if (!props.fromLoad) {
        initial.push(a.id);
      } else if (a.selected && props.fromLoad) {
        initial.push(a.id);
      }
    });
    declined.forEach((a) => {
      if (a.selected && props.fromLoad) {
        initial.push(a.id);
      }
    });
    setTradelines(initial);
    setSavedTradelines(JSON.stringify(initial.sort()));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function listSort(a, b) {
    if (a.is_custom && !b.is_custom) return -1;
    if (!a.is_custom && b.is_custom) return 1;
    return (
      parseInt(b?.tradeline?.balanceAmount || 0) -
      parseInt(a?.tradeline?.balanceAmount || 0)
    );
  }

  const confirmToggle = (tradeline_id) => {
    Modal.confirm({
      title: "Are you sure you want select this credit line?",
      okText: "Select Credit Line",
      content:
        "Verify that this tradeline is eligible as an unsecured line of credit and can be selected before continuing.",
      onOk: () => toggleTradeline(tradeline_id),
    });
  };

  const toggleTradeline = (tradeline_id) => {
    let newTradelines = [];
    if (tradelines.includes(tradeline_id)) {
      newTradelines = tradelines.filter((t) => t !== tradeline_id);
    } else {
      newTradelines = [...tradelines, tradeline_id];
    }
    setTradelines(newTradelines);
  };

  const addNewTradeline = (tradeline_id) => {
    let newTradelines = [];
    newTradelines = [...tradelines, tradeline_id];
    setTradelines(newTradelines);
  };

  let approvedBalance = 0;
  approved
    .filter((a) => tradelines.includes(a.id))
    .forEach((a) => {
      approvedBalance += parseInt(a.tradeline?.balanceAmount || 0);
    });
  declined
    .filter((a) => tradelines.includes(a.id))
    .forEach((a) => {
      approvedBalance += parseInt(a.tradeline?.balanceAmount || 0);
    });

  const nonQualified = totalApproved < 5000;
  const notEnough = !nonQualified && approvedBalance < 5000;

  return (
    <div className="admin-wizard-credit">
      {customModal ? (
        <CustomCredit
          reload={props.reload}
          applicationId={props.form.id}
          addNewTradeline={addNewTradeline}
          removeModal={() => setCustomModal(false)}
        />
      ) : null}
      <div className="approved-desc wzrd-trade-footer">
        <div>
          <b>{tradelines?.length}</b>&nbsp; credit lines selected
        </div>
        <h5 className="approved-total">Total {flatMoney(approvedBalance)}</h5>
      </div>

      <div className="wzrd-box">
        <div className="wzrd-header">
          <div className="circle">
            {nonQualified ? <IconAlertTriangle /> : <IconCheck />}
          </div>
          <h3>Eligible credit lines</h3>
          <Button
            onClick={() => setCustomModal(true)}
            className="boxed"
            type="blanco"
            size="small"
          >
            <IconPlus />
            Add Credit Line
          </Button>
        </div>
        {approved.length > 0 ? (
          <div className="wzrd-body approved">
            {nonQualified && (
              <div className={`alert-box`}>
                You do not have enough eligible credit available to continue
                with the relief program.
              </div>
            )}
            {notEnough && (
              <div className={`alert-box danger`}>
                Please select a minimum of $7,500 of your eligible credit lines
                to unlock your savings.
              </div>
            )}
            <div className="credit-list">
              {approved.sort(listSort).map((c, i) => (
                <AccountBox
                  key={`abox-${i}`}
                  type="approved"
                  data={c.tradeline}
                  isCustom={c.is_custom}
                  selected={tradelines.includes(c.id)}
                  toggleTradeline={() => toggleTradeline(c.id)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="wzrd-body approved">
            <div className={`alert-box danger`}>
              No approved credit lines found
            </div>
          </div>
        )}
      </div>

      <div className="wzrd-box">
        <div className="wzrd-header">
          <div className="circle danger">
            <IconX />
          </div>
          <h3>Ineligible credit lines</h3>
        </div>
        {declined.length > 0 ? (
          <div className="wzrd-body declined">
            <Text className="declined-desc">
              The following credit lines are not supported by our relief
              programs. Ineligible includes secured debts like mortgages, car
              loans & student loans.
            </Text>

            {showDeclined && (
              <div className="credit-list">
                {declined.sort(listSort).map((c, i) => (
                  <AccountBox
                    key={`dbox-${i}`}
                    type="declined"
                    data={c.tradeline}
                    isCustom={c.is_custom}
                    selected={tradelines.includes(c.id)}
                    toggleTradeline={() => confirmToggle(c.id)}
                  />
                ))}
              </div>
            )}
            <Button
              type="secondary"
              onClick={() => setShowDeclined(showDeclined ? false : true)}
            >
              {showDeclined && (
                <React.Fragment>
                  Hide ineligible credit <IconChevronUp />
                </React.Fragment>
              )}
              {!showDeclined && (
                <React.Fragment>
                  See {declined?.length} ineligible <IconChevronDown />
                </React.Fragment>
              )}
            </Button>
          </div>
        ) : (
          <div className="wzrd-body approved">
            <div className={`alert-box danger`}>No ineligible lines found</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreditSelect;
