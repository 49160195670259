import { gql } from "@apollo/client";

export const getApplication = gql`
  query getApplication($id: ID, $short_id: ID) {
    getApplication(id: $id, short_id: $short_id) {
      id
      status
      ssn_verified
      first_name
      last_name
      email
      phone
      ssn_last_4
      address
      address_line_two
      city
      state
      zip
      plaid

      plan {
        type
        amount
        service_percent
        monthly_fees
        debt_amount
        months
        payment_schedule
        weekend_payments
        start_date
        next_date
      }

      audit {
        agreement_url
      }
    }
  }
`;

export const getCheckoutApplication = gql`
  query getApplication($id: ID, $short_id: ID) {
    getApplication(id: $id, short_id: $short_id) {
      id
      status
      ssn_verified
      first_name
      last_name
      email
      phone
      ssn_last_4
      address
      address_line_two
      city
      state
      zip
      plaid

      allow_manual

      bank {
        id
        name
        logo
        url
        account_type
        last_four
      }

      plan {
        type
        amount
        service_percent
        monthly_fees
        debt_amount
        months
        payment_schedule
        weekend_payments
        start_date
        next_date
      }

      audit {
        agreement_url
      }
    }
  }
`;

export const getWizardApplication = gql`
  query getApplication($id: ID) {
    getApplication(id: $id) {
      id
      short_id
      status
      allow_manual
      first_name
      last_name
      has_co_applicant
      co_first_name
      co_last_name
      email
      phone
      ssn_last_4
      address
      address_line_two
      city
      state
      zip
      plaid
      was_cancelled

      bank {
        id
        name
        logo
        url
        account_type
        last_four
      }

      plan {
        type
        amount
        service_percent
        monthly_fees
        debt_amount
        months
        payment_schedule
        weekend_payments
        start_date
        next_date
      }
    }
  }
`;

export const getCreditState = gql`
  query getCreditState($application_id: ID!) {
    getCreditState(application_id: $application_id) {
      tradelines {
        id
        short_id
        application_id
        credit_report_id
        selected
        is_custom
        tradeline {
          accountNumber
          accountType
          accountTypeText
          balanceAmount
          accountStatus
          openOrClosed
          customerName
          customerNumber
        }
      }
      ssns
      state
    }
  }
`;

export const getDebtAmount = gql`
  query getDebtAmount($application_id: ID!) {
    getDebtAmount(application_id: $application_id)
  }
`;

export const getAgreementData = gql`
  query getAgreementData($application_id: ID!) {
    getAgreementData(application_id: $application_id) {
      application {
        id
        audit {
          account_date
          credit_report_date
          plaid_date
        }
        bank {
          id
          name
          logo
          url
          account_type
          last_four
        }
      }
      tradelines {
        id
        is_custom
        tradeline {
          accountNumber
          customerName
          balanceAmount
        }
      }
      payments {
        draft_date
        amount
      }
      ip_address
    }
  }
`;
