import React from "react";
import { IconCreditCard } from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export default function Status(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const status = activity.tradelines;
  let icon = <IconCreditCard />;

  const goToTradelines = () => {
    navigate(`/customer/${params.id}/tradelines`);
    props.setTab("tradelines");
  };

  return (
    <div className={"app-activity status-" + status.type}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {status.type === "added" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> added{" "}
            <b>{status.amount} credit lines</b>
            with a total debt of <b>{formatMoney(status?.debt_amount || 0)}</b>
          </React.Fragment>
        )}
        {status.type === "selected" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> selected{" "}
            <b>{status.amount} credit lines</b> with a total debt of{" "}
            <b>{formatMoney(status?.debt_amount || 0)}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <div className="pp-link" onClick={goToTradelines}>
          See Credit Lines
        </div>
      </div>
    </div>
  );
}
