import { Button } from "@centrate-io/barn";
import moment from "moment";

export const getColumns = (setManageModal) => {
  return [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
      render: (name, data) => (
        <div className="team-info">
          <div className="team-avatar">
            <span>{name[0].toUpperCase()}</span>
          </div>
          <div className="team-data">
            <h4>{name}</h4>
            <p>
              {data.user_count} Members&nbsp;&nbsp;&bull;&nbsp;&nbsp; Created{" "}
              {moment(data.createdAt * 1).format("MMM D, YYYY")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 100,
      align: "right",
      fixed: "right",
      render: (manage, data) => {
        return (
          <div className="table-actions" style={{ justifyContent: "flex-end" }}>
            <Button
              type="secondary"
              size="small"
              className="boxed"
              onClick={() => setManageModal(data.id)}
            >
              Manage
            </Button>
          </div>
        );
      },
    },
  ];
};
