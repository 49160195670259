import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { getColumns } from "./_data/Columns";
import { flatMoney } from "_assets/js/helpers";
import { estimatedPayments } from "_graphql/queries/payments";
import "./Schedule.scss";

function Schedule(props) {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const { plan } = props;

  const columns = getColumns();

  useEffect(() => {
    const checkState = async () => {
      setLoading(true);
      const stateResponse = await props.client.query({
        query: estimatedPayments,
        variables: {
          plan: plan,
        },
      });
      const newState = stateResponse?.data?.estimatedPayments || [];
      setPayments(newState);
      setLoading(false);
    };
    checkState();
  }, [
    plan,
    plan.payment_schedule,
    plan.next_date,
    plan.start_date,
    plan.weekend_payments,
    props.client,
  ]);

  const newPayments = [...payments]
    .map((p, i) => {
      return {
        ...p,
        id: i + 1,
      };
    })
    .sort((a, b) => a.id - b.id);

  return (
    <div className="wzrd-box admin-custom-plan">
      <div className="wzrd-header">
        <h3>Estimated Payments ({payments?.length})</h3>
        <p>{flatMoney(plan?.debt_amount)} selected debt</p>
      </div>
      <div className="wzrd-body wzrd-schedule">
        <Table
          loading={loading}
          columns={columns}
          rowKey={(record) => record.draft_date?.toString()}
          dataSource={newPayments}
          locale={{
            emptyText: loading
              ? "Loading payments..."
              : "Payments will generate once schedule is selected",
          }}
        />
      </div>
    </div>
  );
}

export default Schedule;
