import { Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Forgot from "./Forgot/Forgot";
import Reset from "./Reset/Reset";
import Company from "./Company/Company";
import User from "./User/User";
import TwoFactor from "./TwoFactor/TwoFactor";
import "./Auth.scss";

function Auth(props) {
  return (
    <div className="auth-main">
      <div className="auth-box">
        <Routes>
          <Route exact path="/" element={<Login {...props} />} />
          <Route exact path="/forgot" element={<Forgot {...props} />} />
          <Route exact path="/reset/:id" element={<Reset {...props} />} />
          <Route
            exact
            path="/onboard/company/:id"
            element={<Company {...props} />}
          />
          <Route exact path="/onboard/user/:id" element={<User {...props} />} />
        </Routes>
      </div>
    </div>
  );
}

export const VerifyRoutes = (props) => {
  return (
    <div className="auth-main">
      <div className="auth-box">
        <Routes>
          <Route exact path="/" element={<TwoFactor {...props} />} />
        </Routes>
      </div>
    </div>
  );
};

export default Auth;
