import React from "react";
import { Button, Tooltip } from "@centrate-io/barn";
import Equifax from "_assets/logos/equifax.svg";
import { FormInput, InputLabel } from "_styleguide";
import { FormatSSN } from "_assets/js/helpers";
import { IconInfoCircle } from "@tabler/icons-react";

function RunReport(props) {
  const { social, setSocial } = props;
  return (
    <div className="admin-wizard-credit">
      <div className="wzrd-box">
        <div className="wzrd-header">
          <h3>
            Run <u>Soft</u> Credit Check
          </h3>
        </div>
        <div className="wzrd-body">
          <div className="experian-button">
            Brought to you by <img src={Equifax} alt="Equifax" />
          </div>
          <div className="credit-confirmation">
            You understand that by clicking on the{" "}
            <b>“I Agree, Run Soft Credit Inquiry”</b> button immediately
            following this notice, you are providing ‘written instructions’ to
            Paypath under the Fair Credit Reporting Act authorizing Paypath to
            obtain information from your personal credit report or other
            information from Equifax. You authorize Paypath to obtain such
            information solely to conduct a pre-qualification for credit.
          </div>
          <div className="form-item" style={{ marginBottom: "32px" }}>
            <InputLabel htmlFor="ssn">
              Social Security Number{" "}
              <Tooltip title="Only use the clients SSN if their report cannot be found with current information">
                <span>
                  Optional <IconInfoCircle />
                </span>
              </Tooltip>
            </InputLabel>
            <FormInput
              id="email"
              value={social}
              onChange={(e) => setSocial(FormatSSN(e.target.value, true))}
              size="medium"
              placeholder="111-22-3333"
            />
          </div>
          <Button
            loading={props.loadingReport}
            type="primary"
            size="large"
            block
            disabled={social?.length > 0 && social?.length < 11}
            onClick={props.handleClick}
          >
            I Agree, Run Soft Credit Check
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RunReport;
