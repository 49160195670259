import React from "react";
import { condition } from "_helpers/experian";
import { flatMoney, FormatDate } from "_assets/js/helpers";
import "./Installments.scss";

const termCalculator = (mortgage) => {
  if (!mortgage?.termsDurationCode) return "-";
  const term = parseInt(mortgage?.termsDurationCode?.code) || 0;
  return term + " " + mortgage?.termsDurationCode?.description;
};

function Installments(props) {
  const { installment } = props;
  return installment.map((m, i) => {
    const e = m?.enhancedPaymentData;
    const info = m?.narrativeCodes?.map((n) => n.description);
    const narrativeClosed = JSON.stringify(info);
    let openOrClosed =
      m.closedDate || narrativeClosed?.includes("CLOSED") ? "C" : "O";
    if (openOrClosed === "O" && m.balance <= 0) {
      openOrClosed = "P";
    }
    return (
      <div key={`crmortages-${i}`} className="wzrd-box installment">
        <div className="wzrd-body">
          <div className="trade-header">
            <h3>{m?.customerName}</h3>
            <p>
              {m?.customerNumber} {info ? `/ ${info.join("; ")}` : ""}
            </p>
            <div>
              <b>Account #:</b> {m?.accountNumber || e?.mortgageId || "-"}
            </div>
          </div>
          <div className={`trade-condition trade-${openOrClosed}`}>
            {condition[openOrClosed]}
          </div>
          <div className="trade-box">
            <div className="trade-items">
              <div className="trade-item">
                <h5>Payment Status</h5>
                <p>{m?.rate?.description || "-"}</p>
              </div>
              <div className="trade-item">
                <h5>Responsibility</h5>
                <p>{m.accountDesignator?.description || "-"}</p>
              </div>
              <div className="trade-item">
                <h5>Account Term</h5>
                <p>{termCalculator(m) || "-"}</p>
              </div>
            </div>
            <div className="trade-items">
              <div className="trade-item">
                <h5>Account Type</h5>
                <p>{m.accountTypeCode?.description || "-"}</p>
              </div>
            </div>
          </div>
          <div className="trade-list">
            <div>
              <h5>Open Date</h5>
              <p>{FormatDate(m?.dateOpened)}</p>
            </div>
            <div>
              <h5>Status Date</h5>
              <p>{FormatDate(m?.lastActivityDate)}</p>
            </div>
            <div>
              <h5>Last Paid Date</h5>
              <p>{FormatDate(m?.lastPaymentDate)}</p>
            </div>
            <div>
              <h5>Reported Date</h5>
              <p>{FormatDate(m?.dateReported)}</p>
            </div>
            <div>
              <h5>Current Balance</h5>
              <p>{flatMoney(parseInt(m?.balance))}</p>
            </div>
            <div>
              <h5>Scheduled Payment</h5>
              <p>{flatMoney(parseInt(m?.scheduledPaymentAmount))}</p>
            </div>
            <div>
              <h5>Last Payment</h5>
              <p>
                {m?.actualPaymentAmount
                  ? flatMoney(parseInt(m?.actualPaymentAmount))
                  : "-"}
              </p>
            </div>
            <div>
              <h5>Original Amount / Limit</h5>
              <p>{flatMoney(parseInt(m?.creditLimit || m?.highCredit))}</p>
            </div>
            <div>
              <h5>Past Due</h5>
              <p>{"---"}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });
}

export default Installments;
