import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { createTeam } from "App/Admin/_graphql/mutations/team";
import { getErrors } from "_helpers/api";
import { IconUsersGroup } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex } from "_styleguide";
import "./CreateTeam.scss";

function CreateTeam(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(props?.name || undefined);

  // Submit question form
  const saveTeam = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          name: name,
        },
        mutation: createTeam,
      });
      message.success(`Team successfully created`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error creating team, try again");
      setSaving(false);
    }
    setSaving(false);
  };

  const updateName = () => {
    props.changeName(name);
    setVisible(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && name?.length > 0) {
      if (props.editing) {
        updateName();
      } else {
        saveTeam();
      }
    }
  };

  return (
    <Modal
      wrapClassName="create-team-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconUsersGroup />
        </div>
        <h3>{props.editing ? "Edit" : "Create"} Team</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="name">Team name *</InputLabel>
          <FormInput
            id="name"
            value={name}
            disabled={saving}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g. New Team..."
            onKeyDown={handleKeyDown}
            size="medium"
          />
        </Flex>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        {props.editing ? (
          <Button
            block
            type="primary"
            disabled={name?.length <= 0}
            onClick={updateName}
          >
            Done
          </Button>
        ) : (
          <Button
            block
            type="primary"
            disabled={name?.length <= 0}
            onClick={saveTeam}
            loading={saving}
          >
            Save
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default CreateTeam;
