import { gql } from "@apollo/client";

export const createTeam = gql`
  mutation createTeam($name: String!) {
    createTeam(name: $name)
  }
`;

export const updateTeam = gql`
  mutation updateTeam($team_id: ID!, $user_ids: [ID!]!) {
    updateTeam(team_id: $team_id, user_ids: $user_ids)
  }
`;

export const removeFromTeam = gql`
  mutation removeFromTeam($team_id: ID!, $user_id: ID!) {
    removeFromTeam(team_id: $team_id, user_id: $user_id)
  }
`;
