import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { monthlySnapshot } from "App/Admin/_graphql/queries/payment";
import { getColumns } from "./_data/Columns";
import moment from "moment";
import StatBar from "./_components/StatBar/StatBar";
import { Select } from "antd";
import { Button } from "@centrate-io/barn";
import { timeout } from "_assets/js/helpers";
import { IconTableExport, IconReportMoney } from "@tabler/icons-react";
import { downloadSnapshot, addPaymentInformation } from "_helpers/exports";
import "./MonthlySnapshot.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "first_attempt",
  sortOrder: "ascend",
};

function MonthlySnapshot(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    data: undefined,
    last: undefined,
    stats: undefined,
    laststats: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(
    moment().startOf("month").format("YYYYMMDD"),
  );
  const [exporting, setExporting] = useState(false);
  const columns = getColumns();

  // Load Payments
  const fetchPayments = async (params = defaultParams) => {
    setLoading(true);
    const queryData = await client.query({
      variables: {
        id: props?.applicationId,
        month: month,
      },
      query: monthlySnapshot,
    });

    const paymentData = addPaymentInformation(
      queryData?.data?.monthlySnapshot?.current || [],
    );
    const lastData = addPaymentInformation(
      queryData?.data?.monthlySnapshot?.last || [],
    );
    const processingData = addPaymentInformation(
      queryData?.data?.monthlySnapshot?.processing || [],
    );
    props?.setCurrentCount(paymentData?.length || 0);
    setState({
      data: paymentData,
      last: lastData,
      processing: processingData,
      stats: getStats(paymentData),
      laststats: getStats(lastData),
      processingstats: getStats(processingData),
    });
    setLoading(false);
  };

  const getStats = (payments) => {
    const stats = {
      payments: payments.length,
      amount: 0,
      paypath: 0,
    };
    payments.forEach((p) => {
      const paypathFee = parseFloat(
        p["Est. PayPath Fee"]?.replace("$", "") || 0,
      );
      stats.amount += p.amount;
      stats.paypath += paypathFee;
    });
    return stats;
  };

  useEffect(() => {
    const currentMoment = moment(month).format("MMMM YYYY");
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: currentMoment + " Snapshot",
      icon: <IconReportMoney />,

      description: "Month over month payment analytics",
    });
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const isSuper = props?.user?.role === "super" ? true : false;
  const currentMoment = moment(month).format("MMMM YYYY");
  let start = moment("2024-06-01");
  let end = moment().startOf("month");
  let options = [];
  while (start.isSameOrBefore(end)) {
    options.push({
      label: start.format("MMMM YYYY"),
      value: start.format("YYYYMMDD"),
    });
    start.add(1, "month");
  }

  const exportTable = async () => {
    setExporting(true);
    await timeout(500);
    await downloadSnapshot(state.data, currentMoment);
    setExporting(false);
  };

  return (
    <div className="admin-application-snapshot">
      <div className="admin-search admin-search-row">
        <Select
          value={month}
          onChange={(val) => setMonth(val)}
          className="filter-select"
          popupClassName="pp-popup"
          placeholder="All"
        >
          {options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
        {isSuper ? (
          <Button
            loading={exporting}
            onClick={exportTable}
            type="secondary"
            className="boxed"
            size="small"
          >
            <IconTableExport />
            Export {currentMoment}
          </Button>
        ) : null}
      </div>
      <div className="admin-table application-table">
        <StatBar
          stats={state.stats}
          laststats={state.laststats}
          processingstats={state.processingstats}
          loadingStats={state.loading}
        />
        <Table
          columns={columns}
          loading={loading}
          pagination={state.pagination}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 317px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading payments..."
              : "No payments found",
          }}
        />
      </div>
    </div>
  );
}

export default MonthlySnapshot;
