import { gql } from "@apollo/client";

export const saveTradelines = gql`
  mutation saveTradelines($tradelines: [String]!, $application_id: String!) {
    saveTradelines(tradelines: $tradelines, application_id: $application_id)
  }
`;

export const createTradeline = gql`
  mutation createTradeline(
    $application_id: String!
    $account_number: String!
    $creditor: String!
    $account_type: String!
    $balance: Float!
  ) {
    createTradeline(
      application_id: $application_id
      account_number: $account_number
      creditor: $creditor
      account_type: $account_type
      balance: $balance
    )
  }
`;
