import { gql } from "@apollo/client";

export const adminTradelines = gql`
  query adminTradelines(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
  ) {
    adminTradelines(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      data {
        id
        short_id
        application_id
        credit_report_id
        selected
        is_custom
        tradeline {
          accountNumber
          accountType
          accountTypeText
          balanceAmount
          accountStatus
          openOrClosed
          customerName
          customerNumber
        }
      }
      total
      pageTotal
    }
  }
`;
