import { gql } from "@apollo/client";

export const applyWorkflow = gql`
  mutation applyWorkflow($workflow_id: String!, $application_id: String!) {
    applyWorkflow(workflow_id: $workflow_id, application_id: $application_id)
  }
`;

export const createWorkflow = gql`
  mutation createWorkflow($name: String!) {
    createWorkflow(name: $name)
  }
`;

export const saveWorkflow = gql`
  mutation saveWorkflow($workflow: WorkflowInput!) {
    saveWorkflow(workflow: $workflow)
  }
`;

export const deleteWorkflow = gql`
  mutation deleteWorkflow($workflow_id: String!) {
    deleteWorkflow(workflow_id: $workflow_id)
  }
`;
