import React, { useState } from "react";
import { Drawer } from "antd";
import { Button, message } from "@centrate-io/barn";
import {
  FormInput,
  InputLabel,
  Flex,
  Select,
  TextArea,
  TaskBadge,
  TeamAvatar,
  InputNumber,
  UserAvatar,
} from "_styleguide";
import { validateTask } from "App/Admin/_helpers/validateForm";
import "./AddTask.scss";

const defaultForm = {
  title: undefined,
  type: undefined,
  instructions: undefined,
  start: undefined,
  delay: 0,
  assigned_user_id: undefined,
  assigned_team_id: undefined,
  template_id: null,
  assigned_user: null,
  template: null,
};

function AddTask(props) {
  const [form, setForm] = useState(props.task?.type ? props.task : defaultForm);
  const jsonForm = props.task?.type
    ? JSON.stringify(props.task)
    : JSON.stringify(defaultForm);
  const isEditing = props.task?.type ? true : false;
  const hasChanged = JSON.stringify(form) !== jsonForm;

  const submitTask = () => {
    props.addTaskToWorkflow(form, isEditing);
    props.onClose();
    if (isEditing) {
      message.success("Task has been updated");
    } else {
      message.success("Task added to workflow");
    }
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const templates = props.templates || [];
  const validation = validateTask(form);
  let templateOptions = {};

  templates.forEach((t) => {
    if (!templateOptions[t.type]) {
      templateOptions[t.type] = [t];
    } else {
      templateOptions[t.type].push(t);
    }
  });

  const changeTemplate = (templateId) => {
    const templateData = templates.find((t) => t.id === templateId);
    if (!templateData) {
      updateForm("template_id", null);
      const editedform = { ...form };
      editedform.template_id = null;
      editedform.title = undefined;
      editedform.type = undefined;
      editedform.instructions = undefined;
      editedform.template = null;
      setForm(editedform);
    } else {
      const editedform = { ...form };
      editedform.template_id = templateId;
      editedform.title = templateData.title;
      editedform.type = templateData.type;
      editedform.instructions = templateData.instructions;
      editedform.template = {
        title: templateData.title,
        type: templateData.type,
        instructions: templateData.instructions,
      };
      setForm(editedform);
    }
  };
  const changeAssigned = (assignedId) => {
    const userData = props.users.find((t) => t.id === assignedId);
    const teamData = props.teams.find((t) => t.id === assignedId);
    if (!userData && !teamData) {
      const editedform = { ...form };
      editedform.assigned_user_id = null;
      editedform.assigned_team_id = null;
      editedform.assigned_team = null;
      editedform.assigned_user = null;
      setForm(editedform);
    } else if (teamData) {
      const editedform = { ...form };
      editedform.assigned_user_id = null;
      editedform.assigned_team_id = assignedId;
      editedform.assigned_team = {
        name: teamData.name,
      };
      editedform.assigned_user = null;
      setForm(editedform);
    } else {
      const editedform = { ...form };
      editedform.assigned_user_id = assignedId;
      editedform.assigned_team_id = null;
      editedform.assigned_team = null;
      editedform.assigned_user = {
        name: userData.name,
      };
      setForm(editedform);
    }
  };

  const changeStart = (value) => {
    if (value === "now") {
      const editedform = { ...form };
      editedform.start = value;
      editedform.delay = 0;
      setForm(editedform);
    } else {
      updateForm("start", value);
    }
  };

  const changeDelay = (value) => {
    const actualValue = parseInt(value);
    if (isNaN(value) || actualValue < 0) {
      updateForm("delay", 0);
    } else {
      updateForm("delay", isNaN(actualValue) ? 0 : actualValue);
    }
  };

  return (
    <Drawer
      width={456}
      onClose={props.onClose}
      open={props.open}
      maskClosable={false}
      className="add-task-drawer"
      rootClassName="add-task-parent"
      destroyOnClose={true}
    >
      <div className="new-task-title">{isEditing ? "Edit" : "Add"} Task</div>
      <div className="new-task-form">
        <Flex vertical={true}>
          <InputLabel htmlFor="template_id">Start From Template</InputLabel>
          <Select
            value={form.template_id}
            onChange={changeTemplate}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
          >
            <Select.Option value={null}>---</Select.Option>
            {Object.keys(templateOptions).map((o) => {
              const toList = templateOptions[o];
              return (
                <Select.OptGroup key={`to-${o}`} label={o.toUpperCase()}>
                  {toList.map((t) => (
                    <Select.Option key={t.id} value={t.id}>
                      <TaskBadge type={t.type} />
                      {t.title}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              );
            })}
          </Select>
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="title" {...validation.title}>
            Title *
          </InputLabel>
          <FormInput
            id="title"
            value={form.title}
            size="medium"
            onChange={(e) => updateForm("title", e.target.value)}
            placeholder="e.g. 30 Day Follow-up...."
            {...validation.title}
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="type" {...validation.type}>
            Task Type *
          </InputLabel>
          <Select
            value={form.type}
            onChange={(val) => updateForm("type", val)}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
            {...validation.type}
          >
            <Select.Option value="general">
              <TaskBadge type="general" />
              General
            </Select.Option>
            <Select.Option value="call">
              <TaskBadge type="call" />
              Call
            </Select.Option>
            <Select.Option value="email">
              <TaskBadge type="email" />
              Email
            </Select.Option>
            <Select.Option value="text">
              <TaskBadge type="text" />
              Text Message
            </Select.Option>
          </Select>
        </Flex>

        <Flex vertical={true}>
          <InputLabel htmlFor="instructions" {...validation.instructions}>
            Instructions *
          </InputLabel>
          <TextArea
            value={form.instructions}
            onChange={(e) => updateForm("instructions", e.target.value)}
            size="large"
            autoSize={true}
            placeholder="e.g. Let customer know their program have begun..."
          />
          {form.template_id !== null ? (
            <p className="at-info">
              * Changing instructions for this task will not affect the
              templates original instructions and will only change them for this
              task.
            </p>
          ) : null}
        </Flex>
        <Flex vertical={false} gap={16}>
          <Flex vertical={true} style={{ flex: 1 }}>
            <InputLabel htmlFor="start" {...validation.start}>
              Start Task *
            </InputLabel>
            <Select
              value={form.start}
              onChange={changeStart}
              size="extra-medium"
              popupClassName="pp-select-dropdown ppsd-task"
              placeholder="---"
              {...validation.start}
            >
              <Select.Option value="previous">
                After Previous Task
              </Select.Option>
              <Select.Option value="start">After Start Date</Select.Option>
              <Select.Option value="now">Due Immediately</Select.Option>
            </Select>
            {form.start === "previous" ? (
              <p className="at-info">
                * Due {form.delay} days after previous task is completed
              </p>
            ) : null}
            {form.start === "start" ? (
              <p className="at-info">
                * Due {form.delay} days after workflow is added
              </p>
            ) : null}
            {form.start === "now" ? (
              <p className="at-info">
                * Due immediately once the workflow is added
              </p>
            ) : null}
          </Flex>
          <Flex vertical={true} style={{ width: "120px" }}>
            <InputLabel htmlFor="delay" {...validation.delay}>
              Delay *
            </InputLabel>
            <InputNumber
              disabled={form.start === "now"}
              controls={false}
              precision={0}
              value={form.delay}
              formatter={(value) => `${value} days`}
              parser={(value) => value?.replace(" days", "")}
              onChange={changeDelay}
            />
          </Flex>
        </Flex>
        <Flex vertical={true}>
          <InputLabel
            htmlFor="assigned_user_id"
            {...validation.assigned_user_id}
          >
            Assign to *
          </InputLabel>
          <Select
            value={form.assigned_user_id || form.assigned_team_id}
            onChange={changeAssigned}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
            {...validation.assigned_user_id}
          >
            {props.teams?.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                <TeamAvatar name={t.name} />
                {t.name}&nbsp;{`(${t.user_count})`}
              </Select.Option>
            ))}
            {props.users?.map((u) => (
              <Select.Option key={u.id} value={u.id}>
                <UserAvatar name={u.name} />
                {u.name}
              </Select.Option>
            ))}
          </Select>
        </Flex>
      </div>
      <div className="new-task-actions">
        <Button block type="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          className="green-btn"
          disabled={!validation.allValid || !hasChanged}
          onClick={submitTask}
        >
          {isEditing ? "Save" : "Add"} Task
        </Button>
      </div>
    </Drawer>
  );
}

export default AddTask;
