import { getActionsData } from "App/Admin/_graphql/queries/application";

export const fetchActionsData = async (apolloClient, applicationId) => {
  let currentForm = undefined;
  try {
    const result = await apolloClient.query({
      query: getActionsData,
      variables: {
        application_id: applicationId,
      },
    });
    currentForm = result?.data?.getActionsData;
  } catch (err) {
    console.log(err);
    currentForm = undefined;
  }
  return currentForm;
};
