import React from "react";
import {
  IconConfetti,
  IconPlus,
  IconCircleX,
  IconPlayerPlay,
  IconPlayerPause,
  IconPencil,
} from "@tabler/icons-react";
import moment from "moment";

export default function Status(props) {
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const status = activity.status;
  let icon = <IconConfetti />;
  if (status?.type === "created") icon = <IconPlus />;
  if (status?.type === "cancelled") icon = <IconCircleX />;
  if (status?.type === "paused") icon = <IconPlayerPause />;
  if (status.type === "subscription" && status.information === "unpaused")
    icon = <IconPlayerPlay />;
  if (status?.type === "re-enroll") icon = <IconPencil />;
  return (
    <div className={"app-activity status-" + status.type}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {status.type === "created" && (
          <React.Fragment>
            {user?.name ? (
              <React.Fragment>
                <b>{user?.name}</b> created an application
              </React.Fragment>
            ) : (
              "Application created by the customer"
            )}
          </React.Fragment>
        )}
        {status.type === "paused" && (
          <React.Fragment>
            {user?.name ? (
              <React.Fragment>
                <b>{user?.name}</b> paused this customer plan:{" "}
                <b>{status.information}</b>
              </React.Fragment>
            ) : (
              "Plan paused by the customer"
            )}
          </React.Fragment>
        )}
        {status.type === "re-enroll" && (
          <React.Fragment>
            {user?.name ? (
              <React.Fragment>
                <b>{user?.name}</b> is modifying this plan:{" "}
                <b>{status.information}</b>
              </React.Fragment>
            ) : (
              "Plan modified by the customer"
            )}
          </React.Fragment>
        )}
        {status.type === "cancelled" && (
          <React.Fragment>
            {user?.name ? (
              <React.Fragment>
                <b>{user?.name}</b> cancelled this customers plan:{" "}
                <b>{status.information}</b>
              </React.Fragment>
            ) : (
              "Plan paused by the customer"
            )}
          </React.Fragment>
        )}
        {status.type === "subscription" &&
          status.information === "unpaused" && (
            <React.Fragment>
              {user?.name ? (
                <React.Fragment>
                  <b>{user?.name}</b> un-paused this customers plan
                </React.Fragment>
              ) : (
                "Plan un-paused by the customer"
              )}
            </React.Fragment>
          )}
        {status.type === "subscribed" && status.information !== "unpaused" && (
          <React.Fragment>
            <b>Customer</b> completed their application and is now{" "}
            <b>subscribed</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
      </div>
    </div>
  );
}
