import React, { useEffect } from "react";
import { DatePicker } from "_styleguide";
import { Text } from "@centrate-io/barn";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { calculateStartDate } from "_helpers/plan";
import { timeout } from "_assets/js/helpers";
import "./PaymentDate.scss";

dayjs.extend(advancedFormat);

function PaymentDate(props) {
  const { form, u } = props;
  const dateFormat = "MMMM D, YYYY";
  let dateValue = undefined;
  const dateChange = async (date, dateString) => {
    props.setToggle(true);
    const newPlan = { ...form.plan, start_date: date.toDate() };
    u("plan", newPlan);
    await timeout(100);
    props.setToggle(false);
  };
  const disabledDate = (current) => {
    if (current.isBefore(dayjs().subtract(1, "day"))) return true;
    if (current.isAfter(dayjs().add(30, "day"))) return true;
    return false;
  };

  const plan = form?.plan;
  if (plan?.start_date) {
    if (plan?.start_date?.length <= 13) {
      dateValue = dayjs(parseInt(plan?.start_date));
    } else {
      dateValue = dayjs(plan?.start_date);
    }
  }

  useEffect(() => {
    const getStartDate = async () => {
      const startDate = calculateStartDate();
      await timeout(200);
      dateChange(startDate);
    };
    getStartDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="payment-date">
      <Text className="due-date">* Verify payment start date</Text>
      <div className="picker">
        <DatePicker
          size="large"
          value={dateValue}
          placeholder="Select due date"
          disabledDate={disabledDate}
          onChange={dateChange}
          format={dateFormat}
          allowClear={false}
          disabled={props.toggle}
        />
      </div>
    </div>
  );
}

export default PaymentDate;
