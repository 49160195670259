import { gql } from "@apollo/client";

export const adminCreditReports = gql`
  query adminCreditReports(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
  ) {
    adminCreditReports(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      data {
        id
        short_id
        slug
        issuer
        report
        createdAt
      }
      total
      pageTotal
    }
  }
`;

export const getCreditReport = gql`
  query getCreditReport($application_id: ID) {
    getCreditReport(application_id: $application_id) {
      id
      issuer
      report
      pdf_url
      createdAt
    }
  }
`;
