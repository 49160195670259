import {
  validateEmail,
  validateName,
  validateZipCode,
  validateState,
  validateAddress,
  validatePhoneNumber,
  validateString,
} from "_assets/js/helpers";

const hasStarted = (field) => {
  if (field !== undefined && field !== null) {
    return 1;
  }
  return 0;
};

export const validateInformation = (form) => {
  const isValid = {
    first_name: {
      started: hasStarted(form.first_name),
      valid: validateName(form.first_name) ? 1 : 0,
    },
    last_name: {
      started: hasStarted(form.last_name),
      valid: validateName(form.last_name) ? 1 : 0,
    },
    co_first_name: {
      started: hasStarted(form.co_first_name),
      valid:
        (form.has_co_applicant && validateName(form.co_first_name)) ||
        !form.has_co_applicant
          ? 1
          : 0,
    },
    co_last_name: {
      started: hasStarted(form.co_last_name),
      valid:
        (form.has_co_applicant && validateName(form.co_last_name)) ||
        !form.has_co_applicant
          ? 1
          : 0,
    },
    email: {
      started: hasStarted(form.email),
      valid: validateEmail(form.email || "") ? 1 : 0,
    },
    phone: {
      started: hasStarted(form.phone),
      valid: validatePhoneNumber(form.phone) ? 1 : 0,
    },
    address: {
      started: hasStarted(form.address),
      valid: validateAddress(form.address) ? 1 : 0,
    },
    city: {
      started: hasStarted(form.city),
      valid: validateName(form.city) ? 1 : 0,
    },
    state: {
      started: hasStarted(form.state),
      valid: validateState(form.state) ? 1 : 0,
    },
    zip: {
      started: hasStarted(form.zip),
      valid: validateZipCode(form.zip) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validatePlan = (plan) => {
  const isValid = {
    type: {
      started: hasStarted(plan.type),
      valid: validateString(plan.type) ? 1 : 0,
    },
    amount: {
      started: hasStarted(plan.amount),
      valid: plan.amount > 0 ? 1 : 0,
    },
    service_percent: {
      started: hasStarted(plan.service_percent),
      valid: plan.service_percent > 0 ? 1 : 0,
    },
    monthly_fees: {
      started: hasStarted(plan.monthly_fees),
      valid: plan.monthly_fees >= 0 ? 1 : 0,
    },
    debt_amount: {
      started: hasStarted(plan.debt_amount),
      valid: plan.debt_amount >= 0 ? 1 : 0,
    },
    months: {
      started: hasStarted(plan.months),
      valid: plan.months >= 12 ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};

export const validateSchedule = (plan) => {
  const isValid = {
    payment_schedule: {
      started: hasStarted(plan.payment_schedule),
      valid: validateString(plan.payment_schedule) ? 1 : 0,
    },
    start_date: {
      started: hasStarted(plan.start_date),
      valid: hasStarted(plan.start_date) ? 1 : 0,
    },
    next_date: {
      started: hasStarted(plan.next_date),
      valid: hasStarted(plan.next_date) ? 1 : 0,
    },
  };

  let allValid = true;
  if (
    plan?.payment_schedule === "semi-monthly" &&
    (!isValid.start_date.valid || !isValid.next_date.valid)
  ) {
    allValid = false;
  } else if (
    plan?.payment_schedule === "monthly" &&
    !isValid.start_date.valid
  ) {
    allValid = false;
  } else if (
    plan?.payment_schedule === "bi-weekly" &&
    !isValid.start_date.valid
  ) {
    allValid = false;
  } else if (!isValid.payment_schedule.valid) {
    allValid = false;
  }

  isValid.allValid = allValid;

  return isValid;
};

export const validatePayment = (plan) => {
  const isValid = {
    payment_schedule: {
      started: hasStarted(plan.payment_schedule),
      valid: hasStarted(plan.payment_schedule) ? 1 : 0,
    },
    next_date: {
      started: hasStarted(plan.next_date),
      valid: hasStarted(plan.next_date) ? 1 : 0,
    },
    start_date: {
      started: hasStarted(plan.start_date),
      valid: hasStarted(plan.start_date) ? 1 : 0,
    },
  };

  let allValid = true;
  Object.keys(isValid).forEach((key) => {
    if (!isValid[key].valid) allValid = false;
  });
  isValid.allValid = allValid;

  return isValid;
};
