import { gql } from "@apollo/client";

export const sendEmail = gql`
  mutation sendEmail(
    $application_id: String!
    $email: String!
    $new_bank: Boolean
  ) {
    sendEmail(
      application_id: $application_id
      email: $email
      new_bank: $new_bank
    )
  }
`;

export const sendText = gql`
  mutation sendText(
    $application_id: String!
    $phone: String!
    $new_bank: Boolean
  ) {
    sendText(
      application_id: $application_id
      phone: $phone
      new_bank: $new_bank
    )
  }
`;

export const toggleManual = gql`
  mutation toggleManual($application_id: String!, $allow_manual: Boolean!) {
    toggleManual(application_id: $application_id, allow_manual: $allow_manual)
  }
`;

export const saveWorkflowSelection = gql`
  mutation saveWorkflowSelection(
    $application_id: String!
    $workflow_id: String
  ) {
    saveWorkflowSelection(
      application_id: $application_id
      workflow_id: $workflow_id
    )
  }
`;
