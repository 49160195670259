import React from "react";
import { INQUIRY } from "_helpers/experian";
import { FormatDate } from "_assets/js/helpers";
import "./Inquiries.scss";

function Inquiries(props) {
  const { inquiries } = props;
  return (
    <div id={props.id} className="wzrd-box inquiries">
      <div className="wzrd-header">
        <h3>Inquiries</h3>
      </div>
      <div className="wzrd-body">
        <table>
          <thead>
            <tr>
              <th>Subscriber</th>
              <th>Date</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {inquiries?.map((inq, i) => (
              <tr key={`trade-inq-${i}`}>
                <td>{inq?.customerName}</td>
                <td>{FormatDate(inq?.inquiryDate)}</td>
                <td>{INQUIRY[inq?.industryCode] || "--"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Inquiries;
