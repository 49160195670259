import React from "react";
import OfferCard from "./_components/OfferCard/OfferCard";
import CustomPlan from "./_components/CustomPlan/CustomPlan";
import { AlertBox } from "_styleguide";
import { IconStarFilled } from "@tabler/icons-react";
import { PLAN_INCLUDES_SHORT } from "_helpers/plan";
import { flatMoney } from "_assets/js/helpers";
import "./Plan.scss";

const PLAN_DEFAULTS = {
  service_percent: 40,
  monthly_fees: 0,
  months: 24,
};

function Plan(props) {
  const { form, u, ua } = props;
  const planData = form?.plan;
  let selected = null;
  if (planData.type && planData?.type === "custom") selected = "custom";
  if (planData.type && planData?.type !== "custom") selected = "program";

  const defaultCustom = () => {
    const totalDebt = form?.plan?.debt_amount || 0;
    const customCost = parseFloat(
      (totalDebt * (PLAN_DEFAULTS.service_percent / 100) +
        PLAN_DEFAULTS.monthly_fees * PLAN_DEFAULTS.months) /
        PLAN_DEFAULTS.months,
    );
    const newPlan = {
      ...planData,
      type: "custom",
      debt_amount: totalDebt,
      amount: customCost,
      monthly_fees: PLAN_DEFAULTS.monthly_fees,
      months: PLAN_DEFAULTS.months,
      service_percent: PLAN_DEFAULTS.service_percent,
    };
    props.ua(form, {
      plan: newPlan,
    });
  };

  const planProps = {
    form,
    u,
    ua,
    COSTS: PLAN_DEFAULTS,
    selected,
    defaultCustom,
  };

  return (
    <div className="admin-wizard-plan">
      <div className="wzrd-box">
        <div className="wzrd-header">
          <div
            className={`plan-radio ${selected === "program" ? "selected" : ""}`}
          />
          <h3>Program Offerings</h3>
          <p>{flatMoney(planData?.debt_amount)} selected debt</p>
        </div>
        <div className="wzrd-body">
          <div className="plan-cards">
            <OfferCard duration={24} {...planProps} />
            <OfferCard duration={18} {...planProps} />
            <OfferCard duration={12} {...planProps} />
          </div>
          <AlertBox className="plan-includes">
            <h5>All plans include:</h5>
            <div className="bullets">
              {PLAN_INCLUDES_SHORT.map((p, i) => (
                <div key={"pi-" + i} className="bullet">
                  <IconStarFilled />
                  {p}
                </div>
              ))}
            </div>
          </AlertBox>
        </div>
      </div>
      <CustomPlan {...planProps} />
    </div>
  );
}

export default Plan;
