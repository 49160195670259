import React, { useState } from "react";
import { AlertBox, Tabs } from "_styleguide";
import { IconAt, IconDeviceMobile } from "@tabler/icons-react";
import SendEmail from "App/Admin/Wizard/BankAgreement/_components/SendEmail/SendEmail";
import SendText from "App/Admin/Wizard/BankAgreement/_components/SendText/SendText";
import { updateManual } from "App/Admin/Wizard/_helpers/form";
import "./ConnectAccount.scss";

function ConnectAccount(props) {
  const [manual, setManual] = useState(props.app?.allow_manual || false);
  const form = props.app;

  const submitManual = async (value) => {
    try {
      setManual(value);
      const manualData = await updateManual(props.client, form, value);
      setManual(manualData);
    } catch (err) {
      console.log(err);
    }
  };

  const sendProps = {
    submitManual,
    manual,
    form,
    client: props.client,
    newAccount: true,
  };

  return (
    <div className="wzrd-box bank-new-box">
      <div className="wzrd-header">
        <h3>Connect New Bank Account</h3>
      </div>
      <div className="wzrd-body">
        <p className="bank-info">
          Customers can connect their Bank Accounts securely & on their own by
          using <b>Plaid</b>, for payment processing.
          <br />
          <br />
          Send an <b>email</b> or <b>text message</b>, then the customer can
          follow the instructions after receiving.
        </p>
        <AlertBox className="bb-contact">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: (
                  <div className="tab-name">
                    <IconAt />
                    Send email
                  </div>
                ),
                children: <SendEmail {...props} {...sendProps} />,
              },
              {
                key: "2",
                label: (
                  <div className="tab-name">
                    <IconDeviceMobile />
                    Send text message
                  </div>
                ),
                children: <SendText {...props} {...sendProps} />,
              },
            ]}
          />
        </AlertBox>
      </div>
    </div>
  );
}

export default ConnectAccount;
