import React, { useEffect } from "react";
import CanvasConfetti from "canvas-confetti";
import SuccessBanner from "_assets/images/success.svg";
import { Title } from "_styleguide";
import { Button } from "@centrate-io/barn";
import { IconFileTypePdf, IconStarFilled } from "@tabler/icons-react";
import { timeout } from "_assets/js/helpers";
import "./Success.scss";

function Success(props) {
  useEffect(() => {
    const confetti = async () => {
      await timeout(50);
      CanvasConfetti({
        particleCount: 200,
        spread: 500,
        origin: { y: 0.4 },
        zIndex: 2147483647,
        startVelocity: 30,
      });
    };
    confetti();
  }, []);

  const generateAgreement = async () => {
    const url = props?.audit?.agreement_url;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "VLG_Subscription_Agreement.pdf"; // the filename you want
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="step-start step-success">
      <div className="title-banner">
        <div className="success-banner">
          <img src={SuccessBanner} alt="" />
        </div>
        <Title>Congratulations & Welcome Aboard!</Title>
      </div>
      <div className="app-form">
        <p className="success-info">
          We're thrilled to have you join us! A choice that marks the beginning
          of your journey to a debt-free life. Get ready to experience the peace
          of mind and empowerment that comes with taking control of your
          finances
        </p>
        <div className="success-agreement">
          <Button
            type="default"
            size="large"
            className="success-pdf"
            onClick={generateAgreement}
          >
            <IconFileTypePdf />
            Download agreement
          </Button>
        </div>
        <div className="next-steps">
          <h4>Next Steps & What to Expect</h4>
          <div className="next-step">
            <IconStarFilled />
            <div>
              <h5>Welcome Call With An Expert Advisor</h5>
              <p>
                In the next 48 hours, you'll be contacted by your personal debt
                advisor for a comprehensive overview of your plan.
              </p>
            </div>
          </div>
          <div className="next-step">
            <IconStarFilled />
            <div>
              <h5>Program Onboarding</h5>
              <p>
                We'll guide you through the initial stages of your program,
                setting up any necessary arrangements and ensuring you're
                comfortable with the process.
              </p>
            </div>
          </div>
          <div className="next-step">
            <IconStarFilled />
            <div>
              <h5>Educational Resources</h5>
              <p>
                Access to our library of financial education resources. These
                tools are designed to empower you with knowledge and skills for
                managing your finances effectively.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
