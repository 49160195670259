import React from "react";
import {
  IconClock,
  IconUser,
  IconTrash,
  IconPencil,
  IconUsersGroup,
} from "@tabler/icons-react";
import { TaskBadge, Tag } from "_styleguide";
import { Button, Modal } from "@centrate-io/barn";
import "./TaskCard.scss";

function TaskCard(props) {
  const { task } = props;

  let { title, type, instructions } = task;
  if (task.template_id) {
    title = task.template?.title || task.title;
    type = task.template?.type || task.type;
  }

  let timing = null;
  if (task.start === "start") {
    if (task.delay <= 0) {
      timing = "Immediately after start";
    } else {
      timing = `${task.delay} day(s) after start`;
    }
  } else if (task.start === "previous") {
    if (task.delay <= 0) {
      timing = "Immediately after previous";
    } else {
      timing = `${task.delay} day(s) after previous`;
    }
  } else if (task.start === "now") {
    timing = "Immediately after start";
  }

  const editTask = () => {
    props.manageTask({
      title: task.title,
      type: task.type,
      instructions: task.instructions,
      start: task.start,
      delay: task.delay,
      order: task.order,
      assigned_user_id: task.assigned_user_id,
      assigned_team_id: task.assigned_team_id,
      template_id: task.template_id,
      assigned_user: task.assigned_user,
      assigned_team: task.assigned_team,
      template: task.template,
    });
  };

  const removeTask = async () => {
    Modal.confirm({
      title: "Are you sure you want remove this task?",
      okText: "Remove Task",
      content:
        "There is no 'undo' for this action, you will have to create a new task to replace it.",
      onOk: () => props.removeTask(task),
    });
  };

  return (
    <div className={`tc-container${props.isDragging ? " dragging" : ""}`}>
      <div className={`task-card`}>
        <div className="tc-header">
          <TaskBadge size="large" type={type} />
          <h4>{title}</h4>
        </div>
        <p>{instructions}</p>
        <div className="tc-info">
          <Tag size="small">
            <IconClock />
            {timing}
          </Tag>
          {task.assigned_user ? (
            <Tag size="small">
              <IconUser />
              {task.assigned_user?.name}
            </Tag>
          ) : null}
          {task.assigned_team ? (
            <Tag size="small">
              <IconUsersGroup />
              {task.assigned_team?.name}
            </Tag>
          ) : null}
        </div>
        <div className="tc-actions">
          <Button
            onClick={removeTask}
            type="nope"
            size="tiny"
            className="boxed"
          >
            <IconTrash />
          </Button>
          <Button
            onClick={editTask}
            type="secondary"
            size="tiny"
            className="boxed"
          >
            <IconPencil />
          </Button>
        </div>
      </div>
      <div className="tc-spacer">
        <div />
      </div>
    </div>
  );
}

export default TaskCard;
