import { gql } from "@apollo/client";

export const getUserInvite = gql`
  query getUserInvite($user_id: ID!) {
    getUserInvite(user_id: $user_id) {
      company {
        id
        name
      }
      name
      email
    }
  }
`;

export const updateLogin = gql`
  query {
    updateLogin
  }
`;
