import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { removeBankAccount } from "App/Admin/_graphql/mutations/bank_account";
import { getErrors } from "_helpers/api";
import { FormInput, InputLabel } from "_styleguide";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import "./RemoveAccount.scss";

function RemoveAccount(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [confirm, setConfirm] = useState();

  // Remove Bank Account
  const removeBank = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          bank_account_id: props.account?.id,
        },
        mutation: removeBankAccount,
      });
      message.success(`Bank account successfully removed`);
      if (props.reload) props.reload();
      if (props.reloadApplication) props.reloadApplication();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error removing bank account, try again");
      setSaving(false);
    }
  };

  const isActive = props.activeAccount ? true : false;

  const shouldDisable = isActive && confirm?.toLowerCase() !== "remove";

  return (
    <Modal
      wrapClassName="remove-account-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div className={`pp-mh-icon${!isActive ? " warning" : ""}`}>
          <IconAlertTriangleFilled />
        </div>
        {isActive
          ? "Remove Active Bank Account?"
          : `Remove "${props.account?.name}" Account?`}
      </div>

      {isActive ? (
        <Modal.Body>
          <div className="ba-content">
            <p>
              You’re attempting to remove the active bank account that is
              currently being used to make payments.{" "}
              <span className="warn">
                *This is a destructive action that can disrupt this customers
                service.
              </span>
            </p>

            <div className="ba-desc">
              <div>
                <b>By proceeding, you understand:</b>
              </div>
              <div>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;This active bank account will be
                permanently removed.
              </div>
              <div>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;If there is another bank account
                on file, it will be set as the new active account for payments.
              </div>
              <div>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;If there is no other bank account
                on file, this plan will be <b>pause</b> until a new payment
                method is provided.
              </div>
            </div>
          </div>
          <div className="type-confirm">
            <InputLabel htmlFor="confirm">
              Type “Remove” to confirm removal
            </InputLabel>
            <FormInput
              id="confirm"
              value={confirm}
              size="medium"
              onChange={(e) => setConfirm(e.target.value)}
            />
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className="ba-content">
            <p>
              This bank account is currently <b>inactive</b>. Removing it is a
              permanent action and <b>cannot be undone</b> cannot be undone.
              Customer will need to reconnect manually.
            </p>

            <div className="ba-desc">
              <b>Remove this inactive bank account?</b>
            </div>
          </div>
        </Modal.Body>
      )}

      <Modal.Actions>
        <Button type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          disabled={shouldDisable}
          type="nope"
          onClick={removeBank}
          loading={saving}
        >
          Remove Account
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default RemoveAccount;
