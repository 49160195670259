import dayjs from "dayjs";

export const PLAN_INCLUDES = [
  "Expert legal representation",
  "Stop to creditor harassment",
  "Educational resources",
  "Team to negotiate your debts",
  "Defense against lawsuits",
  "Debt free in as little as 12 months",
  "1 on 1 legal consultation",
  "Advice on credit repair",
  "Consistent updates on progress",
];

export const PLAN_INCLUDES_SHORT = [
  "Expert legal representation",
  "Debt negotiation",
  "Stop to creditor harassment",
  "Defense against lawsuits",
  "Advice on credit repair",
];

const noWeekends = function (date) {
  const isWeekend = [0, 6].includes(date.day()) ? true : false;
  if (isWeekend) {
    return date.subtract((date.day() + 2) % 7, "days");
  } else {
    return date;
  }
};

export const calculateStartDate = () => {
  const currentDate = dayjs();
  const dateObj = {
    middle: noWeekends(dayjs().date(15)),
    last: noWeekends(dayjs().endOf("month")),
    next: noWeekends(dayjs().add(1, "month").date(15)),
  };

  if (currentDate.isBefore(dateObj.middle)) {
    return dateObj.middle;
  } else if (currentDate.isBefore(dateObj.last)) {
    return dateObj.last;
  } else {
    return dateObj.next;
  }
};
