import React, { Component } from 'react';
import logo from '_assets/logos/icon.svg';
import './Loading.scss';

export default class Loading extends Component {
  render() {
    return (
      <div id="paypath-loading">
        <img src={logo} alt="" />
      </div>
    );
  }
}
