import { gql } from "@apollo/client";

export const adminApplications = gql`
  query adminApplications(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
  ) {
    adminApplications(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      data {
        id
        short_id
        first_name
        last_name
        email
        phone
        dob
        ssn
        address
        address_line_two
        city
        state
        zip
        audit {
          account_date
        }
        user {
          id
          name
          email
        }
        company {
          id
          name
        }
      }
      total
      pageTotal
    }
  }
`;

export const adminSubscriptions = gql`
  query adminSubscriptions(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
    $category: String
  ) {
    adminSubscriptions(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      category: $category
    ) {
      data {
        id
        short_id
        status
        first_name
        last_name
        email
        phone
        dob
        ssn
        address
        address_line_two
        city
        state
        zip
        reason
        audit {
          subscribed_date
        }
        user {
          id
          name
          email
        }
        company {
          id
          name
        }
      }
      total
      pageTotal
    }
  }
`;

export const adminApplication = gql`
  query adminApplication($id: ID) {
    adminApplication(id: $id) {
      id
      status
      first_name
      last_name
      email
      phone
      ssn_last_4
      address
      address_line_two
      city
      state
      zip
      plaid

      active_bank_account
      allow_manual
      was_cancelled

      plan {
        type
        amount
        service_percent
        monthly_fees
        debt_amount
        months
        start_date
      }
    }
  }
`;

export const adminForm = gql`
  query adminApplication($id: ID) {
    adminApplication(id: $id) {
      id
      first_name
      last_name
      email
      phone
      address
      address_line_two
      city
      state
      zip
    }
  }
`;

export const getActionsData = gql`
  query getActionsData($application_id: ID) {
    getActionsData(application_id: $application_id) {
      application {
        id
        first_name
        last_name
        email
        phone
        address
        address_line_two
        city
        state
        zip
      }
      payments {
        id
        status
      }
    }
  }
`;

export const getCustomerCounts = gql`
  query {
    getCustomerCounts {
      active
      paused
      cancelled
      modifying
    }
  }
`;
