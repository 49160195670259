import React from "react";
import { Text } from "@centrate-io/barn";
import { LoadingOutlined } from "@ant-design/icons";
import { Title } from "_styleguide";
import { IconInfoCircle, IconShieldLock } from "@tabler/icons-react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

export const InfoBox = (props) => {
  let dateValue = undefined;
  const plan = props?.plan;
  if (plan?.start_date && plan?.start_date?.length <= 13) {
    dateValue = dayjs(parseInt(plan?.start_date));
  } else if (plan?.start_date) {
    dateValue = dayjs(plan?.start_date);
  }

  return (
    <div className="info-box">
      <IconInfoCircle />
      {plan?.start_date ? (
        <p>
          Payment will be due on the{" "}
          <b>{dateValue.format("Do")} of every month</b> from your connected
          account. You will be reminded when payments are due.
        </p>
      ) : (
        <p>
          It’s recommended to select the date you get paid for guaranteed,
          uninterrupted debt relief.
        </p>
      )}
    </div>
  );
};

export const Trust = () => (
  <div className="final-trust">
    <IconShieldLock />
    <p>
      We’ve partnered with <u>Plaid</u>, <b>the most trusted</b> payment
      processor to ensure your information is <b>safe & secure.</b>
    </p>
  </div>
);

export const DueToday = () => (
  <div className="due-amount">
    <div>Due today</div>
    <div>$0.00</div>
  </div>
);

export const Terms = () => (
  <p className="final-terms">
    By clicking <b>“Start my subscription”</b> you confirm that you have read
    and agree to terms as listed in the agreement above and understand that this
    subscription will auto-renew, monthly, unless canceled, to the payment
    method provided.
  </p>
);

export const Creating = () => (
  <div className="step-start pp-experian">
    <Title>Setting up your account...</Title>
    <div className="app-loading">
      <div className="spinner">
        <LoadingOutlined style={{ fontSize: 80 }} spin />
      </div>
      <Text>This will take just a moment</Text>
    </div>
  </div>
);
