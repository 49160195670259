import { gql } from "@apollo/client";

export const adminApplicationDocuments = gql`
  query adminApplicationDocuments($application_id: ID!) {
    adminApplicationDocuments(application_id: $application_id) {
      id

      name
      type
      fileType
      size
      url

      user {
        id
        name
        email
      }
      company {
        id
        name
      }
      createdAt
    }
  }
`;
