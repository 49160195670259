import React from "react";
import { Menu as AntdMenu } from "antd";
import { Link } from "react-router-dom";
import { IconReportMoney } from "@tabler/icons-react";
import "./Menu.scss";

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});

function Menu(props) {
  const items = [
    getItem(
      <Link to="/analytics">Monthly Snapshot</Link>,
      "snapshot",
      <IconReportMoney />,
    ),
  ];

  const menuKeys = [props.tab || "snapshot"];

  return (
    <div className="analytics-menu">
      <div className="am-title">
        <h3>Analytics</h3>
      </div>
      <AntdMenu
        theme="light"
        mode="inline"
        selectedKeys={menuKeys}
        items={[...items]}
      />
    </div>
  );
}

export default Menu;
