import React from "react";
import {
  IconWalk,
  IconBuildingBank,
  IconClipboardText,
  IconWifi,
  IconConfetti,
  IconRouter,
  IconWifiOff,
  IconBook,
} from "@tabler/icons-react";
import "./CustomerStatus.scss";

function CustomerStatus(props) {
  const { customerStatus } = props;
  return (
    <div>
      {customerStatus === "connecting" && (
        <div className="customer-status connecting">
          <IconRouter />
          <p>Connecting to server to get live progress</p>
        </div>
      )}
      {customerStatus === "failed" && (
        <div className="customer-status failed">
          <IconWifiOff />
          <p>Could not connect to track live progress</p>
        </div>
      )}
      {customerStatus === "pending" && (
        <div className="customer-status pending">
          <IconWalk />
          <p>Customer has not accessed the registration page</p>
        </div>
      )}
      {customerStatus === "online" && (
        <div className="customer-status online">
          <IconWifi />
          <p>Customer is on the registration page now</p>
        </div>
      )}
      {customerStatus === "connecting-bank" && (
        <div className="customer-status bank">
          <IconClipboardText />
          <p>Customer is currently adding their bank details</p>
        </div>
      )}
      {customerStatus === "bank" && (
        <div className="customer-status bank">
          <IconBuildingBank />
          <p>Customer has added their bank account</p>
        </div>
      )}
      {customerStatus === "agreement" && (
        <div className="customer-status agreement">
          <IconBook />
          <p>Customer is currently reading the agreement</p>
        </div>
      )}
      {customerStatus === "subscribed" && (
        <div className="customer-status subscribed">
          <IconConfetti />
          <p>Customer completed agreement and has subscribed!</p>
        </div>
      )}
    </div>
  );
}

export default CustomerStatus;
