import React, { useState, useEffect } from "react";
import { InfoWindow } from "_styleguide";
import { Button } from "@centrate-io/barn";
import { Terms } from "../";
import { LongTerms } from "../Agreement/Agreement";
import { useApolloClient } from "@apollo/client";
import { getAgreementData } from "_graphql/queries/application";
import "./ConfirmPopup.scss";

function ConfirmPopup(props) {
  const { form } = props;
  const client = useApolloClient();
  const [agreement, setAgreement] = useState();

  let plaidData = null;
  try {
    plaidData = form?.plaid ? JSON.parse(form?.plaid) : null;
  } catch (err) {
    console.log(err);
  }

  const getAgreement = async () => {
    const stateResponse = await client.query({
      query: getAgreementData,
      variables: {
        application_id: form?.id,
      },
    });
    setAgreement(stateResponse?.data?.getAgreementData);
  };

  useEffect(() => {
    getAgreement();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InfoWindow className="confirm-iw" onClose={props.closeWindow}>
      <div className="pp-terms-scroll">
        <LongTerms
          form={props.form}
          plaidData={plaidData?.metadata}
          agreement={agreement}
        />
      </div>
      <Terms />
      <Button
        disabled={!agreement}
        size="large"
        onClick={props.startSubscription}
        block
        className="green-btn"
      >
        Start my subscription
      </Button>
    </InfoWindow>
  );
}

export default ConfirmPopup;
