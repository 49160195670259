export const accountTypeCredit = ["07", "15", "18", "37", "43"];
export const accountTypeLoan = ["01"];

export const mortgageList = ["26", "19", "25", "27", "08"];

export const accountTypeMap = {
  "00": "Auto Loan",
  "01": "Unsecured Loan",
  "02": "Secured Loan",
  "03": "Partially Secured Loan",
  "04": "Home Improvement Loan",
  "05": "FHA Home Improvement Loan",
  "06": "Installment Sales Contract",
  "07": "Revolving Charge Account",
  "08": "Real Estate, Specific Type Unknown — terms in years",
  "09": "Loan Secured by Cosigner",
  10: "Business Loan Personally Guaranteed",
  11: "Recreational Merchandise Loan",
  12: "Educational Loan",
  13: "Lease",
  14: "Cosigner (not borrower)",
  15: "Check Credit or Line of Credit",
  16: "FHA Cosigner (not borrower)",
  17: "Mobile Home",
  18: "Credit Card",
  19: "FHA Real Estate Mortgage — terms in years",
  20: "Note Loan",
  21: "Note Loan with Cosigner",
  22: "Secured by Household Goods",
  23: "Secured by Household Goods/Other Collateral",
  25: "VA Real Estate Mortgage — terms in years",
  26: "Conventional Real Estate Mortgage",
  27: "Real Estate Mortgage",
  29: "Rental Agreement",
  30: "Summary of Accounts with Same Status",
  31: "Unknown — Extension of Credit, Review or Collection",
  33: "Manual Mortgage",
  34: "Debt Counseling Service",
  37: "Combined Credit Plan — revolving terms",
  43: "Debit Card",
  47: "Credit Line Secured — revolving terms",
  48: "Collection Department/Agency/Attorney",
  49: "Insurance Underwriting",
  50: "Family Support",
  65: "Government Unsecured Guaranteed Loan",
  66: "Government Secured Guaranteed Loan",
  67: "Government Unsecured Direct Loan",
  68: "Government Secured Direct Loan",
  69: "Government Grant",
  70: "Government Overpayment",
  71: "Government Fine",
  72: "Government Fee for Service",
  73: "Government Employee Advance",
  74: "Government Miscellaneous Debt",
  75: "Government Benefit",
  77: "Returned Check",
  78: "Installment Loan",
  83: "Prescreen/Extract Post Prescreen Inquiry",
  85: "Bi-monthly Mortgage Payment — terms in years",
  86: "Automated Mortgage Reporting",
  87: "Semi-monthly Mortgage Payment — terms in years",
  89: "Home Equity Line of Credit — revolving terms",
  90: "Medical Debt",
  91: "Debt Consolidation",
  92: "Utility Company",
  93: "Child Support",
  94: "Spouse Support",
  95: "Attorney Fees",
  96: "Checking Account",
  98: "Credit Granting",
};

export const dwellingType = {
  A: "Multi-Family Dwelling Units",
  M: "Marginal Multi-Family Dwelling Units",
  P: "PO Box",
  S: "Single Family",
};

export const scoreFactors = {
  95: "No negative reason code",
  94: "No negative reason code",
  93: "Not enough balance paid down over time on first mortgage accounts",
  92: "Delinquent or derogatory first mortgage",
  91: "Balances on delinquent or derogatory revolving accounts are too high",
  90: "No open revolving accounts in your credit file",
  89: "No negative reason code",
  79: "No negative reason code",
  59: "Balances on retail cards are too high compared with credit limits",
  49: "Not enough balance paid down over time on revolving accounts",
  46: "Oldest revolving account was opened too recently",
  44: "Balances on revolving accts are too high compared with credit limits",
  43: "Too many revolving accounts with high balance compared to credit limit",
  19: "No negative reason code",
  97: "Too few accounts",
  96: "Too few open accounts",
  88: "Bankruptcy",
  87: "Unpaid collections",
  86: "Derogatory public records",
  85: "Too many inquiries",
  84: "Number of inquiries was a factor in determining the score",
  83: "No negative reason code",
  82: "Lack of recently reported student loans",
  81: "Not enough balance paid down over time on student loans",
  80: "Delinquent or derogatory student loan",
  78: "Lack of auto account information",
  77: "No open auto accounts in your credit file",
  76: "Delinquent or derogatory status on auto accounts is too recent",
  75: "Payments on auto accounts less than scheduled amount",
  74: "Balances on auto accounts are too high compared with loan amounts",
  73: "Delinquent or derogatory auto account",
  72: "Too few auto accounts paid as agreed",
  71: "Not enough balance paid down over time on auto accounts",
  70: "Too high proportion of auto accounts are delinquent or derogatory",
  69: "Too high proportion of balances from loans not secured by real estate",
  68: "Lack of real estate secured loan information",
  67: "No open real estate secured loans in your credit file",
  66: "Delinquent or derogatory status on real estate loans is too recent",
  65: "Oldest real estate secured loan was opened too recently",
  64: "Not enough balance paid down over time on real estate secured loans",
  63: "Delinquent or derogatory real estate secured loan",
  62: "Lack of first mortgage account information",
  61: "No open first mortgage accounts in your credit file",
  60: "Total delinquent or derogatory balances on real estate loans too high",
  58: "Lack of installment account information",
  57: "No open installment accounts in your credit file",
  56: "Lack of recently reported installment accounts",
  55: "Lack of recently reported auto accounts",
  54: "Delinquent or derogatory status on installment accounts is too recent",
  53: "Not enough balance paid down over time on installment accounts",
  52: "Delinquent or derogatory installment account",
  51: "Too few installment accounts recently paid as agreed",
  50: "Balances on personal installment accts too high compared to loan amts",
  48: "Lack of retail account information",
  47: "No open retail accounts in your credit file",
  45: "Not enough balance paid down over time on retail accounts",
  42: "Total credit limits on open revolving accounts are too low",
  41: "Average time since revolving accounts opened is too recent",
  40: "Too many delinquent or derogatory revolving accounts",
  39: "Balances on delinquent or derogatory bankcards are too high",
  38: "Lack of bankcard account information",
  37: "No open bankcards in your credit file",
  36: "Lack of recently reported revolving accounts",
  35: "Lack of revolving account information",
  34: "Most recently opened revolving account is too new",
  33: "Not enough balance paid down over time on bankcards",
  32: "Oldest bankcard was opened too recently",
  31: "Not enough available credit on revolving accounts",
  30: "Too few open revolving accounts",
  29: "Balances on bankcards are too high compared with credit limits",
  28: "Average credit limit on open bankcards is too low",
  27: "Delinquent or derogatory status on revolving accounts is too recent",
  26: "Balances on bankcards are too high",
  25: "Too high proportion of balances from bankcards",
  24: "Too many bankcards with high balance compared to credit limit",
  23: "Too high proportion of bankcards recently opened",
  22: "Too few bankcards with high credit limit",
  21: "Too many bankcards with a high balance",
  20: "Delinquent or derogatory bankcard",
  18: "Lack of account information",
  17: "No open accounts in your credit file",
  16: "Total of credit limits and loan amounts is too low",
  15: "Lack of recently reported accounts",
  14: "Too high proportion of accounts recently opened",
  13: "Balances on delinquent or derogatory accounts are too high",
  12: "Delinquent or derogatory status on accounts is too recent",
  11: "Oldest account was opened too recently",
  10: "Too few accounts paid as agreed",
  9: "Delinquent or derogatory account",
  8: "Too few accounts recently paid as agreed",
  7: "Too many delinquent or derogatory accounts",
  6: "Too many accounts recently opened",
  5: "Too many recent delinquencies",
  4: "Balances on accts too high compared to credit limits and loan amounts",
};

export const KOB = {
  AB: "Auto rental",
  AC: "Auto leasing",
  AF: "Farm implement dealers",
  AL: "Truck dealers",
  AN: "Automobile dealers, new",
  AP: "Automotive parts",
  AR: "Auto repair, body shops",
  AS: "Service stations",
  AT: "TBA stores, tire dealers",
  AU: "Automobile dealers, used",
  AZ: "Automotive — nonspecific",
  BB: "All banks — nonspecific",
  BC: "Bank credit cards",
  BI: "Bank — installment loans",
  BM: "Bank — mortgage department",
  BN: "Industrial bank",
  BO: "Co-op bank",
  BS: "Savings bank",
  CG: "General clothing store",
  CS: "Specialty clothing store",
  CZ: "Clothing store — nonspecific",
  DC: "Complete department stores",
  DV: "Variety stores",
  DZ: "Department and variety stores — nonspecific",
  EB: "Business education",
  EC: "Colleges",
  EL: "Student loans",
  ET: "Technical education",
  EU: "Universities",
  EV: "Vocational and trade schools",
  EZ: "Education — nonspecific",
  FA: "Auto financing companies",
  FB: "Mortgage brokers",
  FC: "Credit unions",
  FD: "Bail bonds",
  FF: "Sales financing companies",
  FI: "Investment firms",
  FL: "Savings and loans — mortgage",
  FM: "Mortgage companies",
  FP: "Personal loan companies",
  FR: "Mortgage reporters",
  FS: "Savings and loan companies",
  FT: "Investment securities",
  FU: "Bulk purchase finance",
  FW: "Bulk purchase finance — general",
  FZ: "Finance companies — nonspecific",
  GD: "Dairies",
  GN: "Neighborhood grocers",
  GS: "Supermarkets",
  GZ: "Groceries — nonspecific",
  HA: "Appliance sales and service",
  HC: "Carpets and floor coverings",
  HD: "Interior decorators/designers",
  HF: "Home furnishing stores",
  HM: "Music and record stores",
  HR: "Furniture rentals",
  HT: "TV and radio sales and service",
  HZ: "Home furnishings — nonspecific",
  IG: "General insurance",
  IL: "Life insurance",
  IZ: "Insurance — nonspecific",
  JA: "Jewelers",
  JP: "Computer sales and service",
  JV: "Videotape rental and sales",
  JZ: "Jewelry/cameras and computers — nonspecific",
  KG: "General contractors",
  KI: "Home improvement contractor",
  KS: "Subcontractors",
  KZ: "Contractors — nonspecific",
  LA: "Air conditioning/heat/plumbing/electrical sales",
  LF: "Fixture and cabinet suppliers",
  LP: "Paint, glass, wallpaper store",
  LZ: "Lumber/building material/hardware — nonspecific",
  MA: "Animal hospitals",
  MB: "Dentists",
  MC: "Chiropractors",
  MD: "Doctors",
  MF: "Funeral homes",
  MG: "Medical group",
  MH: "Hospitals and clinics",
  MM: "Cemeteries",
  MO: "Osteopaths",
  MP: "Pharmacies and drugstores",
  MS: "Optometrists and optical outlets",
  MV: "Veterinarians",
  MZ: "Medical and related health — nonspecific",
  NA: "Airlines",
  ND: "Credit card — department store",
  NF: "Credit card — finance company",
  NS: "Credit card — savings and loan",
  NU: "Credit card — credit union",
  NZ: "National credit card/airlines — nonspecific",
  OC: "Oil company credit cards",
  OZ: "Oil companies — nonspecific",
  PA: "Accountants and related services",
  PB: "Barber and beauty shops",
  PC: "Equipment leasing",
  PD: "Dry cleaning/laundry/related",
  PE: "Engineering/all kinds",
  PF: "Florists",
  PG: "Photographers",
  PH: "Health and fitness clubs",
  PI: "Detective service",
  PL: "Legal and related services",
  PM: "Check-cashing services",
  PN: "Restaurants/concessions",
  PP: "Pest control",
  PR: "Country clubs",
  PS: "Employment screening",
  PZ: "Personal service (nonmedical) — nonspecific",
  QZ: "Mail-order houses — nonspecific",
  RA: "Apartments",
  RC: "Office leasing",
  RD: "Mobile home dealers",
  RE: "Real-estate sales and rentals",
  RH: "Hotels",
  RM: "Motels",
  RP: "Mobile home park",
  RR: "Property and property management company",
  RZ: "Real estate/public accommodations — nonspecific",
  SA: "Aircraft sales and service",
  SB: "Boats and marinas sales and service",
  SM: "Motorcycles and bicycles sales and service",
  SZ: "Sporting goods — nonspecific",
  TC: "Farm chemicals and fertilizer stores",
  TF: "Feed and feed stores",
  TN: "Nursery and landscaping",
  TZ: "Farm and garden supplies/services — nonspecific",
  UA: "Water utilities/bottled water",
  UC: "Cable TV providers",
  UD: "Garbage and rubbish disposal",
  UE: "Electric light and power company",
  UF: "Fuel oil distributors",
  UG: "Gas company, natural and bottled",
  UH: "Coal and wood suppliers",
  UL: "Long-distance phone company",
  UO: "Online/Internet services",
  UP: "Cellular and paging services",
  UR: "Waste recycling/handlers",
  US: "Satellite TV/direct broadcast providers",
  UT: "Local telephone service provider",
  UV: "Home security company",
  UW: "Wireless telephone service provider",
  UZ: "Utilities and fuel — nonspecific",
  VC: "City and county",
  VF: "Federal government",
  VK: "Child support services",
  VL: "Law enforcement",
  VS: "State government",
  VX: "Court codes",
  VZ: "Government — nonspecific",
  WA: "Automotive supplies",
  WB: "Building supplies/hardware",
  WC: "Clothing and dry goods",
  WD: "Drugs, chemicals and related goods",
  WG: "Wholesale grocery and related products",
  WH: "Home furnishings",
  WM: "Machinery, equipment supplies",
  WP: "Credit card processors",
  WZ: "Wholesale — nonspecific",
  XD: "Direct-mail list services",
  XL: "List processing vendors",
  XM: "Media",
  XZ: "Advertising — nonspecific",
  YA: "Collection department — ACB Credit Bureau",
  YB: "Collection depatment — bank",
  YC: "Other collection agencies",
  YD: "Collection department — department store",
  YF: "Collection department — loan company",
  YL: "Collections attorney",
  YZ: "Collections — nonspecific",
  ZA: "Auto reseller",
  ZB: "Credit report brokers",
  ZC: "Credit bureaus",
  ZD: "Direct-to-consumer reseller",
  ZE: "Employment reseller",
  ZF: "Finance reseller",
  ZI: "Insurance reseller",
  ZL: "Leasing and rental reseller",
  ZM: "Manufacturing",
  ZP: "Personal service reseller",
  ZR: "Retail not elsewhere classified",
  ZS: "Services not elsewhere classified",
  ZT: "Tenant screeners reseller",
  ZW: "Wholesale not elsewhere classified",
  ZY: "Collection reseller",
  ZZ: "All others no",
};

export const termFrequency = {
  D: "Deferred",
  P: "Single payment loan",
  W: "Weeks",
  B: "Biweekly",
  E: "Semimonthly",
  L: "Bimonthly",
  Q: "Quarterly",
  T: "Triannually",
  S: "Semiannually",
  Y: "Years",
  M: "Months",
};

export const ECOA = {
  X: "Deceased",
  A: "Undesignated",
  H: "Individual",
  B: "Joint account",
  C: "Authorized user",
  D: "Joint account",
  E: "Cosigner",
  F: "On behalf of",
  G: "Signer",
  I: "Business/commercial",
  0: "Undesignated",
  1: "Individual",
  2: "Joint account",
  3: "Authorized user",
  4: "Joint account",
  5: "Cosigner",
  6: "On behalf of",
  7: "Signer",
  W: "Business/commercial",
};

export const paymentStatus = {
  "00": "New account",
  11: "Current",
  30: "Dispute, past due",
  60: "Dispute, current",
  71: "Bad debt, profit and loss write-off",
  78: "Repossession",
  80: "Transferred or sold",
  82: "In collections",
  83: "Paid collection",
  88: "Foreclosure",
  89: "Settlement accepted",
  91: "Voluntary surrender",
};

export const condition = {
  O: "Open",
  C: "Closed",
  P: "Paid/Zero Balance",
};

export const INQUIRY = {
  A: "AUTOMOTIVE",
  AF: "FARM IMPLEMENT DEALERS",
  AL: "TRUCK DEALERS",
  AN: "AUTOMOBILE DEALERS, NEW",
  AP: "AUTOMOTIVE PARTS",
  AR: "AUTOMOTIVE REPAIR, BODY SHOPS",
  AS: "SERVICE STATIONS",
  AT: "TBA STORES, TIRE DEALERS",
  AU: "AUTOMOBILE DEALERS, USED",
  AZ: "MISCELLANEOUS",
  B: "BANKS",
  BB: "ALL BANKS",
  BC: "BANCARD",
  C: "CLOTHING",
  CG: "GENERAL CLOTHING STORES",
  CS: "SPECIALTY - SHOES, HATS ETC",
  CZ: "MISCELLANEOUS",
  D: "DEPARTMENT & VARIETY",
  DC: "COMPLETE DEPARTMENT STORES",
  DM: "MAIL ORDER FIRMS",
  DV: "VARIETY STORES",
  DZ: "MISCELLANEOUS",
  F: "FINANCE",
  FA: "AUTO FINANCING",
  FB: "FINANCIAL BROKERAGE FIRMS",
  FC: "CREDIT UNIONS",
  FE: "EDUCATION FINANCE",
  FF: "SALES FINANCING",
  FM: "MORTGAGE COMPANIES",
  FO: "BUY NOW PAY LATER",
  FP: "PERSONAL LOAN COMPANIES",
  FR: "MORTGAGE BROKERS",
  FS: "SAVINGS & LOAN ASSOCIATIONS",
  FY: "FACTORING COMPANIES",
  FZ: "MISCELLANEOUS",
  G: "GROCERIES",
  GC: "DAIRIES",
  GN: "NEIGHBORHOOD GROCERIES",
  GS: "SUPERMARKETS",
  GZ: "MISCELLANEOUS",
  H: "HOME FURNISHINGS",
  HA: "APPLIANCE SALES & SERVICE",
  HC: "CARPETS & FLOOR COVERINGS",
  HF: "FURNITURE ETC.",
  HM: "MUSIC RECORDS",
  HT: "TELEVISION & RADIO",
  HZ: "MISCELLANEOUS",
  I: "INSURANCE",
  IG: "GENERAL INSURANCE",
  IL: "LIFE INSURANCE",
  IZ: "MISCELLANEOUS",
  J: "JEWELRY & CAMERAS",
  JA: "JEWELERS",
  JC: "CAMERAS",
  JZ: "MISCELLANEOUS",
  K: "CONTRACTORS",
  KG: "GENERAL",
  KI: "HOME IMPROVEMENTS",
  KS: "SUB-CONTRACTORS",
  KZ: "MISCELLANEOUS",
  L: "LUMBER, BLDG MATLS & HARDWARE",
  LA: "AIR COND, PLUMB, ELEC SALES & SERVICE",
  LF: "FIXTURE, CABINET COMPANIES",
  LH: "HARDWARE STORES",
  LP: "PAIN, GLASS & PAPER",
  LY: "LUMBER YARDS",
  LZ: "MISCELLANEOUS",
  M: "MEDICAL & HEALTH",
  MB: "DENTISTS",
  MC: "CHIROPRACTORS",
  MD: "DOCTORS & CLINICS",
  MF: "FUNERAL HOMES, CEMETERIES",
  MH: "HOSPITALS",
  MO: "OSTEOPATHIS",
  MP: "PHARMACIES & DRUGS",
  MS: "OPTOMERTRISTS, ETC",
  MV: "VETERINARIANS",
  MZ: "MISCELLANEOUS",
  O: "OIL & NATIONAL CREDIT CARDS",
  OC: "OIL COMPANIES",
  ON: "NATIONAL CREDIT CARD COMPANIES",
  OZ: "MISCELLANEOUS",
  P: "PERSONAL SERVICES",
  PA: "ACCOUNTANTS, ETC",
  PB: "BARBERS, BEAUTY SHOPS",
  PD: "DRY CLEANING, LAUNDRY",
  PE: "ENGINEERING, ALL KINDS",
  PG: "PHOTOGRAPHERS",
  PL: "LEGAL & RELATED SERVICES",
  PZ: "MISCELLANEOUS",
  R: "REAL ESTATE, HOTELS, ETC",
  RA: "APARTMENTS",
  RE: "REAL ESTATE, SALES, RENT",
  RH: "HOTELS",
  RM: "MOTELS",
  RZ: "MISCELLANEOUS",
  S: "SPORTING GOODS",
  SB: "BOAT & MARINA, SALES & SERVICE",
  SG: "SPORTING GOODS STORES",
  SM: "MOTORCYCLES & BICYCLES, SALES & SERVICE",
  SZ: "MISCELLANEOUS",
  T: "FARM & GARDEN SUPPLIES",
  TC: "CHEMICAL & FERTILIZER STORES",
  TF: "FEED & WEED STORES",
  TN: "NURSERY & LANDSCAPING",
  TZ: "MISCELLANEOUS",
  U: "UTILITIES",
  UC: "COAL & WOOD DEALERS",
  UD: "GARBAGE & RUBBAGE DISPOSALS",
  UE: "ELECTRIC LIGHT & POWER",
  UF: "FUEL OIL DEALERS",
  UG: "GAS-NATURAL BOTTLES",
  UH: "CABLE COMPANIES",
  UI: "WIRELESS COMPANIES",
  UT: "TELEPHONE COMPANIES",
  UW: "WATER COMPANIES",
  UZ: "MISCELLANEOUS",
  V: "GOVERNMENT",
  VC: "CITY & COUNTY",
  VF: "FEDERAL",
  VS: "STATE",
  VZ: "MISCELLANEOUS",
  W: "WHOLESALE",
  WA: "AUTOMOTIVE SUPPLIES",
  WB: "WHOLESALE BLDG/HARDWARE SUPPLY",
  WC: "CLOTHING & DRY GOODS",
  WD: "DRUGS & CHEMICALS, ETC",
  WE: "BLDG SUPPLIES & HARDWARE",
  WG: "GROCERIES & RELATED PRODUCTS",
  WH: "HOME FURNISHINGS",
  WM: "MACHINERY & EQUIPMENT",
  WP: "MERCHANT PROCESSING",
  WZ: "MISCELLANEOUS",
  X: "ADVERTISING",
  XA: "AGENCIES",
  XM: "NEW MEDIA",
  XZ: "MISCELLANEOUS",
  Y: "COLLECTION SERVICES",
  YA: "ACB OF A",
  YC: "OTHER",
  Z: "MISCELLANEOUS",
  ZB: "MISCELLANEOUS REPORTING AGENCIES",
  ZC: "CONSUMER PRODUCTS",
  ZD: "AUTHENTICATION PRODUCTS",
  ZF: "CREDIT BUILDER",
  ZR: "RETAIL NOT ELSEWHERE",
  ZS: "SERVICES NOT ELSEWHERE",
  ZW: "WHOLESALE NOT ELSEWHERE",
  ZZ: "ALL OTHERS-BUSINES MACHINES, CATERING, VENDING MACHINES, SCHOOLS, AIRCRAFT LEASING, RAILROADS, CLUBS, LODGES, SHOPPING CENTERS, TRAVEL AGENCIES",
};
