import React from "react";
import { useParams } from "react-router-dom";
import Menu from "./_components/Menu/Menu";
import MonthlySnapshot from "./MonthlySnapshot/MonthlySnapshot";
import "./Analytics.scss";

const DEFAULT_TAB = "snapshot";

function Analytics(props) {
  const { tab } = useParams();

  const globalProps = {
    tab: tab || DEFAULT_TAB,
    ...props,
  };

  return (
    <div className="admin-analytics">
      <Menu {...globalProps} />
      <div className="analytics-body">
        {globalProps.tab === "snapshot" && <MonthlySnapshot {...globalProps} />}
      </div>
    </div>
  );
}

export default Analytics;
