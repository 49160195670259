import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { createPayment } from "_graphql/mutations/payment";
import { getErrors } from "_helpers/api";
import { IconPlus } from "@tabler/icons-react";
import { InputNumber, InputLabel, Flex, DatePicker } from "_styleguide";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "./CreatePayment.scss";

dayjs.extend(dayjsPluginUTC);

function CreatePayment(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [nextAttempt, setNextAttempt] = useState(undefined);
  const [amount, setAmount] = useState(undefined);

  // Submit question form
  const saveCreditLine = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          application_id: props.applicationId,
          next_attempt: nextAttempt,
          amount: amount,
        },
        mutation: createPayment,
      });
      message.success(`Payment successfully created`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error creating payment, try again");
      setSaving(false);
    }
    setSaving(false);
  };

  const dateFormat = "MM/DD/YYYY";
  const dateChange = async (v) => {
    setNextAttempt(v);
  };

  const isValid = () => {
    if (nextAttempt && parseInt(amount) > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      wrapClassName="create-payment-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconPlus />
        </div>
        <h3>Create new payment</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="next_attempt">Payment Date *</InputLabel>
          <DatePicker
            value={nextAttempt}
            placeholder="Select date..."
            onChange={(val) => dateChange(val)}
            format={dateFormat}
            allowClear={false}
            size="medium"
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="amount">Payment Amount *</InputLabel>
          <InputNumber
            controls={false}
            value={amount}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setAmount(value)}
          />
        </Flex>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={!isValid()}
          onClick={saveCreditLine}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreatePayment;
