import React from "react";
import { IconMail, IconPhone } from "@tabler/icons-react";
import moment from "moment";

export default function Status(props) {
  const { activity } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const status = activity.outreach;
  let icon = <IconPhone />;
  if (status.type === "email") icon = <IconMail />;
  return (
    <div className={"app-activity status-" + status.type}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {status.type === "text" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> sent the registration link via{" "}
            <b>text message</b> to <b>{status.contact}</b>
          </React.Fragment>
        )}
        {status.type === "email" && (
          <React.Fragment>
            <b>{user?.name || "Customer"}</b> sent the registration link via{" "}
            <b>email</b> to <b>{status.contact}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
        <a className="pp-link" href={status.url}>
          Registration Link
        </a>
      </div>
    </div>
  );
}
