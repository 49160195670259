import React, { useState, useEffect } from "react";
import { Button, message } from "@centrate-io/barn";
import { usePlaidLink } from "react-plaid-link";
import axios from "axios";
import { IconLockOpen } from "@tabler/icons-react";
import { saveForm, exchangeToken } from "_styleguide/Actions/saveForm";
import { sendMessage } from "_graphql/queries/socket";
import { savePlaidAccount } from "App/Admin/_graphql/mutations/bank_account";
import "./Plaid.scss";

const PlaidLink = (props) => {
  const getInstitution = async (institution_id) => {
    const institutionData = await axios.get(
      `${process.env.REACT_APP_API_PATH}/plaid/institution?id=${institution_id}`,
    );
    return institutionData?.data?.institution || null;
  };
  const onSuccess = async (public_token, metadata) => {
    let institution = null;
    if (metadata?.institution?.institution_id) {
      institution = await getInstitution(metadata?.institution?.institution_id);
    }
    await props.updatePlaid({ public_token, metadata, institution });
  };
  const { open, ready } = usePlaidLink({
    token: props?.linkToken,
    onSuccess,
  });
  const openPlaid = async () => {
    if (props?.sendSocketMessage) {
      await props?.sendSocketMessage("connecting-bank");
    }
    open();
  };
  return (
    <Button
      className="plaid-connect green-btn"
      block
      type="primary"
      size="large"
      disabled={!ready}
      onClick={() => openPlaid()}
    >
      <IconLockOpen /> Connect Bank Account
    </Button>
  );
};

function Plaid(props) {
  const { form, u } = props;
  const [linkToken, setLinkToken] = useState();

  /* Load initial link_token */
  useEffect(() => {
    const getInitialLinkToken = async () => {
      const linkTokenData = await axios.post(
        `${process.env.REACT_APP_API_PATH}/plaid/create_link_token`,
        { userId: form?.id || JSON.parse(localStorage.getItem("pp_form")) },
      );
      if (!linkTokenData?.data?.link_token) {
        return message.error(
          "Could not connect to Plaid, refresh to try again",
        );
      }
      setLinkToken(linkTokenData?.data?.link_token);
    };
    getInitialLinkToken();
    // eslint-disable-next-line
  }, []);

  const updatePlaid = async (newPlaidData) => {
    // Attempt token exchange FIRST
    const exchangeResult = await exchangeToken(
      props.client,
      form,
      newPlaidData,
    );
    if (exchangeResult !== "success") {
      message.error("Could not connect to Plaid, refresh to try again");
    } else {
      try {
        const stringPlaidData = JSON.stringify(newPlaidData);
        u("plaid", stringPlaidData);
        await saveForm(
          props.client,
          { ...form, plaid: stringPlaidData },
          "checkout",
        );
        if (sendSocketMessage) {
          await sendSocketMessage("bank", stringPlaidData);
        }
        await props.client.mutate({
          variables: {
            application_id: form?.id,
          },
          mutation: savePlaidAccount,
        });
        if (props.setBankAdded) props.setBankAdded(true);
      } catch (err) {
        console.log(JSON.stringify(err));
      }
    }
  };

  const sendSocketMessage = async (status, data) => {
    if (!props.fromRegistration) return;
    try {
      await props.client.query({
        query: sendMessage,
        variables: {
          application_id: form?.id,
          status: status,
          data: data || null,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return linkToken && props.hasStartDate && !props.toggle ? (
    <PlaidLink
      linkToken={linkToken}
      updatePlaid={(newPlaidData) => updatePlaid(newPlaidData, form)}
      sendSocketMessage={sendSocketMessage}
    />
  ) : (
    <Button
      className="plaid-connect green-btn"
      block
      type="primary"
      size="large"
      disabled={true}
    >
      <IconLockOpen /> Connect Bank Account
    </Button>
  );
}

export default Plaid;
