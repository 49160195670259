import React, { Component } from "react";
import { Tooltip } from "@centrate-io/barn";
import "./NameLogo.scss";

class NameLogo extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-name-logo " + (props.className || "");

    if (props.size) {
      props.className += " pp-size-" + props.size;
    }

    const name = (props.name?.charAt(0) || "?")?.toUpperCase();

    if (props.tooltip) {
      return (
        <Tooltip title={props.tooltip}>
          <div className={props.className}>{name}</div>
        </Tooltip>
      );
    } else {
      return <div className={props.className}>{name}</div>;
    }
  }
}

export default NameLogo;
