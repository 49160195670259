import React, { Component } from "react";
import "./Title.scss";

class Title extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-title " + (props.className || "");
    return <h2 {...props}>{props.children}</h2>;
  }
}

export default Title;
