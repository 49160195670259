import { gql } from "@apollo/client";

export const adminPayments = gql`
  query adminPayments(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
  ) {
    adminPayments(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      data {
        id
        short_id
        application_id
        status
        order
        amount
        first_attempt
        last_attempt
        next_attempt
        total_attempts
        is_custom
      }
      total
      pageTotal
    }
  }
`;

export const adminAllPayments = gql`
  query adminPayments(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
    $date: String
    $type: String
    $status: String
  ) {
    adminPayments(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      date: $date
      type: $type
      status: $status
    ) {
      data {
        id
        short_id
        application_id
        status
        order
        amount
        first_attempt
        last_attempt
        next_attempt
        total_attempts
        is_custom
        application {
          id
          short_id
          first_name
          last_name
          total_payments
        }
        attempts {
          attempt
          description
        }
        updates {
          ach_status
          description
        }
      }
      total
      pageTotal
    }
  }
`;

export const monthlySnapshot = gql`
  query monthlySnapshot($id: ID, $month: String) {
    monthlySnapshot(id: $id, month: $month) {
      current {
        id
        short_id
        application_id
        status
        order
        amount
        first_attempt
        last_attempt
        next_attempt
        total_attempts
        application {
          id
          short_id
          first_name
          last_name
          total_payments
          plan {
            months
          }
        }
      }
      last {
        amount
        application {
          total_payments
          plan {
            months
          }
        }
      }
      processing {
        amount
        application {
          total_payments
          plan {
            months
          }
        }
      }
    }
  }
`;

export const adminPaymentsStats = gql`
  query adminPaymentsStats(
    $search: String
    $date: String
    $type: String
    $status: String
  ) {
    adminPaymentsStats(
      search: $search
      date: $date
      type: $type
      status: $status
    ) {
      open
      paid
      processing
      paused
      failed
      cancelled
    }
  }
`;

export const getPayment = gql`
  query getPayment($payment_id: ID) {
    getPayment(payment_id: $payment_id) {
      id
      short_id
      application_id
      status
      order
      amount
      first_attempt
      last_attempt
      next_attempt
      total_attempts
      is_custom
      attempts {
        status
        description
        attempt
        balance
        transaction_id
        timestamp
      }
      updates {
        ach_status
        description
        direction
        amount
        fee
        transaction_id
        timestamp
      }
      application {
        id
        short_id
        first_name
        last_name
      }
    }
  }
`;
