import { Button, Text } from "@centrate-io/barn";
import { IconLetterCase, IconTrash } from "@tabler/icons-react";
import { TableActions } from "_styleguide";
import moment from "moment";

export const getColumns = (goToWorkflow, removeWorkflow) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconLetterCase />
          Name
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (name, data) => (
        <div>
          <Text className="workflow-name">{data.name}</Text>
          <Text className="helper workflow-helper">
            {data.total_tasks} task{data.total_tasks?.length === 1 ? "" : "s"}
            &nbsp;&nbsp;&bull;&nbsp;&nbsp;Last updated on{" "}
            {moment(data.updatedAt * 1).format("M/D/YY")}
          </Text>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (manage, data) => {
        const items = [
          {
            label: (
              <span className="bad">
                <IconTrash /> Remove Workflow
              </span>
            ),
            onClick: () => removeWorkflow(data.id),
          },
        ];
        return (
          <div className="table-actions workflow-actions">
            <Button
              type="blanco"
              size="small"
              className="boxed"
              onClick={() => goToWorkflow(data.id)}
            >
              Edit Workflow
            </Button>
            <TableActions items={items} />
          </div>
        );
      },
    },
  ];
};
