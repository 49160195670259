import { Text } from "@centrate-io/barn";
import {
  IconUser,
  IconCalendar,
  IconFiles,
  IconReceipt2,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import moment from "moment";

export const getColumns = (myId) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconUser />
          User
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{name || "New User"}</b>
            {data?.role === "user-invite" && (
              <Tag type="success" size="small">
                Invite pending
              </Tag>
            )}
            {data?.role !== "user-invite" && data?.id === myId && (
              <Tag type="primary" size="small">
                You
              </Tag>
            )}
            {data?.role !== "user-invite" && (
              <Tag type="neutral" size="small">
                {data?.role}
              </Tag>
            )}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconFiles />
          Leads
        </div>
      ),
      dataIndex: "application_count",
      key: "application_count",
      width: 160,
      render: (application_count) => (
        <Text>
          {application_count} application{application_count === 1 ? "" : "s"}
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconReceipt2 />
          Customers
        </div>
      ),
      dataIndex: "subscription_count",
      key: "subscription_count",
      width: 160,
      render: (subscription_count) => (
        <Text>
          {subscription_count} subscription{subscription_count === 1 ? "" : "s"}
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Invited
        </div>
      ),
      dataIndex: "invited_date",
      key: "invited_date",
      sorter: true,
      width: 150,
      render: (invited_date) => (
        <Text>
          {invited_date ? (
            moment(parseInt(invited_date)).format("M/D/YYYY")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Joined
        </div>
      ),
      dataIndex: "joined_date",
      key: "joined_date",
      sorter: true,
      width: 150,
      render: (joined_date) => (
        <Text>
          {joined_date ? (
            moment(parseInt(joined_date)).format("M/D/YYYY")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
  ];
};
