import {
  IconFiles,
  IconReceipt2,
  IconChartAreaLine,
  IconUsers,
  IconLock,
  IconCash,
  IconBuilding,
  IconReportMoney,
} from "@tabler/icons-react";
export const getCrumbs = (route) => {
  if (route === "applications") {
    return {
      title: "Leads",
      icon: <IconFiles />,
      description: "Current open leads",
    };
  }
  if (route === "subscriptions") {
    return {
      title: "Customers",
      icon: <IconReceipt2 />,
      description: "Manage your subscribed customers",
    };
  }
  if (route === "payments") {
    return {
      title: "Payments",
      icon: <IconCash />,
      description: "Month over month snapshot of receivables",
    };
  }
  if (route === "snapshot") {
    return {
      title: "Monthly Snapshot",
      icon: <IconReportMoney />,

      description: "Monitor all payments",
    };
  }
  if (route === "companies") {
    return {
      title: "Companies",
      icon: <IconBuilding />,
      description: "Manage your client companies",
    };
  }
  if (route === "users") {
    return {
      title: "Users",
      icon: <IconUsers />,
      description: "Manage your company's users",
    };
  }
  if (route === "analytics") {
    return {
      title: "Analytics",
      icon: <IconChartAreaLine />,
      description: "Track statistics and make informed decisions",
    };
  }
  if (route === "users") {
    return {
      title: "Users",
      icon: <IconUsers />,
      description: "Manage your team",
    };
  }
  return {
    title: "Admin Dashboard",
    icon: <IconLock />,
    description: "Manage your account",
  };
};
