import React, { Component } from "react";
import "./Avatar.scss";

class Avatar extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-avatar " + (props.className || "");

    if (props.danger) {
      props.className += " pp-danger";
    }

    if (props.warning) {
      props.className += " pp-warning";
    }

    return <div className={props.className}>{props.icon || null}</div>;
  }
}

export default Avatar;
