export const appMap = {
  subscription: {
    title: "ACTIVE",
    type: "success",
  },
  paused: {
    title: "PAUSED",
    type: "warning",
  },
  cancelled: {
    title: "CANCELLED",
    type: "danger",
  },
  application: {
    title: "MODIFYING",
    type: "neutral",
  },
};
