import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Drawer } from "antd";
import { Button, message, Modal } from "@centrate-io/barn";
import { IconTrash } from "@tabler/icons-react";
import {
  AlertBox,
  FormInput,
  InputLabel,
  Flex,
  Select,
  TextArea,
  TaskBadge,
} from "_styleguide";
import { validateTemplate } from "App/Admin/_helpers/validateForm";
import {
  saveTaskTemplate,
  deleteTemplate,
} from "App/Admin/_graphql/mutations/task";

import { getErrors } from "_helpers/api";
import "./ManageTemplate.scss";

const defaultForm = {
  title: undefined,
  type: undefined,
  instructions: undefined,
};

function ManageTemplate(props) {
  const client = useApolloClient();
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState(
    props.template?.id ? props.template : defaultForm,
  );
  const jsonForm = props.template?.id
    ? JSON.stringify(props.template)
    : JSON.stringify(defaultForm);
  const isEditing = props.template?.id ? true : false;
  const hasChanged = JSON.stringify(form) !== jsonForm;

  // Create Task Template
  const createTemplate = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { ...form },
        mutation: saveTaskTemplate,
      });
      message.success(`Task template ${isEditing ? "saved" : "created"}`);
      if (props.reload) props.reload();
      props.onClose();
      setSaving(false);
      setForm(defaultForm);
    } catch (err) {
      message.error(getErrors(err) || "Error saving template, try again");
      setSaving(false);
    }
  };
  const removeTemplate = async (template_id) => {
    Modal.confirm({
      title: "Are you sure you want remove this template?",
      okText: "Remove Template",
      content:
        "This action cannot be reversed and will remove the template and it can no longer be used to create tasks from.  It will not affect any current tasks created from it.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: { template_id: props.template?.id },
            mutation: deleteTemplate,
          });
          message.success(`Template has been removed`);
          if (props.reload) props.reload();
          props.onClose();
          setForm(defaultForm);
        } catch (err) {
          message.error(getErrors(err) || "Error removing template, try again");
        }
      },
    });
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const validation = validateTemplate(form);

  return (
    <Drawer
      width={456}
      onClose={props.onClose}
      open={props.open}
      maskClosable={false}
      className="task-drawer"
      rootClassName="task-parent"
      destroyOnClose={true}
    >
      <div className="new-task-title">
        {isEditing ? "Editing" : "New"} Template
      </div>
      <div className="new-task-form">
        <Flex vertical={true}>
          <InputLabel htmlFor="title" {...validation.title}>
            Title *
          </InputLabel>
          <FormInput
            id="title"
            value={form.title}
            size="medium"
            disabled={saving}
            onChange={(e) => updateForm("title", e.target.value)}
            placeholder="e.g. 30 Day Follow-up...."
            {...validation.title}
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="type" {...validation.type}>
            Task Type *
          </InputLabel>
          <Select
            value={form.type}
            onChange={(val) => updateForm("type", val)}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
            disabled={saving}
            {...validation.type}
          >
            <Select.Option value="general">
              <TaskBadge type="general" />
              General
            </Select.Option>
            <Select.Option value="call">
              <TaskBadge type="call" />
              Call
            </Select.Option>
            <Select.Option value="email">
              <TaskBadge type="email" />
              Email
            </Select.Option>
            <Select.Option value="text">
              <TaskBadge type="text" />
              Text Message
            </Select.Option>
          </Select>
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="instructions" {...validation.instructions}>
            Instructions *
          </InputLabel>
          <TextArea
            value={form.instructions}
            onChange={(e) => updateForm("instructions", e.target.value)}
            size="large"
            autoSize={true}
            disabled={saving}
            placeholder="e.g. Let customer know their program have begun..."
          />
        </Flex>
        {isEditing ? (
          <Flex>
            <AlertBox type="info">
              <b>NOTE:</b>&nbsp;&nbsp;Editing this template will not affect any
              workflows or customer tasks that were built from this template, it
              will only affect tasks added to workflows or customers going
              forward.
            </AlertBox>
          </Flex>
        ) : null}
      </div>
      <div className="new-task-actions">
        <Button type="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <div>
          {isEditing ? (
            <Button onClick={removeTemplate} type="nope">
              <IconTrash />
            </Button>
          ) : null}

          <Button
            type="primary"
            className="green-btn"
            disabled={!validation.allValid || !hasChanged}
            onClick={createTemplate}
            loading={saving}
          >
            {isEditing ? "Save Template" : "Create Template"}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default ManageTemplate;
