import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { changeUserRole } from "App/Admin/_graphql/mutations/user";
import { getErrors } from "_helpers/api";
import {
  IconIdBadge2,
  IconUserFilled,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import "./ChangeRole.scss";

function ChangeRole(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [role, setRole] = useState(
    props.roleUser?.role === "removed"
      ? undefined
      : props.roleUser?.role || undefined,
  );

  // Submit question form
  const updateRole = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { user_id: props.roleUser.id, role },
        mutation: changeUserRole,
      });
      message.success(`Role updated for ${props.roleUser.name}`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error updating role, try again");
      setSaving(false);
    }
  };

  const access = {
    super: ["super", "admin", "manager", "user"],
    admin: ["admin", "manager", "user"],
    manager: ["manager", "user"],
    user: [],
  };

  const userRole = props.user?.role || "user";

  const RoleOption = ({ roleName, name, description }) =>
    access[userRole]?.includes(roleName) ? (
      <div
        onClick={() => setRole(roleName)}
        className={`ro-option${roleName === role ? " selected" : ""}`}
      >
        <Tag type={roleName} size="role">
          {name}
        </Tag>
        <p>{description}</p>
        <IconCircleCheckFilled />
      </div>
    ) : null;

  return (
    <Modal
      wrapClassName="change-role-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <div className="pp-modal-header">
        <div>
          <IconIdBadge2 />
        </div>
        <h3>Change Role</h3>
      </div>

      <Modal.Body>
        <div className="role-card">
          <div className="rc-icon">
            <IconUserFilled />
          </div>
          <h3>Matthew Angelini</h3>
          <p>mangelini@gmail.com</p>
        </div>
        <div className="role-options">
          <RoleOption
            roleName="super"
            name="Super Admin"
            description="Access to all customers, payments, companies and users, across the platform."
          />
          <RoleOption
            roleName="admin"
            name="Admin"
            description="Access to all customers, payments, companies, and users under your umbrella."
          />
          <RoleOption
            roleName="manager"
            name="Manager"
            description="Access to all customers, their payments, and users under your umbrella."
          />
          <RoleOption
            roleName="user"
            name="User"
            description="Access to only their specific customers and ability to manage them."
          />
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={!role}
          onClick={updateRole}
          loading={saving}
        >
          Save Role
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ChangeRole;
