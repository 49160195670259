import React from "react";
import { InputLabel, Select, DatePicker } from "_styleguide";
import Schedule from "./_components/Schedule/Schedule";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { validatePayment } from "App/Admin/Wizard/_helpers/validateWizard";
import "./Payment.scss";

dayjs.extend(advancedFormat);

function Payment(props) {
  const { form } = props;
  const plan = form?.plan;

  const u = async (f, v) => {
    const newPlan = { ...form.plan };
    newPlan[f] = v;
    props.ua(form, {
      plan: newPlan,
    });
  };

  const dateFormat = "MMMM D, YYYY";
  let dateValue = undefined,
    nextValue = undefined;
  const dateChange = async (f, v) => {
    const newPlan = { ...form.plan };
    newPlan[f] = v.toDate();
    props.ua(form, {
      plan: newPlan,
    });
  };
  const disabledDate = (current) => {
    const isWeekend = [0, 6].includes(current.day()) ? true : false;
    if (isWeekend) return true;
    if (current.isBefore(dayjs().subtract(1, "day"))) return true;
    if (current.isAfter(dayjs().add(45, "day"))) return true;
    return false;
  };

  if (plan?.start_date) {
    if (plan?.start_date?.length <= 13) {
      dateValue = dayjs(parseInt(plan?.start_date));
    } else {
      dateValue = dayjs(plan?.start_date);
    }
  }

  if (plan?.next_date) {
    if (plan?.next_date?.length <= 13) {
      nextValue = dayjs(parseInt(plan?.next_date));
    } else {
      nextValue = dayjs(plan?.next_date);
    }
  }

  const validation = validatePayment(plan);

  return (
    <React.Fragment>
      {/*      <div className="general-info-box">
        <IconInfoCircle />
        {plan?.start_date ? (
          <p>
            Payment will be due on the{" "}
            <b>{dateValue.format("Do")} of every month</b> from your connected
            account. You will be reminded when payments are due.
          </p>
        ) : (
          <p>
            It’s recommended to select the date you get paid for guaranteed,
            uninterrupted debt relief.
          </p>
        )}
      </div>*/}
      <div className="wzrd-box admin-custom-plan wzrd-dates">
        <div className="wzrd-header">
          <h3>Payment Schedule</h3>
        </div>
        <div className="wzrd-body">
          <div className="form-item">
            <InputLabel>Payment Timing</InputLabel>
            <Select
              value={plan.payment_schedule}
              onChange={(val) => u("payment_schedule", val)}
              size="extra-medium"
              popupClassName="pp-select-dropdown"
              placeholder="Select payment timing"
              {...validation.payment_schedule}
            >
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="semi-monthly">Semi-Monthly</Select.Option>
              <Select.Option value="bi-weekly">Bi-Weekly</Select.Option>
            </Select>
          </div>
          <div className="form-row">
            <div>
              <InputLabel>
                {plan?.payment_schedule === "monthly" ? "Start Date" : ""}
                {plan?.payment_schedule === "semi-monthly" ? "First Date" : ""}
                {plan?.payment_schedule === "bi-weekly" ? "Next Payment" : ""}
              </InputLabel>
              <DatePicker
                value={dateValue}
                placeholder="Select date..."
                disabledDate={disabledDate}
                onChange={(val) => dateChange("start_date", val)}
                format={dateFormat}
                allowClear={false}
                size="medium"
              />
            </div>
            {plan?.payment_schedule &&
            plan?.payment_schedule === "semi-monthly" ? (
              <div>
                <InputLabel>
                  {plan?.payment_schedule === "semi-monthly"
                    ? "Second Date"
                    : "Next Payment"}
                </InputLabel>
                <DatePicker
                  value={nextValue}
                  placeholder="Select date..."
                  disabledDate={disabledDate}
                  onChange={(val) => dateChange("next_date", val)}
                  format={dateFormat}
                  allowClear={false}
                  size="medium"
                />
              </div>
            ) : null}
          </div>
          {plan?.payment_schedule && plan?.payment_schedule !== "bi-weekly" ? (
            <div className="form-item">
              <InputLabel>Weekend Payments</InputLabel>
              <Select
                value={plan.weekend_payments}
                onChange={(val) => u("weekend_payments", val)}
                size="extra-medium"
                popupClassName="pp-select-dropdown"
                placeholder="Select payment timing"
              >
                <Select.Option value="friday">
                  Default to Friday before
                </Select.Option>
                <Select.Option value="monday">
                  Default to Monday after
                </Select.Option>
              </Select>
            </div>
          ) : null}
        </div>
      </div>
      <Schedule
        payments={form?.payments || []}
        plan={plan}
        client={props.client}
      />
    </React.Fragment>
  );
}

export default Payment;
