import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "../node_modules/@centrate-io/barn/dist/index.css";
import "../node_modules/@centrate-io/barn/dist/_fonts/fonts.scss";
import "_styleguide/styleguide.scss";
import App from "App/App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import moment from "moment";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    ss: "%s seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_PATH}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("JWT");
  return {
    headers: { ...headers, Authorization: token ? `JWT ${token}` : "" },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <ApolloProvider client={client}>
      <App client={client} />
    </ApolloProvider>
  </React.Fragment>,
);
