import React, { Component } from "react";
import "./AlertBox.scss";

export default class AlertBox extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-alert-box " + (props.className || "");

    if (props.type) {
      props.className += " pp-alert-box-" + props.type;
    }

    return <div {...props}>{props.children}</div>;
  }
}
