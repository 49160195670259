import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminCompanies } from "App/Admin/_graphql/queries/company";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import { getCrumbs } from "../_helpers/crumbs";
import InviteCompany from "./_modals/InviteCompany/InviteCompany";
import "./Companies.scss";

function Companies(props) {
  const client = useApolloClient();
  const [inviteModal, setInviteModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });

  const columns = getColumns(props?.user?.company?.id);

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      query: adminCompanies,
    });
    setState({
      loading: false,
      data: queryData?.data?.adminCompanies || [],
    });
    props?.setCurrentCount(queryData?.data?.adminCompanies?.length || 0);
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("companies"));
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-companies">
      <div className="admin-search admin-button">
        <Button
          type="primary"
          size="small"
          onClick={() => setInviteModal(true)}
        >
          Invite Company
        </Button>
        {inviteModal ? (
          <InviteCompany
            reload={fetchDocuments}
            removeModal={() => setInviteModal(false)}
          />
        ) : null}
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 230px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading companies..."
              : "No companies found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          {/*Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}*/}
        </div>
      )}
    </div>
  );
}

export default Companies;
