import React from "react";
import { assetCategories, cashCategories } from "_helpers/budget";
import { flatMoney, formatPercent } from "_assets/js/helpers";
const OverviewItem = (props) => (
  <div
    className={`budget-overview-item${props.borderless ? " borderless" : ""}${props.green === true ? " green" : ""}${props.green === false ? " red" : ""}`}
  >
    <h5>{props.title}</h5>
    <p>{props.amount}</p>
  </div>
);

function Overview(props) {
  const { budget } = props;

  const summary = {
    net_income: 0,
    net_expenses: 0,
    disposable_income: 0,
    expense_percent: 0,
    debt_to_income: 0,
    cash_flow: 0,
    total_assets: 0,
  };

  budget.forEach((b) => {
    const isIncome = b.type === "income" ? true : false;
    const isAsset = assetCategories.includes(b.category);
    const isCash = cashCategories.includes(b.category);

    if (isIncome && !isAsset && !isCash) {
      summary.net_income += b.value;
    }
    if (!isIncome) {
      summary.net_expenses -= b.value;
    }
    if (isIncome && isCash) {
      summary.cash_flow += b.value;
    }
    if (isIncome && isAsset) {
      summary.total_assets += b.value;
    }
  });
  summary.debt_to_income =
    summary.net_income === 0
      ? null
      : (Math.abs(summary.net_expenses) / summary.net_income) * 100;
  summary.disposable_income = summary.net_income + summary.net_expenses;

  return (
    <div className="wzrd-box">
      <div className="wzrd-header">
        <h3>Financial Overview</h3>
      </div>
      <div className="wzrd-body">
        <div className="budget-overview">
          <OverviewItem
            title="Monthly Income"
            amount={flatMoney(summary.net_income)}
          />
          <OverviewItem
            title="Monthly Expenses"
            amount={flatMoney(summary.net_expenses)}
          />
          <OverviewItem
            title="Cash Flow"
            amount={flatMoney(summary.disposable_income)}
            green={
              summary.disposable_income > 0
                ? true
                : summary.disposable_income < 0
                  ? false
                  : undefined
            }
          />
          <OverviewItem
            title="Debt-to-Income"
            amount={formatPercent(summary.debt_to_income)}
            borderless
          />
          <OverviewItem
            title="Total Cash"
            amount={flatMoney(summary.cash_flow)}
            borderless
          />
          <OverviewItem
            title="All Assets"
            amount={flatMoney(summary.total_assets)}
            borderless
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
