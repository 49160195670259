import React from "react";
import { flatMoney } from "_assets/js/helpers";
import {
  IconDropletHalf2,
  IconTargetArrow,
  IconBolt,
  IconCheck,
} from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import "./OfferCard.scss";

const COSTS = {
  service_percent: 40,
  monthly_fees: 0,
};

function OfferCard(props) {
  const { duration, form } = props;
  let className = `pp-plan-card`;
  className += " duration-" + duration;

  const totalCost = (months) => {
    const totalDebt = form?.plan?.debt_amount || 0;
    return parseFloat(
      (totalDebt * (COSTS.service_percent / 100) +
        COSTS.monthly_fees * months) /
        months,
    );
  };

  const plans = {
    24: {
      title: "Steady Course",
      icon: <IconDropletHalf2 />,
      type: "relaxed",
      months: 24,
      cost: totalCost(24),
    },
    18: {
      title: "Accelerated",
      icon: <IconTargetArrow />,
      type: "moderate",
      months: 18,
      cost: totalCost(18),
    },
    12: {
      title: "Rapid Relief",
      icon: <IconBolt />,
      type: "aggressive",
      months: 12,
      cost: totalCost(12),
    },
  };

  const selectPlan = (planInfo) => {
    const newPlan = {
      ...form?.plan,
      type: planInfo.type,
      amount: planInfo.cost,
      monthly_fees: COSTS.monthly_fees,
      months: planInfo.months,
      service_percent: COSTS.service_percent,
    };
    props.ua(form, {
      plan: newPlan,
    });
  };

  const planData = plans[duration];
  const selected = form?.plan?.type === planData?.type;

  return (
    <div className={className}>
      <div className="oc-info">
        <div className="oc-circle">{planData?.icon}</div>
        <div className="oc-content">
          <h4>
            {flatMoney(planData?.cost)} per month, for {duration} months
          </h4>
          <p>{planData?.title} Plan</p>
        </div>
        <Button
          type={selected ? "primary" : "secondary"}
          onClick={() => (selected ? null : selectPlan(planData))}
        >
          {selected ? (
            <React.Fragment>
              <IconCheck />
              Selected
            </React.Fragment>
          ) : (
            "Select Plan"
          )}
        </Button>
      </div>
    </div>
  );
}

export default OfferCard;
