import { Text, Tooltip } from "@centrate-io/barn";
import {
  IconSignature,
  IconCreditCard,
  IconStack,
  IconScale,
  IconPennant,
  IconCheckbox,
  IconCircleCheck,
  IconCircleX,
  IconCircleMinus,
} from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import { accountTypeCredit, accountTypeLoan } from "_helpers/experian";
import { Tag } from "_styleguide";

export const getColumns = () => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconCreditCard />
            <b>{data.tradeline?.customerName || "Unknown"}</b>
            {data?.is_custom ? <Tag size="small">Custom</Tag> : null}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.tradeline?.accountNumber || "0000-0000"}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Balance
        </div>
      ),
      dataIndex: "balanceAmount",
      key: "balanceAmount",
      sorter: true,
      render: (name, data) => (
        <Text>{formatMoney(parseInt(data?.tradeline?.balanceAmount))}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconPennant />
          Status
        </div>
      ),
      dataIndex: "openOrClosed",
      key: "openOrClosed",
      sorter: true,
      render: (name, data) => {
        const statusMap = {
          O: "Open",
          C: "Closed",
          P: "Paid / Zero Balance",
          U: "Unknown",
        };
        return <Text>{statusMap[data?.tradeline?.openOrClosed || "U"]}</Text>;
      },
    },
    {
      title: (
        <div className="column-name">
          <IconStack />
          Type
        </div>
      ),
      dataIndex: "accountType",
      key: "accountType",
      sorter: true,
      render: (name, data) => (
        <Text>{data?.tradeline?.accountTypeText || "Unknown"}</Text>
      ),
    },
    {
      title: (
        <div className="column-name" style={{ justifyContent: "center" }}>
          <IconCheckbox /> Selected
        </div>
      ),
      dataIndex: "selected",
      key: "selected",
      sorter: true,
      align: "center",
      width: 160,
      render: (selected, data) => {
        const isApproved =
          accountTypeLoan.includes(data?.tradeline?.accountType) ||
          accountTypeCredit.includes(data?.tradeline?.accountType);
        const balanceAmount = parseInt(data?.tradeline?.balanceAmount || 0);
        const isOpen = data?.tradeline?.openOrClosed === "C" ? false : true;
        if (selected)
          return (
            <Tooltip title="Selected">
              <IconCircleCheck />
            </Tooltip>
          );
        if (isApproved && balanceAmount > 0 && isOpen) {
          return (
            <Tooltip title="Not Selected">
              <IconCircleX />
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Not Applicable">
            <IconCircleMinus />
          </Tooltip>
        );
      },
    },
  ];
};
