import { gql } from "@apollo/client";

export const getWorkflows = gql`
  query {
    getWorkflows {
      id
      short_id
      name
      total_tasks
      user {
        id
        short_id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const getAppWorkflows = gql`
  query getAppWorkflows($application_id: ID!) {
    getAppWorkflows(application_id: $application_id) {
      workflows {
        id
        name
        total_tasks
      }
      selected
    }
  }
`;

export const getWorkflow = gql`
  query getWorkflow($workflow_id: ID) {
    getWorkflow(workflow_id: $workflow_id) {
      id
      name
      total_tasks
      tasks {
        id
        title
        type
        instructions
        order
        start
        delay
        assigned_user_id
        assigned_team_id
        template_id
        assigned_user {
          name
        }
        assigned_team {
          name
        }
        template {
          title
          type
          instructions
        }
      }
    }
  }
`;
