import React, { Component } from "react";
import logo from "_assets/logos/icon.svg";
import "./Construction.scss";

class Construction extends Component {
  render() {
    return (
      <div className="pp-construction">
        <img src={logo} alt="" />
        <div className="pp-construction-info">
          <h2>Scheduled Maintenance</h2>
          <p>
            The site is currently undergoing scheduled maintenance but should be
            available soon. If you have any questions, please reach out to
            <a className="pp-link" href="mailto:engineering@paypath.ai">
              engineering@paypath.ai
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Construction;
