import React from "react";
import {
  formatMoney,
  numberWithCommas,
  parseAndLimit,
} from "_assets/js/helpers";
import "./StatBar.scss";

function StatBar(props) {
  const { stats, laststats, processingstats } = props;

  const diff = {
    payments: (stats?.payments || 0) - (laststats?.payments || 0),
    amount: parseAndLimit((stats?.amount || 0) / (laststats?.amount || 0)),
    paypath: parseAndLimit((stats?.paypath || 0) / (laststats?.paypath || 0)),
  };

  console.log(processingstats);

  return (
    <div className="snap-stat-bar">
      <div className="stat-card">
        <label>Total Payments</label>
        <h3>
          {numberWithCommas(stats?.payments || 0)}
          {diff.payments > 0 ? <span>+{diff.payments}</span> : null}
          {diff.payments < 0 ? (
            <span className="red">-{diff.payments}</span>
          ) : null}
        </h3>
      </div>
      <div className="stat-card">
        <label>Money Processed</label>
        <h3>
          {formatMoney(stats?.amount || 0)}
          {parseFloat(diff.amount) > 1 && diff.amount !== "Infinity" ? (
            <span>{diff.amount}X</span>
          ) : null}
          {parseFloat(diff.amount) < 1 && diff.amount !== "0.00" ? (
            <span className="red">{diff.amount}X</span>
          ) : null}
        </h3>
      </div>
      <div className="stat-card">
        <label>Est. Paypath Fees</label>
        <h3>
          {formatMoney(stats?.paypath || 0)}{" "}
          {parseFloat(diff.paypath) > 1 && diff.paypath !== "Infinity" ? (
            <span>{diff.paypath}X</span>
          ) : null}
          {parseFloat(diff.paypath) < 1 && diff.paypath !== "0.00" ? (
            <span className="red">{diff.paypath}X</span>
          ) : null}
        </h3>
      </div>
      <div className="stat-card processing">
        <label>Processing Payments</label>
        <h3>{numberWithCommas(processingstats?.payments || 0)}</h3>
      </div>
      <div className="stat-card processing">
        <label>Money Processing</label>
        <h3>{formatMoney(processingstats?.amount || 0)}</h3>
      </div>
      <div className="stat-card processing">
        <label>Possible PayPath Fees</label>
        <h3>{formatMoney(processingstats?.paypath || 0)}</h3>
      </div>
    </div>
  );
}

export default StatBar;
