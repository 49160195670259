import React, { useState, useEffect } from "react";
import { Search, Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminCreditReports } from "App/Admin/_graphql/queries/creditreport";
import { getColumns } from "./_data/Columns";
import { useNavigate } from "react-router-dom";
import "./CreditReports.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: undefined,
  sortOrder: undefined,
};

function CreditReports(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  const goToTradelines = () => {
    navigate(`/customer/${props.shortId}/tradelines`);
    props.setTab("tradelines");
  };

  const columns = getColumns(goToTradelines);

  // Load CreditReports
  const fetchCreditReports = async (params = defaultParams) => {
    const queryData = await client.query({
      variables: {
        id: props?.applicationId,
        ...params,
        search: search,
      },
      query: adminCreditReports,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.adminCreditReports?.pageTotal;
    pagination.totalAll = queryData?.data?.adminCreditReports?.total;
    setPage(params?.page || 1);
    const reportData = (queryData?.data?.adminCreditReports?.data || []).map(
      (c) => {
        let reportData = null;
        try {
          reportData = JSON.parse(c.report);
        } catch (err) {
          reportData = null;
        }
        return { ...c, reportData };
      },
    );
    setState({
      loading: false,
      data: reportData,
      pagination: pagination,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchCreditReports({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
    });
  };

  useEffect(() => {
    fetchCreditReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  return (
    <div className="admin-application-credit-reports">
      <div className="manage-search">
        <div className="ms-actions"></div>
        <div className="ms-filters">
          <Search
            placeholder="Search credit reports..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={() => {
              fetchCreditReports({ ...defaultParams, search });
            }}
            style={{ width: 304 }}
          />
        </div>
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 276px - 40px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading credit lines..."
              : "No credit lines found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default CreditReports;
