import React, { Component } from "react";
import { InputNumber as AntdInput } from "antd";
import "./InputNumber.scss";

class InputNumber extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-input-number " + (props.className || "");

    if (props.started && props.valid !== 1) {
      props.className += " pp-invalid";
    }
    if (props.size === "large") {
      props.className += " pp-large";
    }
    if (props.size === "medium") {
      props.className += " pp-medium";
    }

    let icon = null;
    if (props.icon) {
      icon = props.icon;
      delete props.icon;
    }

    return (
      <div className={`pp-input-number-wrapper ${icon ? "pp-icon" : ""}`}>
        {icon}
        <AntdInput {...props} />
      </div>
    );
  }
}

export default InputNumber;
