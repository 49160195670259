import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, message } from "@centrate-io/barn";
import { saveFormLocal } from "_helpers/form";
import { saveApplication } from "_graphql/mutations/application";
import PayPathSmall from "_assets/logos/paypath_small.svg";
import VLGLogo from "_assets/logos/vlg.svg";
import "./Actions.scss";

function Actions(props) {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  // Save Application Flow
  const [updateApplication] = useMutation(saveApplication, {
    onError: (err) => {
      setIsSaving(false);
      message.error("Error saving application, try again");
      throw new Error("Error saving application, try again");
    },
  });
  const saveForm = async (form, stage) => {
    const doApplication = await updateApplication({
      variables: {
        application: { ...form },
      },
    });
    if (doApplication.errors) {
      throw new Error("Error saving application, try again");
    }
    return doApplication;
  };

  const handleNext = async (which) => {
    const category = props.name;
    setIsSaving(true);
    if (which !== "previous") {
      try {
        const saveData = await saveForm({ ...props.form, stage: category });
        if (saveData?.data?.saveApplication) {
          await saveFormLocal(saveData?.data?.saveApplication);
        }
        setIsSaving(false);
        navigate(props[which]);
      } catch (err) {
        setIsSaving(false);
        console.log(err);
      }
    } else {
      setIsSaving(false);
      navigate(props[which]);
    }
  };

  return (
    <div className={`bottom-actions ${props.noMobile && "no-mobile"}`}>
      <div className="action-buttons">
        {props.previous && !props.noPrevious ? (
          <Button
            loading={isSaving}
            onClick={() => handleNext("previous")}
            type="secondary"
            size="large"
            block
          >
            Previous step
          </Button>
        ) : null}
        {props.next && !props.customNext ? (
          <Button
            disabled={props.disabledNext}
            loading={isSaving}
            onClick={() => handleNext("next")}
            type="primary"
            size="large"
            className="green-btn"
            block
          >
            {props.nextText || "Next step"}
          </Button>
        ) : null}
        {props.customNext ? props.customNext : null}
      </div>
      <div
        className="powered"
        style={{ display: props.noPowered ? "none" : "flex" }}
      >
        Powered by <img src={PayPathSmall} alt="PayPath" />
      </div>
      {props.showLogo && (
        <div className="footer-logo">
          <img src={VLGLogo} alt="Virtue Law Group" />
        </div>
      )}
    </div>
  );
}

export default Actions;
