import { gql } from "@apollo/client";

export const getBudget = gql`
  query getBudget($application_id: ID) {
    getBudget(application_id: $application_id) {
      id
      items {
        name
        category
        type
        value
      }
      createdAt
      updatedAt
    }
  }
`;
