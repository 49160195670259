import React, { Component } from "react";
import "./SubTitle.scss";

class SubTitle extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-subtitle " + (props.className || "");
    return <h5 {...props}>{props.children}</h5>;
  }
}

export default SubTitle;
