import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getErrors } from "_helpers/api";
import {
  saveForm,
  saveTradelines,
  updateBudget,
} from "App/Admin/Wizard/_helpers/form";
import { calculateStartDate } from "_helpers/plan";
import { Button, message } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import { Steps } from "App/Admin/Wizard/_helpers/steps";
import "./Footer.scss";

function Footer(props) {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const isNew = props.applicationId === "new";
  const current = Steps[props?.tab];

  const updateForm = async () => {
    const current = Steps[props?.tab];
    const isTransitioning = current?.next && isNew;
    try {
      const formData = { ...props.form };
      if (!formData?.plan?.start_date) {
        formData.plan = formData.plan ? { ...formData.plan } : {};
        formData.plan.start_date = calculateStartDate();
      }
      setSaving(true);
      if (isTransitioning) props.setTransitioning(true);
      const newId = await saveForm(props.client, formData);
      props?.setSavedForm(JSON.stringify(formData));
      setSaving(false);
      if (isTransitioning) {
        navigate(`/wizard/${newId}/${current?.next}`);
      } else {
        goNext();
      }
    } catch (err) {
      message.error(getErrors(err) || "Error saving application, try again");
      setSaving(false);
      props.setTransitioning(false);
    }
  };

  const updateTradelines = async () => {
    try {
      setSaving(true);
      await saveTradelines(
        props.client,
        props?.credit?.tradelines,
        props?.applicationId,
      );
      props?.credit?.setSavedTradelines(
        JSON.stringify(props?.credit?.tradelines?.sort()),
      );
      setSaving(false);
      goNext();
    } catch (err) {
      message.error(getErrors(err) || "Error saving application, try again");
      setSaving(false);
    }
  };

  const submitBudget = async (value) => {
    try {
      setSaving(true);
      await updateBudget(
        props.client,
        props.budget.items,
        props?.applicationId,
      );
      props?.budget?.setSavedBudgetItems(
        JSON.stringify(props.budget.items?.sort()),
      );
      setSaving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error saving budget, try again");
      setSaving(false);
    }
  };

  const clickSave = () => {
    if (props?.tab === "credit") {
      updateTradelines();
    } else if (props?.tab === "budget") {
      submitBudget();
    } else {
      updateForm();
    }
  };

  const goNext = () => {
    navigate(`/wizard/${props.applicationId}/${current?.next}`);
  };

  return (
    <div className="admin-wizard-footer">
      <div className="awf-left">
        {current?.previous && !props.isEdited && (
          <Link
            to={`/wizard/${props.applicationId}/${current?.previous}`}
            disabled={props.isEdited}
          >
            <Button type="neutral">Back</Button>
          </Link>
        )}
        {props.isEdited && props?.tab !== "budget" && (
          <Button type="neutral" disabled>
            Back
          </Button>
        )}
      </div>
      <div className="awf-right">
        {current?.next || props?.tab === "budget" ? (
          <Button
            loading={saving}
            type="primary"
            onClick={clickSave}
            disabled={!props.isEdited}
          >
            {props?.tab === "budget" ? "Save Budget" : "Save & Continue"}
          </Button>
        ) : null}
        {/*        {current?.next && !isNew && !props.isEdited && (
          <Link to={`/wizard/${props.applicationId}/${current?.next}`}>
            <Button className="green-btn" type="primary">
              Next Step
            </Button>
          </Link>
        )}
        {(isNew || props.isEdited) && (
          <Button className="green-btn" type="primary" disabled>
            Next Step
          </Button>
        )}*/}
      </div>
    </div>
  );
}

export default Footer;
