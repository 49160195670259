import { gql } from "@apollo/client";

export const getTeams = gql`
  query {
    getTeams {
      id
      short_id
      name
      users {
        id
        name
        email
      }
      user_count
      createdAt
    }
  }
`;

export const getTeam = gql`
  query getTeam($team_id: ID) {
    getTeam(team_id: $team_id) {
      id
      name
      users {
        id
        name
        email
      }
    }
  }
`;
