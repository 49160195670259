import React, { Component } from "react";
import "./UserAvatar.scss";

class UserAvatar extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-user-avatar " + (props.className || "");

    if (props.size) {
      props.className += " pp-tag-" + props.size;
    }

    return (
      <div {...props}>
        <span>{props.name[0].toUpperCase()}</span>
      </div>
    );
  }
}

export default UserAvatar;
