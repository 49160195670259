import React from "react";
import {
  IconListDetails,
  IconCreditCard,
  IconCalculator,
  IconPercentage,
  IconCash,
  IconShoppingCart,
  IconCheck,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import {
  validateInformation,
  validatePlan,
  validateSchedule,
} from "App/Admin/Wizard/_helpers/validateWizard";
import { Tooltip } from "@centrate-io/barn";
import "./Menu.scss";

const MenuItem = (props) => {
  const isSelected = props.selected === props.tab;
  const information = props?.validation?.[props.tab];
  const isCompleted = information?.isCompleted;
  const notReady = information?.isDisabled;
  const isDisabled = notReady;
  let className = "awm-item";
  if (isSelected) className += " selected";
  if (isCompleted) className += " completed";
  if (isDisabled) className += " disabled";
  const itemContainer = (
    <div className={className}>
      <span className="awm-item-icon">
        {isCompleted ? <IconCheck /> : information.icon}
      </span>
      <h5>{props.title}</h5>
    </div>
  );
  return isDisabled ? (
    <Tooltip title="Complete previous steps" placement="bottom">
      {itemContainer}
    </Tooltip>
  ) : (
    <Link to={`/wizard/${props.applicationId}/${props.tab}`}>
      {itemContainer}
    </Link>
  );
};

function Menu(props) {
  const savedForm = props.savedData;

  const informationValidation = validateInformation(savedForm);
  const planValidation = validatePlan(savedForm?.plan || {});
  const scheduleValidation = validateSchedule(savedForm?.plan || {});

  const completed = {
    information: informationValidation?.allValid,
    credit: (savedForm?.plan?.debt_amount || 0) >= 5000,
    plan: planValidation?.allValid,
    payments: scheduleValidation?.allValid,
    bank: savedForm?.plaid ? true : false,
  };

  const menuProps = {
    applicationId: props.applicationId,
    selected: props.tab,
    validation: {
      information: {
        isCompleted: completed.information,
        icon: <IconListDetails />,
      },
      credit: {
        isDisabled: !completed.information,
        isCompleted: completed.credit,
        icon: <IconCreditCard />,
      },
      budget: {
        isDisabled: false,
        isCompleted: false,
        icon: <IconCalculator />,
      },
      plan: {
        isDisabled: !(completed.information && completed.credit),
        isCompleted: completed.plan,
        icon: <IconPercentage />,
      },
      payment: {
        isDisabled: !completed.plan,
        isCompleted: completed?.payments,
        icon: <IconCash />,
      },
      customer: {
        isDisabled: !(
          completed.information &&
          completed.credit &&
          completed.plan
        ),
        isCompleted: false,
        icon: <IconShoppingCart />,
      },
    },
  };

  return (
    <div className="admin-wizard-menu">
      <MenuItem tab="information" title="Basic Info" {...menuProps} />
      <MenuItem tab="credit" title="Credit Select" {...menuProps} />
      <MenuItem tab="plan" title="Program Plan" {...menuProps} />
      <MenuItem tab="payment" title="Payment Schedule" {...menuProps} />
      <MenuItem tab="customer" title="Review" {...menuProps} />
      <div className="awm-sep" />
      <MenuItem tab="budget" title="Budget Calculator" {...menuProps} />
    </div>
  );
}

export default Menu;
