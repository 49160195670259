import { gql } from "@apollo/client";

export const adminCreateNote = gql`
  mutation adminCreateNote(
    $application_id: String!
    $content: String!
    $user_id: String
  ) {
    adminCreateNote(
      application_id: $application_id
      content: $content
      user_id: $user_id
    ) {
      id
    }
  }
`;
