import { Text } from "@centrate-io/barn";
import {
  IconSignature,
  IconCoin,
  IconCalendar,
  IconFile,
  IconFiles,
} from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import { Tag } from "_styleguide";
import { paymentTag } from "_helpers/payments";

export const getColumns = () => {
  return [
    {
      title: (
        <div className="column-name">
          <IconFiles />
          Customer
        </div>
      ),
      dataIndex: "application",
      key: "application",
      width: 200,
      render: (application, data) => (
        <div>
          <Text className="doc-name">
            <IconFile />
            <b>
              {data?.["application.first_name"]}{" "}
              {data?.["application.last_name"]}
            </b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data?.["application.short_id"]}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Payment
        </div>
      ),
      dataIndex: "order",
      key: "order",
      width: 200,
      render: (order, data) => (
        <div>
          <Text className="doc-name">
            <IconCoin />
            <b>
              Payment # {order || 0}/{data?.["application.total_payments"]}
            </b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: <div className="column-name">Status</div>,
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "center",
      render: (status) => (
        <Text>
          <Tag type={paymentTag[status]}>{status?.toUpperCase()}</Tag>
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Due Date
        </div>
      ),
      dataIndex: "first_attempt",
      key: "first_attempt",
      sorter: true,
      width: 150,
      align: "center",
      render: (first_attempt, data) => <Text>{data?.["Due Date"]}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Paid Date
        </div>
      ),
      dataIndex: "last_attempt",
      key: "last_attempt",
      sorter: true,
      width: 150,
      align: "center",
      render: (last_attempt, data) => <Text>{data?.["Processed Date"]}</Text>,
    },

    {
      title: <div className="column-name">Amount</div>,
      dataIndex: "amount",
      key: "amount",
      width: 150,
      align: "center",
      render: (amount) => <Text>{formatMoney(amount)}</Text>,
    },
    {
      title: <div className="column-name">PayPath Fee</div>,
      dataIndex: "paypath_fee",
      key: "paypath_fee",
      width: 150,
      align: "center",
      render: (paypath_fee, data) => <Text>{data?.["Est. PayPath Fee"]}</Text>,
    },
    {
      title: <div className="column-name">Plan Months</div>,
      dataIndex: "plan_months",
      key: "plan_months",
      width: 150,
      align: "center",
      render: (plan_months, data) => <Text>{data?.["Plan Months"]}</Text>,
    },
    {
      title: <div className="column-name">Total Payments</div>,
      dataIndex: "total_payments",
      key: "total_payments",
      width: 150,
      align: "center",
      render: (total_payments, data) => <Text>{data?.["Total Payments"]}</Text>,
    },
    {
      title: <div className="column-name"># Attempts</div>,
      dataIndex: "attempts",
      key: "attempts",
      width: 150,
      align: "center",
      render: (attempts, data) => <Text>{data?.["Total Attempts"]}</Text>,
    },
  ];
};
