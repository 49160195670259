import React from "react";
import moment from "moment";
import { formatMoney } from "_assets/js/helpers";

export const LongTerms = ({ form, plaidData, agreement }) => (
  <React.Fragment>
    <h2>LEGAL SERVICES AGREEMENT</h2>
    <p>
      The purpose of this correspondence is to, upon execution: (1) establish an
      attorney client relationship between Virtue Law Group, LLC ("VLG" or "the
      Firm") and Client ("You"); (2) define the scope of the Firm's
      representation of You; and (3) establish other material terms and
      conditions of the representation, including but not limited to the
      financial terms. This correspondence may be referred to as the Engagement
      Letter (“Letter”) or the Legal Services Agreement (“Agreement”).
    </p>
    <h3>Parties To Engagement Letter</h3>
    <p>
      The parties to the Agreement are VLG and Client. No other person or entity
      shall be entitled to claim an attorney-client relationship with VLG with
      respect to the legal services to be provided pursuant to this Legal
      Engagement Letter.
    </p>
    <h3>Inception Of Attorney Client Relationship</h3>
    <p>
      No attorney-client relationship will exist between VLG and Client until
      Client has executed the Agreement, nor will VLG be obligated to provide
      legal services, until Client has returned a signed copy or a electronic
      signed equivalent of this Agreement and paid the initial retainer.
    </p>
    <h3>Legal Services</h3>
    <p>
      VLG will provide legal services wherein it will assist Client in resolving
      outstanding disputes with the entities identified below and by disputing
      erroneous or inaccurate information appearing on one or more of Client’s
      credit reports in violation of the Fair Credit Reporting Act, 15 U.S.C. §
      1681 et seq. VLG agrees to provide Client with the following legal
      services: Review and analyze contracts with entities identified below and
      determine legal rights of Client under the same; Enforce applicable laws
      protecting Client from harassment by entities identified below, including
      through the initiation of actions under the Fair Debt Collection Practices
      Act, 15 U.S.C. § 1692d et seq.; Enforce the Fair Credit Reporting Act
      against entities identified below and against credit reporting agencies
      including Experian, Equifax and TransUnion; Prosecute violations of state
      and federal law against entities identified below and against credit
      reporting agencies by initiating actions in state or federal court; Defend
      lawsuits initiated by entities listed below against Client in state or
      federal court; Analyze Client’s qualifications for bankruptcy protection
      under Chapter 7 or Chapter 13 of the U.S. Bankruptcy Code. This service is
      limited to information reported by Client to VLG or obtained by VLG from
      creditors or purported creditors, including information reported to credit
      bureaus. The cost of legal services rendered by VLG is set forth below,
      and those fees are earned by VLG for services rendered to Client as set
      forth herein at the time such fees are paid.
    </p>
    <h3>Client Consent</h3>
    <p>
      Client allows VLG to obtain a copy of Client’s credit report to develop a
      strategy regarding the resolution of debts that are excessive or
      unauthorized by law. Client authorizes VLG to challenge any debts
      appearing in Client’s credit report that Client believes to be invalid,
      inaccurate, or without legal basis. Client further authorizes VLG to
      obtain copies of Client’s credit report throughout the representation at
      VLG’s discretion. Client further consents to VLG's ability to utilize
      other attorneys and law firms to provide services to Client, and may
      assign all servicing of the client's file to such other attorney or law
      firm as deemed appropriate by VLG's Managing Attorney. This may include
      but is not limited to all in-court representation of client, all
      processing of client's file, and all payment collection from client.
    </p>
    <h3>Services to be Performed</h3>
    <p>
      VLG will analyze Client’s credit reports and develop strategies for
      correcting invalid, inaccurate, or unlawful debts for which Client should
      not be held legally responsible. VLG will use existing laws and interact
      with creditors and credit bureaus on Client’s behalf to resolve Client’s
      debts. VLG will also interact with collection agencies to resolve Client’s
      debts by requiring them to supply purported evidence of Client’s alleged
      indebtedness to them. VLG will also consult with Client regarding all
      aspects of the credit reporting process, including all laws applicable to
      the same. VLG will also investigate Client’s delinquent accounts to
      determine the most effective method for resolving Client’s debts or
      removing any legal liability for such debts.
    </p>
    <p>
      If a lawsuit is filed against Client, VLG will represent Client and will
      not charge any additional fees for representation if the lawsuit was
      initiated after the date Client signs this Agreement or electronic signed
      equivalent . If a lawsuit was initiated against Client before the date
      Client signs this Agreement or electronic signed equivalent and Client
      chooses to have VLG represent Client, an additional fee of $500.00 will be
      charged. If legal fees are recovered from an adverse party, VLG will
      retain such fees for its services. Client will be responsible to pay any
      damages resulting from such lawsuits or any settlements reached. All other
      costs incurred in a lawsuit will be paid by VLG out of the fees set forth
      below, including the fees of any attorney retained on Client’s behalf in a
      jurisdiction in which VLG is not admitted to practice law. No additional
      payments from Client to VLG will be necessary for the defense of any
      lawsuit filed against Client regarding the debts below after the date
      Client executes this Agreement. VLG will only settle a debt where
      litigation is active or contemplated by a creditor or debt collector. VLG
      will negotiate a settlement on Client’s behalf without any additional fees
      being charged to or incurred by Client. Any settlement reached shall be
      Client’s responsibility and shall be paid directly from Client to the
      creditor. Client’s payment to VLG will be reduced and re-amortized due to
      the settled account being resolved as determined by the individual
      assigned attorney from VLG that is handling Client’s lawsuit and
      settlement thereof.
    </p>
    <h3>Fees</h3>
    <p>
      Client will pay the following fees for the legal services provided by VLG.
      No fee or other cost will be charged or collected other than that set
      forth below. This is the only amount Client will pay for VLG’s services,
      and this fee is fixed, such that it is earned the moment it is transmitted
      to VLG. Upon request, VLG will provide an update on the progress of
      services performed under this agreement at reasonable intervals of no
      greater frequency than once a month.
    </p>
    <h3>Refund Policy</h3>
    <p>
      If after twenty-four (24) months an account is fully validated by a
      creditor, such that no further dispute to the validity of the account
      could be made, and is not the subject of a lawsuit of any kind, Client is
      entitled to receive a full refund of the fees that Client paid towards
      that account (i.e., Client will be refunded the fees paid in proportion to
      the debt at issue). Should Client have an outstanding balance with VLG at
      the time Client’s refund is issued on the validated account, any refund
      will first be applied towards the outstanding balance.
    </p>
    <h3>Responsibilities of Parties</h3>
    <p>
      VLG will perform the legal services as specified under this agreement,
      keep Client informed of progress and developments, and respond promptly to
      Client's inquiries and communications.
    </p>
    <p>
      Client agrees to provide VLG with complete and accurate information and
      documents, be cooperative, keep VLG informed of relevant developments,
      abide by this Agreement, and make timely payments as agreed upon in this
      Agreement. In addition, Client will advise VLG of any changes to Client’s
      address, telephone number, email address, and/or any other changes in
      contact information.
    </p>
    <p>
      Client acknowledges that there are multiple avenues for addressing a debt,
      that every matter is unique, and that outcomes may vary on a case-by-case
      basis. VLG shall use best efforts to obtain the most favorable result for
      Client based upon the specific circumstances associated with each
      individual debt. VLG will inform Client of any proposed actions and obtain
      Client authorization prior to proceeding. Client understands that VLG
      includes more than one attorney, and that support personnel from VLG may
      also provide services in connection with Client's case.
    </p>
    <h3>Right to Conduct Business Electronically</h3>
    <p>
      Client agrees that VLG may contact Client electronically and
      telephonically and that all business with VLG may be conducted
      electronically. Client further agrees that VLG may transmit data,
      including that regarding your credit profile, electronically, and that any
      electronic communication carries the risk of disclosure to a third party
      and that VLG will not be held responsible for any such inadvertent
      disclosure of information. A facsimile or email transmission of this
      electronically accepted agreement in lieu of a phsycal signature, via an
      email attachment or otherwise, will be as valid as the original. This
      agreement may not be modified except in writing by both parties.
    </p>
    <h3>Client Acknowledgements</h3>
    <p>
      By client signing or digitally accepting terms via “Subscribe” button on
      website to this agreement, Client acknowledges that VLG has not instructed
      Client to breach any contract, fail to make any required payment, or fail
      to perform any obligation Client may have lawfully incurred. VLG reserves
      the right to terminate this agreement if (a) Client fails to make timely
      payments of the amount due or (b) Client’s payments are returned multiple
      times for any reason. VLG will not pay Client’s debts and does not
      guarantee that any debt you now have or may incur will be ultimately
      resolved in association with VLG’s services. Client understands and agrees
      that Client must forward any communication Client receives in printed or
      electronic form from any creditor, court, or representative of a creditor
      or a court and that Client must keep a log of all telephonic
      communications with any creditor or credit reporting agency.
    </p>
    <p>
      Client will not electronically sign by clicking the subscribe button on
      the website, indicating acceptance to the terms and conditions herein, in
      lieu of a physical signature until Client has received and read the
      information statements and notices of cancellation required by state and
      federal law, even if otherwise advised. By the Client electronically
      clicking the subscribe button as acceptance in lieu of a physical
      signature to this agreement, Client acknowledges receipt of these
      disclosures prior to the time of electronic signing and agrees to the
      terms of this agreement.
    </p>
    <h3>Termination of Services by Client</h3>
    <p>
      Client may cancel this agreement at any time before midnight CST of the
      5th day after the date of execution of this agreement by providing
      cancellation in writing. However, to be effective, termination or
      discharge of VLG must be in writing. In such event, Client authorizes VLG
      to make and retain a duplicate of Client’s file.
    </p>
    <p>
      The attorney-client relationship between VLG and Client shall end upon
      discharge of VLG by Client pursuant to this paragraph.
    </p>
    <p>
      However, such discharge shall not relieve Client of any obligation to pay
      fees and costs incurred prior to the discharge, as well as any fees and
      costs expended after the discharge to the extent reasonably required in
      VLG’s sole discretion to protect Client’s interests prior to court order
      substituting new counsel or permitting withdrawal of VLG from the
      litigation.
    </p>
    <p>
      In addition, Client may terminate VLG’s services under this agreement at
      any time by submitting such cancellation in writing. Client understands
      that all fees paid to VLG at the time of cancellation have been earned and
      shall be forfeited, and that no refund of any fees paid up to and
      including the date of cancellation will be refunded.
    </p>
    <h3>Withdrawal From Representation By VLG</h3>
    <p>
      Virtue Law Group shall be permitted to withdraw from representation
      whenever required or permitted to do so by law. In addition, the Firm may
      withdraw as counsel at any time if withdrawal can be accomplished without
      material adverse effects on the interests of Client, or if: 1) Client
      persists in a course of action involving a lawyer's services that the
      lawyer reasonably believes to be criminal or fraudulent; 2) Client has
      used the lawyer's services to perpetrate a crime or fraud; 3) Client
      insists upon pursuing an objective that the lawyer considers repugnant or
      imprudent; 4) Client fails substantially to fulfill an obligation to VLG
      regarding the Firm's services (including, but not limited to, Client’s
      financial obligations under this Agreement after reasonable warning from
      the lawyer that the lawyer will withdraw unless the obligation is
      fulfilled; 5) the representation will result in an unreasonable financial
      burden on the lawyer or has been rendered unreasonably difficult by
      Client; or 6) where other good cause for withdrawal exists.
    </p>
    <h3>Document Storage Policy</h3>
    <p>
      On termination of a matter, the Firm will maintain file documents for five
      years, or any alternate period as determined by New Jersey law or other
      applicable law. Upon termination of the matter, Client has the right to
      take possession of the file. If Client chooses to take possession of the
      file, the firm may copy all or any part of the file. If Client chooses not
      to take possession of the file, the firm will retain the file pursuant to
      its document storage policy stated above.
    </p>
    <h3>Legal Malpractice Insurance</h3>
    <p>
      As of the date of this letter, VLG has errors and omissions (legal
      malpractice) insurance applicable to the services to be rendered pursuant
      to this Agreement, subject to any applicable deductible or self-insured
      retention.
    </p>
    <h3>Choice Of Law/Forum Selection</h3>
    <p>
      This Agreement is deemed to have been executed and is intended to be
      performed in the State of New Jersey, subject to its laws, regardless of
      whether services are actually rendered outside of the State. Any dispute
      arising from this Agreement shall be governed by the laws of the State of
      New Jersey. The venue for the judicial resolution of such dispute shall be
      proper only within the State of New Jersey.
    </p>
    <h3>General Provisions</h3>
    <p>
      This agreement sets forth the entire understanding of the parties. Any
      amendments must be in writing and signed by both parties. This agreement
      shall be construed under the laws of the State of New Jersey. If any
      provision of this agreement is held to be invalid, illegal, or
      unenforceable, the remaining portions of this agreement shall remain in
      full effect and construed so as to best effectuate the original intent and
      purpose of this agreement.
    </p>
    <h3>Additional Acknowledgements</h3>
    <p>
      I acknowledge that all of the fees I pay to Virtue Law Group, LLC are for
      the legal services provided by the law firm.
    </p>
    <p>
      The fees I pay to Virtue Law Group, LLC are not for the purpose of
      settlement and will not be paid to my creditors.
    </p>
    <p>
      I acknowledge that the services provided by Virtue Law Group, LLC is
      neither debt settlement nor credit repair. Rather, I am paying for the
      legal services that will be provided by a law firm and its attorneys.
    </p>
    <p>
      I understand that Virtue Law Group, LLC and its attorneys will represent
      me in any lawsuit filed against me in connection with an account appearing
      on this Agreement. This representation will not require any additional
      fees to be paid other than the fees set forth on this Agreement.
    </p>
    <p>
      I acknowledge that my current credit score will be negatively affected in
      the process of resolving my debt.
    </p>
    <p>
      I understand that if I enroll any security debt, the security or
      collateral may be repossessed or foreclosed as a result of this program.
    </p>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Creditor</th>
          <th>Account #</th>
        </tr>
      </thead>
      <tbody>
        {agreement?.tradelines?.map((t, i) => (
          <tr key={t.id}>
            <td>{i + 1}</td>
            <td>{t.tradeline?.customerName}</td>
            <td>########{t.tradeline?.accountNumber?.slice(-4)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <p>
      <b>Total Enrolled Debt:&nbsp;&nbsp;</b>
      {formatMoney(form?.plan?.debt_amount || 0)}
    </p>
    <p>
      This agreement and acknowledgments become effective as of the date it is
      electronically executed by clicking the “Subscribe" or similar button on
      our website, you expressly acknowledge and agree that you have carefully
      read, fully understood, and hereby unconditionally consent to be legally
      bound by all the terms and conditions outlined in this Agreement. By
      indicating acceptance of this agreement and its terms and conditions, the
      parties agree to be bound by its provisions, acknowledging that this
      electronic execution serves as a valid and enforceable substitute for a
      physical signature.
    </p>
    <p>
      <b>Printed Name:&nbsp;&nbsp;</b>
      {form?.first_name} {form?.last_name}
    </p>
    <p>
      <b>Date:&nbsp;&nbsp;</b>
      {moment().format("M/D/YYYY")}
    </p>
    <h3>Electronic Funds Transfer (EFT) Authorization Form</h3>
    <p>
      <u>Consumer Authorization for Direct Payment via ACH (ACH Debits)</u>
    </p>
    <p>
      Direct Payment via ACH is the transfer of funds from a consumer account
      for the purpose of making a payment. I authorize Virtue Law Group, LLC
      (“VLG”) to electronically debit my account (and, if necessary,
      electronically credit my account to correct erroneous debits) as follows:
    </p>
    <p>
      <b>Bank Name:&nbsp;&nbsp;</b>
      {agreement?.application?.bank?.name || plaidData?.institution?.name}{" "}
    </p>
    <p>
      <b>Checking or Saving:&nbsp;&nbsp;</b>
      {agreement?.application?.bank?.account_type?.toUpperCase() ||
        plaidData?.account?.subtype?.toUpperCase()}
    </p>
    <p>
      <b>Account Number:&nbsp;&nbsp;</b>
      ########
      {agreement?.application?.bank?.last_four || plaidData?.account?.mask}
    </p>
    <p>
      <b>Address:&nbsp;&nbsp;</b>
      {form?.address} {form?.address_line_two}
    </p>
    <p>
      <b>City:&nbsp;&nbsp;</b>
      {form?.city}
    </p>
    <p>
      <b>State:&nbsp;&nbsp;</b>
      {form?.state}
    </p>
    <p>
      <b>Zip:&nbsp;&nbsp;</b>
      {form?.zip}
    </p>
    <p>
      <b>Primary Phone #:&nbsp;&nbsp;</b>
      {form?.phone}
    </p>
    <p>
      <b>Last 4 digits of social security #:&nbsp;&nbsp;</b>
      {form?.ssn_last_4}
    </p>
    <h3>Payment Schedule</h3>
    <p>I agree to the following payment schedule</p>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Payment Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {agreement?.payments?.map((p, i) => (
          <tr key={`pay-agree-${i}`}>
            <td>{i + 1}</td>
            <td>{moment(parseInt(p?.draft_date)).format("M/D/YYYY")}</td>
            <td>{formatMoney(p?.amount || 0)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <p>
      I authorize VLG or their designees to debit the bank account indicated in
      this form, for the noted amounts stated above. I understand that because
      this is an electronic transaction, these funds may be withdrawn from my
      account before or after the above-noted transaction dates. I will not
      dispute Virtue Law Group, LLC debiting my checking/savings account, so
      long as the transaction corresponds to the payment amount indicated on
      this form. This agreement becomes effective as of the date it is
      electronically executed by clicking the subscribe button provided on the
      website (virtulg.org) by the parties listed in this agreement. By
      indicating acceptance of this agreement and its terms and conditions, the
      parties agree to be bound by its provisions, acknowledging that this
      electronic execution serves as a valid and enforceable substitute for a
      physical signature.
    </p>
    <p>
      <b>Printed Name:&nbsp;&nbsp;</b>
      {form?.first_name} {form?.last_name}
    </p>
    <p>
      <b>Date:&nbsp;&nbsp;</b>
      {moment().format("M/D/YYYY")}
    </p>
    <h3>POWER OF ATTORNEY</h3>
    <p>
      I {form?.first_name} {form?.last_name} hereby appoint Virtue Law Group
      (“LAW FIRM”) as my Attorney-In-Fact, which expressly includes the
      authority to do each of the following:
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;1. Communicate with my creditors, and each of them, and
      with Experian, Equifax, and TransUnion, regarding the status of my
      account, any information regarding my account, and regarding the accuracy
      of information reported by my creditors to any credit bureau.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;2. Represent me in any legal proceedings by responding
      to such proceeding or by initiating a proceeding or counter-proceeding on
      my behalf.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;3. Negotiate a resolution to any of my accounts or any
      dispute regarding any of my accounts with any creditor or credit bureau.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;4. Engage licensed attorneys to act on my behalf both in
      court and in any negotiation or other proceeding.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;5. Execute any settlement document or other written
      confirmation of the terms of any resolution to any dispute regarding any
      of my accounts.
    </p>
    <p>&nbsp;&nbsp;&nbsp;6. Dispute the validity of any account.</p>
    <p>
      All authority identified above shall include that authority which is
      reasonable necessary to give effect to the authorities granted above. This
      Power of Attorney shall remain in full force and effect until (a)
      withdrawn in writing by ; or (b) four years from the date affixed below.
      Any party receiving a copy of this document is expressly granted the
      authority to act upon it absent written withdraw for a period of four
      years from the date of execution.
    </p>
    <p>
      This agreement and acknowledgments become effective as of the date it is
      electronically executed by clicking the “Subscribe" or similar button on
      our website, you expressly acknowledge and agree that you have carefully
      read, fully understood, and hereby unconditionally consent to be legally
      bound by all the terms and conditions outlined in this Agreement. By
      indicating acceptance of this agreement and its terms and conditions, the
      parties agree to be bound by its provisions, acknowledging that this
      electronic execution serves as a valid and enforceable substitute for a
      physical signature.
    </p>
    <p>
      The purpose of this correspondence is to, upon execution: (1) establish an
      attorney client relationship between Virtue Law Group, LLC ("VLG" or "the
      Firm") and Client ("You"); (2) define the scope of the Firm's
      representation of You; and (3) establish other material terms and
      conditions of the representation, including but not limited to the
      financial terms. This correspondence may be referred to as the Engagement
      Letter (“Letter”) or the Legal Services Agreement (“Agreement”).
    </p>
    <h3>Additional Disclosures</h3>
    <p>
      This agreement and disclosure is accepted electronically by clicking the
      subscribe button provided on the website (virtuelg.org), indicating
      acceptance to the terms and conditions herein, in lieu of a physical
      signature and authorize and permit Virtue Law Group, LLC (“VLG”) or their
      designees, ACHQ, Plaid, Optimum Bank, Omnifund, Equipay, Forte, a CSG
      Company, Vector Payments, Actum, Paya, NMI, Seamless Chex, Approvely, or
      Authorize.NET or any other VLG designates to initiate electronic funds
      transfer via an Automated Clearing House system (ACH) from my account with
      VLG. VLG may also require a voided check or savings deposit slip to verify
      the account information.
    </p>
    <p>
      If necessary, VLG may make adjustments if errors have occurred during the
      transaction. The dates of the drafts are listed on the Authorization Form
      on Page 1. However, if the draft date falls on a weekend or bank holiday,
      the debit transaction will take place on the next business day. This
      authority will remain in effect until VLG is notified by the client
      verbally or in writing at least three (3) business days prior to the next
      scheduled draft date. For record-keeping purposes, all cancellations or
      revocation of payment authorizations must be in writing. If the debit is
      returned because of non-sufficient funds or uncollected funds, then the
      originator and its financial institution may reinitiate the entry up to
      two (2) times. The reversal of funds from a client’s account that was
      drafted in error cannot be made until seven business days from the draft
      date. The member agrees to waive all rights of reversal or refusal of any
      payment on any draft that VLG may make against the member’s bank account
      while services are performed. The member agrees with all the provisions
      and conditions outlined within.{" "}
    </p>
    <h3>Acknowledgment of Refunds & Draft Date Changes</h3>
    <p>
      ACH Refunds: If a refund is due, such refund will be made by check or ACH
      payable to the account from which the payment or payments were drafted.
      All refunds will be processed within ten (10) business days. In the event
      my EFT or draft is returned from my bank unpaid, I agree that a fee of
      $25.00 or as allowed by law may be charged to my account via draft or EFT.
      Furthermore, I warrant that I am authorized to execute this payment
      authorization and the above information is true and correct. Draft Date
      Changes: If you should need to notify us of your intent to change the
      payment date, you must contact us three (3) business days prior to the
      debit being initiated, and your notice must be in writing.
    </p>
    <h3>Electronic acceptance</h3>
    <p>
      By clicking the Subscribe" or similar button at the checkout page on our
      website (virtuelg.org), you expressly acknowledge and agree that you have
      carefully read, fully understood, and hereby unconditionally consent to be
      legally bound by all the terms and conditions outlined in this Agreement.
      You acknowledge and agree that your electronic acceptance constitutes a
      valid and binding agreement to the terms and conditions of this contract,
      with the same legal effect as if you had physically signed a paper copy of
      the contract. Furthermore, you expressly acknowledge that your electronic
      acceptance will be treated as conclusive evidence of your intent to be
      bound by the terms herein, and you waive any objection to the
      enforceability of this agreement based on the electronic form of
      acceptance.
    </p>
    <h3>Effective Date of Agreement</h3>
    <p>
      This entire agreement becomes effective as of the date it is
      electronically executed by clicking the Subscribe" or similar button at
      the checkout page on our website (virtuelg.org), you expressly acknowledge
      and agree that you have carefully read, fully understood, and hereby
      unconditionally consent to be legally bound by all the terms and
      conditions outlined in this agreement by the parties listed in this
      agreement. By indicating acceptance of this agreement and its terms and
      conditions, the parties irrevocably agree to be bound by its provisions,
      fully understanding and acknowledging that this electronic execution
      serves as a valid, enforceable, and legally binding substitute for a
      physical signature.
    </p>
    <p>
      <b>Printed Name:&nbsp;&nbsp;</b>
      {form?.first_name} {form?.last_name}
    </p>
    <p>
      <b>Credit Report Acceptance Date:&nbsp;&nbsp;</b>
      {moment(
        parseInt(agreement?.application?.audit?.credit_report_date),
      ).format("M/D/YYYY")}
    </p>
    <p>
      <b>Plaid Acceptance Date:&nbsp;&nbsp;</b>
      {moment(parseInt(agreement?.application?.audit?.plaid_date)).format(
        "M/D/YYYY",
      )}
    </p>
    <p>
      <b>Power of Attorney Acceptance Date:&nbsp;&nbsp;</b>
      {moment().format("M/D/YYYY")}
    </p>{" "}
    <p>
      <b>Agreement Acceptance Date:&nbsp;&nbsp;</b>
      {moment().format("M/D/YYYY")}
    </p>
    <p>
      <b>IP Address:&nbsp;&nbsp;</b>
      {agreement?.ip_address}
    </p>
  </React.Fragment>
);
