import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { getBankAccounts } from "App/Admin/_graphql/queries/bank_account";
import { Loading } from "_styleguide";
import Accounts from "./_components/Accounts/Accounts";
import ConnectAccount from "./_components/ConnectAccount/ConnectAccount";
import "./BankAccounts.scss";

function BankAccounts(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState();

  // Load Bank Accounts
  const fetchAccounts = async () => {
    const accountsData = await client.query({
      variables: { application_id: props?.applicationId },
      query: getBankAccounts,
    });
    setAccounts(accountsData?.data?.getBankAccounts || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="admin-application-accounts">
      <div className="admin-information-boxes">
        <div className="wzrd-box">
          <div className="wzrd-header">
            <h3>Linked Bank Accounts</h3>
          </div>
          <div className="wzrd-body">
            <Accounts
              accounts={accounts}
              app={props.app}
              reload={fetchAccounts}
              reloadApplication={props.reloadApplication}
            />
          </div>
        </div>
        <ConnectAccount client={client} app={props.app} />
      </div>
    </div>
  );
}

export default BankAccounts;
