import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { completeTask } from "App/Admin/_graphql/mutations/task";
import { getUsers } from "App/Admin/_graphql/queries/user";
import { getErrors } from "_helpers/api";
import { IconCheck } from "@tabler/icons-react";
import { TextArea, Flex, InputLabel, Select } from "_styleguide";
import "./CompleteTask.scss";

function CompleteTask(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const [notify, setNotify] = useState([]);
  const [note, setNote] = useState();

  // Pause Plan
  const saveComplete = async (skip) => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          task_id: props.taskId,
          skip: skip ? true : false,
          note: note || null,
          notify: notify || [],
        },
        mutation: completeTask,
      });
      message.success(`Task has been completed`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error completing task, try again");
      setSaving(false);
    }
  };

  const fetchUsers = async () => {
    const usersData = await client.query({
      query: getUsers,
    });
    setUsers(usersData?.data?.getUsers || []);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      wrapClassName="complete-task-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div className="pp-mh-icon">
          <IconCheck />
        </div>
        Complete Task
      </div>

      <Modal.Body>
        <div className="ct-form">
          <Flex vertical={true}>
            <InputLabel htmlFor="note">Leave a note (optional)</InputLabel>
            <TextArea
              disabled={saving}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              size="large"
              autoSize={true}
              placeholder="e.g. Excited to get started after intro call..."
            />
          </Flex>
          <Flex vertical={true}>
            <InputLabel htmlFor="notify">Notify (optional)</InputLabel>
            <Select
              disabled={saving}
              mode="multiple"
              allowClear
              value={notify}
              onChange={(val) => setNotify(val)}
              size="extra-medium"
              popupClassName="pp-select-dropdown ppsd-task"
              placeholder="---"
            >
              {users?.map((u) => (
                <Select.Option key={u.id} value={u.id}>
                  {u.name}
                  <span className="ct-email">({u.email})</span>
                </Select.Option>
              ))}
            </Select>
            <p className="pp-info">
              * We’ll notify the next task owner by default.
            </p>
          </Flex>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <div className="right-part">
          <Button
            type="blanco"
            onClick={() => saveComplete(true)}
            loading={saving}
          >
            Skip Task
          </Button>
          <Button
            className="green-btn"
            type="primary"
            onClick={() => saveComplete(false)}
            loading={saving}
          >
            Complete Task
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default CompleteTask;
