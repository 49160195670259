import React, { Component } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import './FAQ.scss';

class FAQ extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'pp-faq ' + (props.className || '');
    return <AntdCollapse {...props} expandIconPosition="end" />;
  }
}

export default FAQ;
