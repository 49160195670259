import { gql } from "@apollo/client";

export const adminApplicationNotes = gql`
  query adminApplicationNotes($application_id: ID!) {
    adminApplicationNotes(application_id: $application_id) {
      id
      user {
        id
        name
        email
      }
      company {
        id
        name
      }
      content
      createdAt
    }
  }
`;
