import { gql } from "@apollo/client";

export const estimatedPayments = gql`
  query estimatedPayments($plan: PlanInputType!) {
    estimatedPayments(plan: $plan) {
      draft_date
      amount
    }
  }
`;
