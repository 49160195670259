import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Header, Modal } from "@centrate-io/barn";
import { getPayment } from "App/Admin/_graphql/queries/payment";
import {
  IconCheck,
  IconDots,
  IconX,
  IconBan,
  IconPlayerPause,
  IconClockExclamation,
  IconRefreshAlert,
  IconRefresh,
  IconRefreshOff,
} from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import moment from "moment";
import { Loading } from "_styleguide";
import "./PaymentHistory.scss";

const updateStatuses = {
  open: "open",
  paused: "Payment Paused",
  processed: "Transaction Complete",
  pending: "Payment Pending",
  voided: "Payment Cancelled",
  failed: "Paymenet Failed",
  expired: "Payment Expired",
  refunded: "Payment Refunded",
  "refund pending": "Refund Pending",
  "refund failed": "Refund Failed",
  "transaction.sale.success": "Payment Pending",
  "transaction.void.success": "Payment Cancelled",
  "transaction.void.failure": "Void Failed",
  "transaction.void.unknown": "Void Unknown",
  "transaction.check.status.settle": "Transaction Complete",
  "transaction.check.status.return": "Paymenet Failed",
  "transaction.check.status.latereturn": "Paymenet Failed",
  "transaction.refund.success": "Payment Refunded",
  "transaction.refund.failure": "Refund Failed",
  "transaction.refund.unknown": "Refund Unknown",
};

function PaymentHistory(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState();

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      variables: {
        payment_id: props?.paymentId,
      },
      query: getPayment,
    });
    const payData = queryData?.data?.getPayment;
    setLoading(false);
    const updates = [...payData?.attempts, ...payData.updates];
    setPaymentData({
      payment: payData,
      updates: updates.sort(
        (a, b) => parseInt(a.timestamp) - parseInt(b.timestamp),
      ),
    });
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      wrapClassName="payment-data-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <Header size="5" type="display">
        <span>Payment #{paymentData?.payment?.order}&nbsp;&nbsp;/</span>
        &nbsp;&nbsp;{paymentData?.payment?.application?.first_name}&nbsp;
        {paymentData?.payment?.application?.last_name}
      </Header>

      {loading ? (
        <div className="modal-load">
          <Loading />
        </div>
      ) : (
        <Modal.Body>
          <div className="history-list">
            {paymentData?.updates?.map((pu, i) => {
              const isAttempt = pu.status !== undefined;
              const actualStatus = pu.status || pu.ach_status;

              return (
                <div key={`pu-${i}`} className="history">
                  <div
                    className={`history-icon ${actualStatus?.replace(" ", "-")}`}
                  >
                    {["processed", "transaction.check.status.settle"].includes(
                      actualStatus,
                    ) && <IconCheck />}
                    {[
                      "processing",
                      "pending",
                      "open",
                      "transaction.sale.success",
                    ].includes(actualStatus) && <IconDots />}
                    {[
                      "failed",
                      "transaction.void.failure",
                      "transaction.check.status.return",
                      "transaction.check.status.latereturn",
                    ].includes(actualStatus) && <IconX />}
                    {["voided", "transaction.void.success"].includes(
                      actualStatus,
                    ) && <IconBan />}
                    {["paused"].includes(actualStatus) && <IconPlayerPause />}
                    {[
                      "expired",
                      "transaction.void.unknown",
                      "transaction.refund.unknown",
                    ].includes(actualStatus) && <IconClockExclamation />}
                    {["refunded", "transaction.refund.success"].includes(
                      actualStatus,
                    ) && <IconRefresh />}
                    {["refund pending"].includes(actualStatus) && (
                      <IconRefreshAlert />
                    )}
                    {["refund failed", "transaction.refund.failure"].includes(
                      actualStatus,
                    ) && <IconRefreshOff />}
                  </div>
                  {isAttempt ? (
                    <div className="history-info">
                      <h4>
                        <b>
                          Attempt #{pu.attempt} - {pu.description}
                        </b>
                      </h4>
                      <p>
                        {moment(pu.timestamp * 1).format("M/D/YY @ h:mm:ss a")}
                        &nbsp;&nbsp;&bull;&nbsp;&nbsp;Acct Bal:{" "}
                        {pu.balance || pu.balance === 0
                          ? formatMoney(pu.balance)
                          : "Balance not available"}
                        &nbsp;&nbsp;&bull;&nbsp;&nbsp; Bank Type:{" "}
                        {pu.bank_type || "N/A"}
                      </p>
                    </div>
                  ) : (
                    <div className="history-info">
                      <h4>
                        <b>{updateStatuses[pu.ach_status]}</b>
                        {formatMoney(pu.amount)}
                      </h4>
                      <p>
                        {moment(pu.timestamp * 1).format("M/D/YY @ h:mm:ss a")}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
            {paymentData?.updates?.length <= 0 ? (
              <div className="no-history">This payment has no history yet.</div>
            ) : null}
          </div>
        </Modal.Body>
      )}

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default PaymentHistory;
