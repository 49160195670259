import { exportPayments } from "_graphql/queries/export";
import {
  flattenObject,
  convertDates,
  downloadCSV,
  parseAndLimit,
} from "_assets/js/helpers";
import { message } from "@centrate-io/barn";

export const addPaymentInformation = (payments, excludeOriginal) => {
  const PAYPATH_FEE = 84.0;

  let results = [...payments];
  results = results.map((r) => convertDates(flattenObject(r)));
  results = results.map((r) => {
    const planMonths =
      r["application.plan.months"] || r["application.total_payments"];
    const perMonth = r["application.total_payments"] / planMonths;
    const paypathFee = parseAndLimit(PAYPATH_FEE / perMonth);

    let original = excludeOriginal ? {} : { ...r };

    return {
      ...original,
      "Customer ID": r["application.short_id"],
      "Customer First Name": r["application.first_name"],
      "Customer Last Name": r["application.last_name"],
      "Payment ID": r.short_id,
      Status: r.status?.toUpperCase(),
      "Payment #": `${r.order}/${r["application.total_payments"]}`,
      Amount: "$" + r.amount,
      "Est. PayPath Fee": "$" + paypathFee,
      "Plan Months": planMonths,
      "Total Payments": r["application.total_payments"],
      "Payments / Month": perMonth,
      "Total Attempts": r.total_attempts,
      "Due Date": r.first_attempt,
      "Processed Date": r.last_attempt,
    };
  });
  return results;
};

export const downloadPayments = async (apolloClient, params) => {
  try {
    const result = await apolloClient.query({
      query: exportPayments,
      variables: {
        ...params,
      },
    });
    let results = result?.data?.exportPayments || [];
    results = addPaymentInformation(results);
    if (results?.length > 0) {
      downloadCSV(results, "payments-export.csv");
    } else {
      message.error("No data to export, try again");
    }
  } catch (err) {
    message.error("Error exporting data, try again");
    console.log(err);
  }

  return true;
};

export const downloadSnapshot = async (results, month) => {
  try {
    let csvResults = addPaymentInformation(results, true);
    if (csvResults?.length > 0) {
      downloadCSV(csvResults, `${month.replace(" ", "-")}-snapshot.csv`);
    } else {
      message.error("No data to export, try again");
    }
  } catch (err) {
    message.error("Error exporting data, try again");
    console.log(err);
  }

  return true;
};
