import { gql } from "@apollo/client";

export const getTaskTemplates = gql`
  query {
    getTaskTemplates {
      id
      short_id
      title
      type
      instructions
      createdAt
    }
  }
`;

export const getCustomerTasks = gql`
  query getCustomerTasks($application_id: String!) {
    getCustomerTasks(application_id: $application_id) {
      id
      title
      type
      instructions
      order
      start
      delay
      skipped
      completed
      note
      estimated_due_timestamp
      due_timestamp
      due_days
      is_due
      completed_timestamp
      assigned_user_id
      assigned_team_id
      template_id
      completed_user {
        id
        name
        email
      }
      assigned_user {
        id
        name
        email
      }
      assigned_team {
        id
        name
      }
    }
  }
`;

export const getMyTasksCount = gql`
  query {
    getMyTasks {
      todo {
        id
        application {
          id
          status
        }
      }
    }
  }
`;

export const getMyTasks = gql`
  query {
    getMyTasks {
      todo {
        id
        title
        type
        instructions
        order
        start
        delay
        skipped
        completed
        note
        estimated_due_timestamp
        due_timestamp
        due_days
        is_due
        completed_timestamp
        assigned_user_id
        assigned_team_id
        template_id
        application {
          id
          short_id
          status
          first_name
          last_name
        }
      }
      done {
        id
        title
        type
        instructions
        order
        start
        delay
        skipped
        completed
        note
        estimated_due_timestamp
        due_timestamp
        due_days
        is_due
        completed_timestamp
        assigned_user_id
        assigned_team_id
        template_id
        application {
          id
          short_id
          first_name
          last_name
        }
      }
    }
  }
`;
