import { gql } from "@apollo/client";

export const exportPayments = gql`
  query exportPayments(
    $search: String
    $date: String
    $type: String
    $status: String
  ) {
    exportPayments(search: $search, date: $date, type: $type, status: $status) {
      short_id
      status
      order
      amount
      first_attempt
      last_attempt
      total_attempts
      application {
        short_id
        first_name
        last_name
        total_payments
        plan {
          months
        }
      }
    }
  }
`;
