import React, { useState } from "react";
import { Input, Button, message } from "@centrate-io/barn";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { getErrors } from "_helpers/api";
import PayPathLogo from "_assets/logos/paypath.svg";
import { forgotPassword } from "_graphql/mutations/user";
import { validateEmail } from "_assets/js/helpers";
import "./Forgot.scss";

function Forgot(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  // Submit login and receive token
  const submitForgot = async () => {
    const isEmailValid = validateEmail(email || "");
    if (!isEmailValid)
      return message.error("Please enter a valid email address");
    setLoading(true);
    try {
      await client.mutate({
        variables: { email },
        mutation: forgotPassword,
      });
      message.success(`Reset instructions sent to: ${email}`);
      navigate("/");
    } catch (err) {
      message.error(getErrors(err) || "Error resetting password, try again");
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="auth-forgot">
        <div className="auth-info">
          <img className="login-logo" src={PayPathLogo} alt="PayPathAI" />
        </div>
        <div className="login-form">
          <div className="line">
            <Input.Label>Email used for login *</Input.Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
              placeholder="e.g. jford@gmail.com..."
            />
          </div>
          <Button
            loading={loading}
            type="secondary"
            onClick={submitForgot}
            size="large"
            block
          >
            Reset Password
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Forgot;
