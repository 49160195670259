import React, { Component } from "react";
import { Input as BarnInput } from "@centrate-io/barn";
import "./Input.scss";

class Input extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-input " + (props.className || "");

    if (props.started && props.valid !== 1) {
      props.className += " pp-invalid";
    }
    if (props.size === "large") {
      props.className += " pp-large";
    }
    if (props.size === "medium") {
      props.className += " pp-medium";
    }

    let icon = null;
    if (props.icon) {
      icon = props.icon;
      delete props.icon;
    }

    return (
      <div className={`pp-input-wrapper ${icon ? "pp-icon" : ""}`}>
        {icon}
        <BarnInput {...props} />
      </div>
    );
  }
}

export default Input;
